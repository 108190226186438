import { useCallback, useEffect, useState } from "react";
import backendRepository from "../../repository/backend-repository";
import './spying-history-list.scss'
import usePlayerStore from "../../store/use-player-store";
import Pagination from "../../layout/pagination/pagination";
import PaginationLength from "../../layout/pagination/pagination-length";
import PaginationFilter from "../../layout/pagination/pagination-filter";
import { Button, List, ListItem, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import ShieldIcon from '@mui/icons-material/Shield';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import moment from "moment";
import { addQueryParam } from "../../services/utils";
import ConquestForChip from "../conquest-for-chip/conquest-for-chip";
import SpyingForChip from '../../components/spying-for-chip/spying-for-chip'
import { computeSuccessLevel } from "./compute-success-level";


function SpyingHistoryList() {

    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const player = usePlayerStore(state => state.player)

    const [spyingHistory, setSpyingHistory] = useState(null);

    const [pages, setPages] = useState(addQueryParam("page", 1, false))
    const [pageSize, setPageSize] = useState(addQueryParam("length", 20, false));
    const [role, setRole] = useState(addQueryParam("role", "", false))

    const fetchData = useCallback(async (pages, pageSize, role) => {
        try {
            const history = await backendRepository.getSpyingHistory(pages, pageSize, role);
            setSpyingHistory(history);
        } catch (error) {
            console.error(error);
        }
    }, [setSpyingHistory])

    useEffect(() => {
        fetchPlayer().then(() => {
            fetchData(pages, pageSize, role)

        })
    }, [fetchData]);


    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        addQueryParam("length", pageSize, true)
        setPages(1);
        addQueryParam("page", 1, true)

        fetchData(1, pageSize, role)
    };

    const handleRoleChange = (role) => {
        setRole(role);
        addQueryParam("role", role, true)
        setPages(1);
        addQueryParam("page", 1, true)
        fetchData(1, pageSize, role)
    }

    const handlePageSelection = (page) => {
        setPages(page)
        addQueryParam("page", page, true)
        fetchData(page, pageSize, role)
    }

    if (spyingHistory === null || player === null) {
        return <Skeleton animation="wave" height={600} />
    }
    return (
        <>
            <PaginationFilter onRoleChange={handleRoleChange} filters={{ 'as-attacker': "Attaque", 'as-defender': "Defense", '': 'Tout' }} />
            <Pagination
                currentPage={spyingHistory.currentPage}
                totalPages={spyingHistory.totalPages}
                onPageSelection={handlePageSelection}
            />
            <PaginationLength value={pageSize} onPageSizeChange={handlePageSizeChange} />
            <List dense sx={{ bgcolor: 'background.paper' }}>
                {spyingHistory.spyings.map((spying) => {
                    let role = player.id === spying.attacker.id ? "Attaque" : "Défense"
                    let successLevel = spying.successLevel
                    let { result, iconColor } = computeSuccessLevel(player, spying, successLevel)

                    return (
                        <ListItem alignItems={"center"} key={spying.id} sx={{ color: "white" }}>
                            <ListItemIcon>
                                {player.id === spying.defender.id ? <ShieldIcon className={iconColor} fontSize={"large"} /> : <GpsFixedIcon className={iconColor} fontSize={"large"} />}
                                <span className={"conquest-for-icon"}>
                                    <SpyingForChip spyType={spying.spyingType} detailed />
                                </span>
                            </ListItemIcon>
                            <ListItemText
                                primary={<>
                                    <strong>{spying.successLevel === 3 ?
                                        "Inconnu"
                                        :
                                        spying.attacker.nickname
                                    }</strong> VS <strong>{spying.defender.nickname}</strong><br />
                                    <strong>{result}</strong> sur {role}
                                </>}
                                secondary={
                                    <>{moment(spying.createdAt).format("YYYY/MM/DD HH:mm:SS")}</>
                                }
                            />
                            <Button variant={"outlined"} component={Link} to={`/spyings/${spying.id}`}>Détail</Button>
                        </ListItem>
                    );
                })}
            </List>
        </>
    )
}

export default SpyingHistoryList