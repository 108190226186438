import React, { useState, useEffect } from "react";

import usePlayerStore from "../../store/use-player-store";

import Chip from "../../layout/chip/chip";
import SpyingTypeSelector from "../spying-type-selector/spying-type-selector";
import { Skeleton, Button, ButtonGroup, TextField, Table, TableRow, TableCell, TableContainer, TableBody } from "@mui/material";
const cardOptions = [
  {
    title: "Rapport de ressources",
    value: "watchResource",
    description: "Vos espions reviendrons avec un rapport detaillé des ressources actuelles du royaume ciblé"
  }
]

function SpyingCard({
  player,
  spyQuantity = 0,
  type = null,
  onChange = () => { },
  onValidityChange = () => { }
}) {
  const resourceStocks = usePlayerStore(state => state.resourceStocks)
  const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)

  const [spyStock, setSpyStock] = useState(null)
  const [exceedCost, setExceedCost] = useState(false)
  const [quantity, setQuantity] = useState(spyQuantity)
  const [target, setTarget] = useState({})
  const [spyType, setSpyType] = useState(type)

  useEffect(() => {
    setTarget(player ?? {})
  }, [player])

  useEffect(() => {

    const fetchData = async () => {
      try {
        await fetchPlayerResources()
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [fetchPlayerResources]);

  useEffect(() => {
    if (resourceStocks.length === 0) {
      return
    }

    const spyResource = resourceStocks.find(
      resource => resource.resource.type === "spy"
    );
    setSpyStock(spyResource.quantity)
  }, [resourceStocks])

  function computeCostExceeded(newQuantity) {
    if (newQuantity > spyStock || newQuantity < 0 || newQuantity === '' || isNaN(newQuantity)) {
      setExceedCost(true);
    } else {
      setExceedCost(false);
    }
  }

  const handleQuantityChange = (event) => {

    event.preventDefault();
    let newQuantity = parseInt(event.target.value);
    if (newQuantity > -1 || newQuantity === '' || isNaN(newQuantity)) {
      setQuantity(newQuantity);
      computeCostExceeded(newQuantity);
    }
    onChange(newQuantity, spyType)
    handleValidityChange(newQuantity, spyType)
  };

  const handleMax = () => {
    setQuantity(spyStock)
    handleValidityChange(spyStock, spyType)
    onChange(spyStock, spyType)
  }

  const handleReset = () => {
    setQuantity(0)
    computeCostExceeded(0)
    handleValidityChange(0, spyType)
  }

  const handleValidityChange = (quantity, spyType) => {
    const valid = !target || exceedCost || quantity === 0 || isNaN(quantity) || quantity === '' || null === spyType
    onValidityChange(!valid)
  }

  return <>
    {resourceStocks.length === 0 ?
      <Skeleton animation="wave" height={600} />
      :
      <div className="action-container">
        <div className="action-card action-card-single">
          <div className={`banner banner-single banner-spying`}>
            <div className="action-title">
              <h2>ESPIONNAGE</h2>
              <p>Percez les secrets de votre adversaire</p>
            </div>
          </div>

          <form className='action-form'>
            {Object.entries(target).length !== 0 ? <h2>Votre cible: <Chip>{target.nickname}</Chip></h2> : <h2><Chip>Pas de cible</Chip></h2>}
            <h3>Type de conquete:</h3>
            <SpyingTypeSelector options={cardOptions} value={spyType} onChange={(spyType) => setSpyType(spyType)} />

            <TableContainer sx={{ marginTop: '20px', marginBottom: '20px' }}>
              <Table size={"small"}>
                <TableBody>
                  <TableRow>
                    <TableCell>Stock d'espions actuel</TableCell>
                    <TableCell align="right">{spyStock}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Espions mobilisés</TableCell>
                    <TableCell align="right">
                      {isNaN(quantity) || quantity === '' ? 0 : <span className={quantity > spyStock ? "exceed" : ""}>{quantity}</span>}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Espions de reserve</TableCell>
                    <TableCell align="right">
                      <span>
                        {spyStock - quantity < 0 || isNaN(quantity) ? 0 : spyStock - quantity}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <label htmlFor={`quantityInput`}>
            </label>
            <div className='action-input-container'>
              <TextField
                size={"small"}
                className='action-input'
                type="number"
                label={"Quantité d'espions"}
                variant={"outlined"}
                value={isNaN(quantity) || quantity <= 0 ? '' : quantity}
                inputProps={{
                  max: spyStock,
                }}
                onChange={(event) => handleQuantityChange(event)}
              />

              <ButtonGroup variant={"outlined"}>
                <Button onClick={handleMax}>
                  Max
                </Button>
                <Button onClick={handleReset}>
                  Reset
                </Button>
              </ButtonGroup>
            </div>
          </form>
        </div >
      </div >
    }
  </>
}

export default SpyingCard