import "./banner.scss"

function Banner({children = null, img = null}) {
    return <div style={{backgroundImage: `url(${img})`}} className={`banner-head banner-head-single`}>
        {children && <div className="banner-head-title banner-head-title-longer">
            {children}
        </div>}
    </div>
}

export default Banner