import "./changelog-item.scss"
import Chip from "../../layout/chip/chip";

function ChangelogItem({item, compact = false}) {
    const handleChipType = (change) => {
        switch (change.type) {
            case "feat":
                return "success"
            case "bug":
                return "warning"
            case "balancing":
                return "info"
            default:
                return "primary"
        }
    }
    return <div className={"changelog-item"}>
        {!compact && <>
            <h2>{item.date} - Release {item.release}</h2>
            <p className={"changelog-item-description"}>{item.description}</p>
            <p>Détails de la release :</p>
        </>}
        <ul>
            {item.changes.map((change, i) => (
                <li key={i} className={"changelog-item-row"}>
                    <Chip type={handleChipType(change)} size={"sm"}>
                        <strong>{change.type}</strong>
                    </Chip>
                    <div className={"ml-3"}>
                        {change.description}
                    </div>
                </li>
            ))}
        </ul>
    </div>
}

export default ChangelogItem