import "./key-icon.scss"

function KeyIcon({keyCodes = []}) {
    const maxKey = keyCodes.length - 1
    return <span className={"key-container"}>
        {keyCodes.map((keyCode, i) => <span key={i}>
            <span className={"key-icon"}>{keyCode}</span>{i === maxKey ? "" : " + "}
        </span>)}
    </span>
}

export default KeyIcon