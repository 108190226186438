import { useEffect } from "react"
import ContentPane from "../../layout/content-pane/content-pane";
import '../player-resource-info/player-resource-info.scss'
import usePlayerStore from "../../store/use-player-store";
import Skeleton from '@mui/material/Skeleton';
import ElementChip from "../element-chip/element-chip";


function PlayerResourceInfo({ title }) {
    const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const fetchResources = usePlayerStore(state => state.fetchPlayerResources)
    const fetchPlayerProductions = usePlayerStore(state => state.fetchPlayerProductions)

    useEffect(() => {
        Promise.all([fetchResources(), fetchPlayerProductions()])
            .catch(e => console.log(e))
    }, [fetchResources, fetchPlayerProductions]);
    return (
        <>
            {resourceStocks.length === 0 ?
                <Skeleton animation="wave" height={300} />
                :
                <ContentPane title={title}>
                    <div className="resource-list">
                        {/* map playerResource and display information in LI balise */}
                        {resourceStocks.map((item) => (
                            <ElementChip key={item.id} item={item} />
                        ))}
                    </div>
                </ContentPane>
            }
        </>
    )
}


export default PlayerResourceInfo