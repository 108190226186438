import PlayerActiveEffect from '../../components/player-active-effect/player-active-effect'
import SpellCastHistory from '../../components/spell-cast-history/spell-cast-history'
import HelperPane from '../../layout/helper-pane/helper-pane'
import PageTitle from "../../layout/page-title/page-title";
import { Button } from '@mui/material';
import { Link } from "react-router-dom";

function ActiveEffect() {


    return (
        <>

            <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/spell-cast"} variant={"outlined"}>Lancer un sort</Button>
            <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/spells"} variant={"outlined"}>Mes sorts</Button>
            <PageTitle>Historique de la magie</PageTitle>
            <HelperPane linkTo={"magic"}>
                <p>
                    Sur cette page, vous trouverez une liste des effets magiques en cours d'exécution dans votre royaume.
                    Ces effets affichent la durée totale du sort ainsi que le temps restant avant qu'ils ne disparaissent.
                </p>
                <p>
                    De plus, cette page contient la liste de tous les sorts lancés par votre royaume, ainsi que ceux qui ont ciblé votre royaume.
                    Le symbole à gauche vous indique si vous êtes le lanceur ou la cible du sort, et la couleur indique si le sort a réussi ou non.
                    Vous pouvez consulter le detail du sort lancé uniquement si vous êtes le lanceur du sort.
                </p>
            </HelperPane>

            <PlayerActiveEffect />
            <SpellCastHistory />
        </>
    )
}

export default ActiveEffect