import './free-lands-info.scss'
import ContentPane from "../../layout/content-pane/content-pane";
import { useEffect, useState } from "react";
import usePlayerStore from "../../store/use-player-store";

import Skeleton from '@mui/material/Skeleton';
import Chip from '../../layout/chip/chip';
import {thDelimiter} from "../../services/utils";
import {Alert} from "@mui/material";
import game from "../../assets/game/game";


function FreeLandsInfo() {
    const player = usePlayerStore((state => state.player));
    const fetchPlayer = usePlayerStore((state => state.fetchPlayer));
    const playerConstructions = usePlayerStore((state => state.playerConstructions));

    const [totalLands, setTotalLands] = useState(player ? player.freeLand : 0);
    const [totalConstructions, setTotalConstructions] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                return await fetchPlayer();
            } catch (error) {
                console.error(error);
            }
        }

        fetchData().then((player) => {
            setTotalConstructions(player.totalLands - player.freeLand);
            setTotalLands(player.totalLands);
        });
    }, [fetchPlayer, player, playerConstructions]);

    return (
        <>
            {player === null ? (
                <Skeleton animation="wave" />
            ) : (
                <ContentPane title="Vos terrains">
                    {player.isMinimumLandsSafety && <Alert severity={"info"}>Vous avez moins de {thDelimiter(game.MINIMUM_LANDS_SAFETY)} terres, vous ne pouvez pas etre attaqué</Alert> }
                    <div className='flex flex-row w-full justify-space-evenly text-center'>
                        <div className='align-center'>
                            <p>Terrains vides</p>
                            <p><Chip size="md" type="info">{thDelimiter(player.freeLand)}</Chip></p>
                        </div>
                        <div className='align-center'>
                            <p>Total de constructions</p>
                            <p> <Chip size="md" type="info">{thDelimiter(totalConstructions)}</Chip></p>
                        </div>
                        <div className='align-center'>
                            <p>Total de terrains</p>
                            <p><Chip size="md" type="info">{thDelimiter(totalLands)}</Chip></p>
                        </div>
                    </div>
                </ContentPane>
            )}
        </>
    );
}

export default FreeLandsInfo;
