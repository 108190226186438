import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import game from "../../assets/game/game";
import Chip from "../../layout/chip/chip";

function SpellCastSummary({playerSpell, target}) {
    return <>
        <p>
            Vous vous appretez à lancer le sort <span className="strong">{playerSpell.spell.name}</span> sur <span className="strong">{target.nickname}</span>.
        </p>
        <TableContainer>
            <Table size={"small"}>
                <TableBody>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>{playerSpell.spell.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Description du sort</TableCell>
                        <TableCell>{playerSpell.spell.description}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Cout</TableCell>
                        <TableCell>{playerSpell.spell.cost}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dangerosité du sort</TableCell>
                        <TableCell><Chip type={game.MAGIC.COLOR[playerSpell.spell.dangerosity]}>{game.MAGIC.LEVEL[playerSpell.spell.dangerosity]}</Chip></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default SpellCastSummary