//Framework
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom"

//toastify
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


//Pages:
import LandingPage from './pages/landing-page/landing-page'
import RulesPage from './pages/rules-page/rules-page'
import Login from './pages/login/login';
import SignUp from './pages/sign-up/sign-up';
import UpdateAccount from './pages/update-account/update-account';
import Account from './pages/account/account';
import Exploration from './pages/exploration/exploration';
import DashBoard from './pages/dashboard/dashboard'
import Construction from './pages/construction/construction';
import Army from './pages/army/army';
import Market from './pages/market/market';
import Conquest from './pages/conquest/conquest';
import Spying from './pages/spying/spying';
import SpyingRecap from './pages/spying-recap/spying-recap';
import SpyingHistory from './pages/spying-history/spying-history';
import Spells from './pages/spells/spells'
import SpellCast from './pages/spell-cast/spell-cast'
import ActiveEffect from './pages/active-effect/active-effect'
import Swap from './pages/swap/swap';
import Forum from './pages/forum/forum';
import Alliance from './pages/alliance/alliance';
import Messaging from './pages/messaging/messaging';
import Help from "./pages/help/help";

import ConquestRecap from './pages/conquest-recap/conquest-recap';
import ConquestHistory from './pages/conquest-history/conquest-history';

//Components
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Ranking from './pages/ranking/ranking';

//CSS
import './App.scss'
import { ThemeProvider } from "@emotion/react";
import theme from "./mui/theme";
import { Button, Container } from "@mui/material";
import usePlayerStore from "./store/use-player-store";
import SocketManager from "./components/socket-manager/socket-manager";
import Changelog from "./pages/changelog/changelog";
import CvAccess from "./pages/cv-access/cv-access";
import Orders from "./pages/orders/orders";
import WhatsNew from "./components/whats-new/whats-new";
import PrivateRoutes from './services/private-routes';
import NotFound from "./pages/not-found/not-found";
import CloseIcon from '@mui/icons-material/Close';
import history from "./services/router-history";
import SpellCastRecap from "./pages/spell-cast-recap/spell-cast-recap";
import Feed from "./pages/feed/feed";
import ScrollToTop from "./layout/scroll-to-top/scroll-to-top";
import ScrollToTopButton from "./layout/scroll-to-top-button/scroll-to-top-button";
import useUiStore from "./store/use-ui-store";

function App() {
    const restoreFromGlobals = usePlayerStore(state => state.restoreFromGlobals);
    restoreFromGlobals()

    const scrollToBottom = useUiStore(state => state.scrollToBottom)


    const CloseButton = ({ closeToast }) => (
        <Button onClick={closeToast} style={{ height: "40px", padding: "1rem" }}>
            <CloseIcon className="material-icons" />
        </Button>

    );

    //if container = true, the Container component encompass the page component
    const renderPage = (page, container = true) => {
        return container ? (
            <Container maxWidth="md" className="content-container">
                {page}
            </Container>
        ) : (
            <>
                {page}
            </>
        );
    };
    // Load token global when app boot
    return (
        <ThemeProvider theme={theme}>
            <div className='main-container'>

                {/* All the ROUTE logic of the app is in the Router */}

                <Router history={history}>
                    <ScrollToTop />
                    <SocketManager />
                    <WhatsNew />

                    <Header />
                    {scrollToBottom && <ScrollToTopButton />}
                    <Routes>
                        <Route path='/' element={renderPage(<LandingPage />, false)} />
                        <Route path='/rules' element={renderPage(<RulesPage />)} />
                        <Route path='/login' element={renderPage(<Login />)} />
                        <Route path='/signUp' element={renderPage(<SignUp />)} />
                        <Route path='/help' element={renderPage(<Help />)} />

                        {/**Privates routes only access for the authentificated player */}
                        <Route element={<PrivateRoutes />}>
                            <Route path="/dashboard" element={renderPage(<DashBoard />)} />
                            <Route path='/exploration' element={renderPage(<Exploration />)} />
                            <Route path='/construction' element={renderPage(<Construction />)} />
                            <Route path='/army' element={renderPage(<Army />)} />
                            <Route path='/market' element={renderPage(<Market />)} />
                            <Route path='/conquest' element={renderPage(<Conquest />)} />
                            <Route path='/spying' element={renderPage(<Spying />)} />
                            <Route path='/spyings/me' element={renderPage(<SpyingHistory />)} />
                            <Route path='/spyings/:id' element={renderPage(<SpyingRecap />)} />
                            <Route path='/spells' element={renderPage(<Spells />)} />
                            <Route path='/spell-cast' element={renderPage(<SpellCast />)} />
                            <Route path='/active-effect' element={renderPage(<ActiveEffect />)} />
                            <Route path='/spell-casts/:id' element={renderPage(<SpellCastRecap />)} />
                            <Route path='/swap' element={renderPage(<Swap />)} />
                            <Route path='/forum' element={renderPage(<Forum />)} />
                            <Route path='/ranking' element={renderPage(<Ranking />)} />
                            <Route path='/alliance' element={renderPage(<Alliance />)} />
                            <Route path='/messaging' element={renderPage(<Messaging />)} />
                            <Route path='/conquests/:id' element={renderPage(<ConquestRecap />)} />
                            <Route path='/conquests/me' element={renderPage(<ConquestHistory />)} />
                            <Route path='/orders' name={"o"} element={renderPage(<Orders />)} />
                            <Route path='/players/:id' element={renderPage(<Account />)} />
                            <Route path='/update-account' element={renderPage(<UpdateAccount />)} />
                            <Route path='/feeds' element={renderPage(<Feed />)} />
                        </Route>

                        <Route path='/changelog' element={renderPage(<Changelog />)} />
                        <Route path='/cv-access' element={renderPage(<CvAccess />)} />
                        <Route path='/*' element={renderPage(<NotFound />)} />
                    </Routes>
                    <Footer />
                </Router>

                <ToastContainer autoClose={2000} limit={3} transition={Flip} position='bottom-center' closeButton={CloseButton} />
            </div>
        </ThemeProvider>
    )
}

export default App;
