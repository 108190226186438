import React, { useState } from "react";
import FaceCompact from "./face-compact";
import FaceDetailed from "./face-detailed";
import "./citizen-tile.scss"

function CitizenTile({ citizen, productions, upgradeVillagerOrders }) {

    const [detailed, setDetailed] = useState(false);

    const handleClick = () => {
        setDetailed(!detailed);
    };

    const computeClasses = () => {
        const classes = ["citizen-tile-container"]

        if (detailed) {
            classes.push("citizen-tile-container-detailed")
        }

        return classes.join(" ")
    }

    return <div className={computeClasses()}>
        {detailed ? <FaceDetailed
            onClick={handleClick}
            citizen={citizen}
            productions={productions}
            upgradeVillagerOrders={upgradeVillagerOrders}
        /> : <FaceCompact
            onClick={handleClick}
            citizen={citizen}
        />}
    </div>
}

export default CitizenTile