import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import backendRepository from "../../repository/backend-repository";
import ContentPane from "../../layout/content-pane/content-pane";
import {Alert, Button, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import SpellCastEffect from "../../components/spells/spell-cast-effect";
import moment from "moment";
import {MOMENT_FORMAT_HM, thDelimiter} from "../../services/utils";
import "./spell-cast-recap.scss"

function SpellCastRecap() {
    const playerSpell = useRef(null)
    const endOfCast = useRef(null)
    const [spellCast, setSpellCast] = useState(null)
    const [forbiddenAccess, setForbiddenAccess] = useState(false)
    const spellCastId = useParams().id

    const navigate = useNavigate()

    useEffect(() => {
        backendRepository.getSpellCast(spellCastId).then(d => {
            playerSpell.current = {
                spell: d.spell,
                isCooldown: false,
                isUnlock: true
            }
            endOfCast.current = moment(d.createdAt).add(d.spell.duration + 1, 'hours')
            endOfCast.current = endOfCast.current.subtract(endOfCast.current.minutes(), "minutes")
            setSpellCast(d)
        }).catch(e => {
            if (e.response.status === 404) {
                navigate("/404")
            } else if (e.response.status === 403) {
                setForbiddenAccess(true)
            }
        })
    }, [spellCastId, navigate])

    if (forbiddenAccess) {
        return <ContentPane title={"Accès non autorisé"}>
            <Alert severity={"error"}>Vous n'avez pas les droits pour consulter cette page</Alert>
        </ContentPane>
    }

    if (null === spellCast) {
        return null
    }

    return <>
        <Button component={Link} to={"/spell-cast"} variant={"outlined"}>Relancer un sort</Button>
        <ContentPane title={"Rapport de sort"}>
            <div className={`banner banner-single ${spellCast.isWin ? 'banner-spell-victory' : 'banner-spell-defeat'}`}>
                <div className="action-title">
                    {spellCast.isWin ?
                        <h2 className="spell-cast-success">Réussite</h2>
                        :
                        <h2 className="spell-cast-failed">Echec</h2>}
                </div>
            </div>
            <p>
                Date du cast : {moment(spellCast.createdAt).format(MOMENT_FORMAT_HM)}
            </p>
            <TableContainer>
                <Table size={"small"}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Ensorceleur</TableCell>
                            <TableCell>
                                <span className="strong">{spellCast.attacker.nickname}</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Cible</TableCell>
                            <TableCell>
                                <span className="strong">{spellCast.defender.nickname}</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sort lancé</TableCell>
                            <TableCell>
                                <span className="strong">{spellCast.spell.name}</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Durée de l'effet</TableCell>
                            <TableCell>
                                {spellCast.spell.duration > 0 ? `${spellCast.spell.duration} cycles` : "Instantané"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date du cast</TableCell>
                            <TableCell>
                                {moment(spellCast.createdAt).format(MOMENT_FORMAT_HM)}
                            </TableCell>
                        </TableRow>
                        {spellCast.spell.duration > 0 && <TableRow>
                            <TableCell>Date de fin d'effet</TableCell>
                            <TableCell>
                                {endOfCast.current.format(MOMENT_FORMAT_HM)}
                            </TableCell>
                        </TableRow>}
                        <TableRow>
                            <TableCell>Mages engagés</TableCell>
                            <TableCell><strong>{thDelimiter(spellCast.attackerMageCount)}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mages perdus</TableCell>
                            <TableCell><strong>{thDelimiter(spellCast.attackerLostMage)}</strong> ({((spellCast.attackerLostMage / spellCast.attackerMageCount) * 100).toFixed(2)}%)</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={"spell-cast-effect"}>
                {spellCast.isWin ? <SpellCastEffect spellCast={spellCast}/> : "Le sort a échoué"}
            </div>
        </ContentPane>
    </>
}

export  default SpellCastRecap