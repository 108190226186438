import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Button, Divider, IconButton, Menu, MenuList, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import useUiStore from "../../store/use-ui-store";
import {
    ConquestNotification,
    ShortageNotification, SpellCastNotification,
    StarvationNotification, SystemNotification,
    TroopsReturnNotification, SpyingNotification, SpyReturnNotification
} from "./notifications";


const displayedNotifications = 10
function NotificationButton() {
    const fetchNotifications = useUiStore(state => state.fetchNotifications)
    const addMoreNotifications = useUiStore(state => state.addMoreNotifications)
    const markNotificationAsRead = useUiStore(state => state.markNotificationAsRead)
    const markAllNotificationsAsRead = useUiStore(state => state.markAllNotificationsAsRead)
    const deleteAllNotifications = useUiStore(state => state.deleteAllNotifications)
    const notifications = useUiStore(state => state.notifications)
    const [asUnread, setAsUnread] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [hasMoreNotifications, setHasMoreNotifications] = useState(true)
    const open = Boolean(anchorEl);

    const computeAsUnread = (notifications) => {
        setAsUnread(notifications.map(n => !n.isRead ? 1 : 0).reduce((c, acc) => acc + c, 0))
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (_, notification) => {
        if (typeof notification === "object") {
            if (!notification.isRead) {
                markNotificationAsRead(notification)
            }
        } else {
            setAnchorEl(null);
        }
    };
    const handleMarkAllAsRead = () => {
        markAllNotificationsAsRead()
    }

    const handLoadMore = () => {
        const notifications = useUiStore.getState().notifications
        const notificationsLength = notifications.length
        if (notificationsLength < displayedNotifications) {
            setHasMoreNotifications(false)
            return
        }

        const lastNotification = notifications.slice(-1)[0]

        addMoreNotifications(lastNotification.id).then(() => {
            const newLength = useUiStore.getState().notifications.length
            if (newLength === notificationsLength || newLength - notificationsLength < displayedNotifications) {
                setHasMoreNotifications(false)
            }
        })
    }

    useEffect(() => {
        fetchNotifications()
    }, [fetchNotifications])

    useEffect(() => {
        computeAsUnread(notifications)
    }, [notifications])
    return <>
        <IconButton onClick={handleClick}>
            <Badge
                badgeContent={asUnread >= displayedNotifications ? `${displayedNotifications}+` : asUnread}
                sx={{
                    "& .MuiBadge-badge": {
                        color: "black",
                        backgroundColor: "#e3b505"
                    }
                }}
            >
                <NotificationsIcon />
            </Badge>
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <Paper sx={{ maxWidth: "30rem", minHeight: "10em" }}>
                <MenuList>
                    <div className={"ml-4 mr-4"}>
                        <Button disabled={notifications.length === 0} className={"w-full"} color={"error"} variant={"outlined"} onClick={deleteAllNotifications}>Tout supprimer</Button>
                    </div>
                    <div className={"ml-4 mr-4 mt-4"}>
                        <Button disabled={notifications.length === 0 || asUnread === 0} className={"w-full"} color={"info"} variant={"outlined"} onClick={handleMarkAllAsRead}>Tout marquer comme lu</Button>
                    </div>
                    <Divider />
                    {notifications.map(n => {
                        const notificationsProps = {
                            key: n.id,
                            onClick: handleClose,
                            notification: n
                        }
                        switch (n.type) {
                            case "conquest":
                                return <ConquestNotification {...notificationsProps} handleClose={handleClose} />
                            case "troops_return":
                                return <TroopsReturnNotification {...notificationsProps} handleClose={handleClose} />
                            case "spying":
                                return <SpyingNotification {...notificationsProps} handleClose={handleClose} />
                            case "spy_return":
                                return <SpyReturnNotification {...notificationsProps} handleClose={handleClose} />
                            case "starvation":
                                return <StarvationNotification {...notificationsProps} handleClose={handleClose} />
                            case "shortage":
                                return <ShortageNotification {...notificationsProps} handleClose={handleClose} />
                            case "spell_cast":
                                return <SpellCastNotification {...notificationsProps} handleClose={handleClose} />
                            case "system":
                                return <SystemNotification {...notificationsProps} handleClose={handleClose} />
                            default:
                                return null
                        }
                    })}
                    {notifications.length === 0 && <div className={"text-center m-8"}>
                        Pas de notifications pour le moment
                    </div>}
                    {hasMoreNotifications ? <div className={"ml-4 mr-4"}>
                        <Button disabled={notifications.length === 0} className={"w-full"} color={"primary"} variant={"text"} onClick={handLoadMore}>Charger plus</Button>
                    </div> : <div className={"ml-4 mr-4"}>
                        <Button className={"w-full"} color={"primary"} variant={"text"} disabled>Plus de notifications à charger</Button>
                    </div>}
                </MenuList>
            </Paper>
        </Menu>
    </>
}

export default NotificationButton