import { useEffect, useState } from "react"
import "./spell-icon.scss"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Tooltip } from "@mui/material";
import { BiTimeFive } from "react-icons/bi";
import { TbSquareRoundedLetterM } from "react-icons/tb";
import { BiHelpCircle } from 'react-icons/bi'
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import FaceDetailed from "../spell-tile/face-detailed";


function SpellIcon({ type, playerSpell, selectable = false, dialog = false, selected = false, withMana = true }) {

    const [isUnlock, setIsUnlock] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (playerSpell.wizardSchool >= playerSpell.spell.requiredSchool) {
            setIsUnlock(true);
        }
    }, [playerSpell.spell.requiredSchool, playerSpell.wizardSchool, selectable]);

    let className = (isUnlock && !playerSpell.isCooldown) ? "spell-icon" : "spell-icon spell-icon-disabled";
    if (dialog) {
        className += " spell-icon-dialog";
    }

    const cooldownTime = new Date(playerSpell.nextDisponibility).getHours();

    const handleDialogClose = (e) => {
        setDialogOpen(false);
        e.stopPropagation()
    };

    return <div className={"spell-icon-container"}>
        <img src={require(`../../assets/img/spells/${type}.webp`)}
            className={className}
            alt="spell-icon" />
        {selected && <CheckBoxIcon fontSize={"medium"} className={"spell-icon-top selected-spell"} />}
        {playerSpell.isCooldown && (
            <Tooltip placement={"top"} className={"spell-icon-top"} title={`disponible à  ${cooldownTime}h 00m`}>
                <span><BiTimeFive size={30} className="coolDown-timer" /></span>
            </Tooltip>
        )}
        {selectable && (
            <Tooltip
                placement={"top"}
                className={"spell-icon-right"}
                title={"Détails du sort"}
                onClick={(e) => {
                    e.stopPropagation()
                    setDialogOpen(true)
                }}
            >
                <span><BiHelpCircle size={25} /></span>
            </Tooltip>
        )}
        {withMana && <span className={"spell-icon-bottom strong"}>{playerSpell.spell.cost}<TbSquareRoundedLetterM size={30} /></span>}
        <Dialog
            sx={{ width: '100%', margin: '0px', padding: '0px' }}
            open={dialogOpen}
            onClose={handleDialogClose}>
            <DialogTitle>Details du sort</DialogTitle>
            <DialogContent sx={{ width: '100%', margin: '0px', padding: '0px' }}>
                <FaceDetailed playerSpell={playerSpell} />
            </DialogContent>
        </Dialog>
    </div>
}

export default SpellIcon