import "./conquest-versus.scss"
import ShieldIcon from '@mui/icons-material/Shield';
import PlayerName from "../../layout/player-name";

function ConquestVersus({attacker, defender}) {
    return (<div className={"versus-container"}>
        <div className={"versus-side attacker"}>
            <PlayerName player={attacker} />
        </div>
        <div className={"versus-icon"}>
            <ShieldIcon className={"icon"} style={{fontSize: "3em"}} />
        </div>
        <div className={"versus-text"}>
            <div>VS</div>
        </div>
        <div className={"versus-side defender"}>
            <PlayerName player={defender} />
        </div>
    </div>)
}

export default ConquestVersus