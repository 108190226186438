import "./resource-hamburger.scss"
import {useEffect, useState} from "react";
import React from "react"
import {Box, Drawer, IconButton} from "@mui/material";
import usePlayerStore from "../../store/use-player-store";
import ElementChip from "../element-chip/element-chip";
import CategoryIcon from '@mui/icons-material/Category';
import ManaProgress from "../mana-progress/mana-progress";

function ResourceHamburger() {
    const [state, setState] = useState(false);

    const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const fetchResources = usePlayerStore(state => state.fetchPlayerResources)
    const fetchPlayerProductions = usePlayerStore(state => state.fetchPlayerProductions)

    useEffect(() => {
        Promise.all([fetchResources(), fetchPlayerProductions()])
            .catch(e => console.log(e))
    }, [fetchResources, fetchPlayerProductions]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };
    return <div>
        <React.Fragment>
            <IconButton size={"large"} onClick={toggleDrawer(true)}>
                <CategoryIcon />
            </IconButton>
            <Drawer
                anchor={"right"}
                open={state}
                onClose={toggleDrawer(false)}
            >
                <div className="hamburger-resource-list">
                    <Box role={"presentation"}>
                        <h3 className={"text-center"}>Mana</h3>
                        <ManaProgress />
                        <h3 className={"text-center"}>Ressources</h3>
                        {/* map playerResource and display information in LI balise */}
                        {resourceStocks.map((item) => (
                            <ElementChip className={"mb-4"} key={item.id} item={item} />
                        ))}
                    </Box>
                </div>
            </Drawer>
        </React.Fragment>
    </div>
}

export default ResourceHamburger