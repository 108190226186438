import "./buy-construction-card.scss";
import { useState } from "react";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { toast } from "react-toastify";

function DestroyConstructionInput({ playerConstruction, construction, onDestroy = () => { } }) {

    const [quantityToDestroy, setQuantityToDestroy] = useState(0);
    const handleDestroy = () => {
        toast.info(
            <div>
                <p>
                    Voulez-vous démanteler {quantityToDestroy} {construction.name} ?
                </p>
                <div className="toastify-button-container">
                    <Button
                        className="button-hamburger"
                        onClick={() => {
                            onDestroy(quantityToDestroy, playerConstruction.construction);
                            setQuantityToDestroy(0);
                        }}
                    >
                        Oui
                    </Button>
                    <Button className="button-hamburger" onClick={() => { }}>
                        Non
                    </Button>
                </div>
            </div>,
            {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: false,
                closeOnClick: true,
                closeButton: false,
            }
        );
    };

    const handleDestroyQuantityChange = (event) => {
        event.preventDefault();
        let newQuantity = parseInt(event.target.value);
        setQuantityToDestroy(newQuantity);
    };

    const handleMaxDestroy = () => {
        setQuantityToDestroy(playerConstruction.quantity);
    };

    const handleResetDestroy = () => {
        setQuantityToDestroy(0);
    };

    return (
        <>
            <label className="strong mt-10" htmlFor={`quantityInput${construction.id}`}>
                Quantité à démanteler:
            </label>

            <div className="action-input-container">
                <TextField
                    size="small"
                    className="action-input"
                    id={`quantityInput${construction.id}`}
                    type="number"
                    value={isNaN(quantityToDestroy) ? "" : quantityToDestroy}
                    onChange={(event) => handleDestroyQuantityChange(event)}
                />

                <ButtonGroup variant="outlined">
                    <Button onClick={handleMaxDestroy}>Max</Button>
                    <Button onClick={handleResetDestroy}>Reset</Button>
                    <Button
                        variant="contained"
                        disabled={
                            quantityToDestroy <= 0 ||
                            quantityToDestroy > playerConstruction.quantity ||
                            isNaN(quantityToDestroy) ||
                            quantityToDestroy === ""
                        }
                        color="error"
                        onClick={handleDestroy}
                    >
                        Démanteler
                    </Button>
                </ButtonGroup>
            </div>
        </>
    );
}

export default DestroyConstructionInput;