import './landing-component.scss'

function LandingComponent({ title, content, backgroundClass, alignment }) {
    return (
        <div className={`background-landing ${backgroundClass} ${alignment === 'right' ? 'align-right' : 'align-left'}`}>
            <div className={`landing-container ${alignment === 'right' ? 'landing-container-right' : 'landing-container-left'}`}>

                <div className='landing-title'><h2>{title}</h2></div>
                <div className='landing-content'>
                    <>
                        {content}
                    </>
                </div>
            </div>
        </div >
    );
}

export default LandingComponent;