import { toast } from 'react-toastify';
import { Button } from "@mui/material";
import backendRepository from "../../repository/backend-repository";
import ContentPane from '../../layout/content-pane/content-pane';
import { useNavigate } from 'react-router-dom';
import usePlayerStore from '../../store/use-player-store';

function DangerZone() {

    const logout = usePlayerStore(state => state.logout)
    const navigate = useNavigate();

    const handleConfirmDelete = () => {
        toast.info(
            <div>
                <p>Votre compte sera DÉFINITIVEMENT supprimé.</p>
                <p>Voulez-vous continuer ?</p>
                <div className='w-full flex justify-space-evenly'>
                    <Button onClick={handleDeleteAccount}>Oui</Button>
                    <Button>Non</Button>
                </div>
            </div>,
            { autoClose: false }
        );
    };

    const handleDeleteAccount = async () => {
        try {
            await new Promise((resolve) => {
                toast.promise(
                    new Promise((innerResolve) => {
                        setTimeout(innerResolve, 5000); // Pause de 5 secondes
                    }),
                    { pending: 'Veuillez patienter...' }
                );

                setTimeout(resolve, 5000); // Pause de 5 secondes avant de résoudre la promesse extérieure
            });

            await backendRepository.deletePlayer();

            logout()
            navigate("/");
            window.location.reload()
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ContentPane title="Zone de danger">
            <div>
                <h3>Suppression du compte</h3>
                <p className='w-full flex justify-space-between align-center'>
                    En cliquant sur supprimer le compte, vous perdrez à jamais votre personnage :
                    <Button variant="contained" color='error' margin='dense' onClick={handleConfirmDelete}>Supprimer le compte</Button>
                </p>

            </div>
        </ContentPane>
    )
}

export default DangerZone