export function BacchusFavorNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Faveur de Bacchus, mais il a <strong>échoué</strong>.
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Faveur de Bacchus, qui augmente la natalité de <strong>{(notification.body.spell.effect.productionIncrease * 100).toFixed(2)}%</strong>.
    </div>
}

export function BacchusFavorSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>La production de villageois de {spellCast.defender.nickname} est augmentée de {(spellCast.effect.productionIncrease * 100).toFixed(2)}%</span>
    }
    return <span>Augmentation de la production des maisons de {(spellCast.effect.productionIncrease * 100).toFixed(2)}%</span>
}
