import React, { useState } from "react";
import { Button, Dialog, Divider, Table, TableCell, TableContainer, TableHead, TableRow, Alert, AlertTitle } from '@mui/material';
import ConstructionAction from "../construction-action/construction-action";
import "./construction-tile.scss"
import ConstructionCost from "./construction-cost";
import ConstructionProduction from "./construction-production";
import {thDelimiter} from "../../services/utils";

function FaceDetailed({ onClick, playerConstruction, playerProduction, sumOfCitizen, constructionOrder }) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOnClick = () => {
        if (!dialogOpen) {
            onClick()
        }
    }

    const openDialog = (event) => {
        event.stopPropagation();
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);

    };

    return (
        <div key={playerConstruction.id} onClick={handleOnClick} className="tile tile-detailed">
            <div className="tile-header tile-detailed-header">
                <img
                    src={require(`../../assets/img/icon/${playerConstruction.construction.type}.webp`)}
                    className="ressource-icon"
                    alt="resoure-icon"
                    rel="preload"
                    as="image"
                    style={{borderTopLeftRadius: "5px"}}
                />
                <h3>{playerConstruction.construction.name}</h3>
                <Button variant="contained" onClick={openDialog}>
                    Construire
                </Button>
            </div>
            <Divider />
            <div className="tile-body">

                <Alert severity="success" icon={false}>
                    <AlertTitle>{playerConstruction.construction.name}</AlertTitle>
                    <p>{playerConstruction.construction.description}</p>
                </Alert>
                <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ lineHeight: "100px" }}>
                                <TableCell>Batiments actuels </TableCell>
                                <TableCell>{thDelimiter(playerConstruction.quantity)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Bâtiments en construction </TableCell>
                                <TableCell>{thDelimiter(constructionOrder)}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>

                <ConstructionProduction
                    playerProduction={playerProduction}
                    playerConstruction={playerConstruction}
                    sumOfCitizen={sumOfCitizen}
                />

                <ConstructionCost playerConstruction={playerConstruction} />

                <Dialog open={dialogOpen} onClose={closeDialog}>
                    <ConstructionAction filter={playerConstruction.construction.id} />
                </Dialog>

            </div>
        </div>
    );
}

export default FaceDetailed;