import './countdown-cycle.scss'

import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const CountdownCycle = () => {
  const [nextHour, setNextHour] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const nextHourDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
      setNextHour(nextHourDate);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {nextHour && (
          <div className={"countdown-chip"}>
            <AccessAlarmIcon className={"countdown-item mr-4"} />
            <Moment
                className={"countdown-item"}
                durationFromNow
                duration={new Date()}
                date={nextHour}
                format="mm[m]ss[s]"
            />
          </div>
      )}
    </>
  );
};

export default CountdownCycle;
