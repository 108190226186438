import { TableContainer, Table, TableBody, TableCell, TableRow } from "@mui/material"
import game from "../../assets/game/game";
import Chip from "../../layout/chip/chip";

function SpellRecap({ playerSpell }) {

    return (
        <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }}>
            <Table size={"small"}>
                <TableBody>
                    <TableRow>
                        <TableCell>Cout</TableCell>
                        <TableCell align="right">{playerSpell.spell.cost} Mana</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Durée de l'effet</TableCell>
                        <TableCell align="right">
                            {playerSpell.spell.duration === 0 && "Instantané"}
                            {playerSpell.spell.duration > 0 && <>{playerSpell.spell.duration} Cycles</>}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Temps de recharge</TableCell>
                        <TableCell align="right">
                            {playerSpell.spell.coolDown} Cycles
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dangerosité</TableCell>
                        <TableCell align="right">
                            <Chip type={game.MAGIC.COLOR[playerSpell.spell.dangerosity]}>{game.MAGIC.LEVEL[playerSpell.spell.dangerosity]}</Chip>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disponible à</TableCell>
                        <TableCell align="right">
                            {playerSpell.isCooldown === true ? (
                                <span>{new Date(playerSpell.nextDisponibility).getHours()}h 00m</span>
                            ) : (
                                "Prêt à l'utilisation"
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SpellRecap