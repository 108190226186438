import {FormControlLabel, Switch} from "@mui/material";

function GameOptionSwitch({label = "", checked, onChange, help = null}) {
    return <>
        <FormControlLabel
            control={<Switch checked={checked} onChange={onChange} />}
            label={label}
        />
        {help && <div className={"mb-7 italic"}>{help}</div>}
    </>
}

export default GameOptionSwitch