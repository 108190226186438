import Game from "../../assets/game/game";

function CitizenCost({ citizen, stocksExceeded, computedVillagerCost, computedResourceCost, resourceCostName, maxQuantity }) {

    const gameData = Game.CITIZEN_UPGRADE;
    return (
        <div className={`banner banner-${citizen.resource.type}`}>
            <div className="action-title">
                <h3>{citizen.resource.name}</h3>
                {citizen.resource.type !== "villager" && (
                    <ul className='action-cost'>
                        <li key={`villager`} className={stocksExceeded[0] ? "exceed-cost" : ""}>
                            {computedVillagerCost} {gameData.ALL.RESOURCE}
                        </li>
                        {computedResourceCost.map((cost, index) => (
                            <li key={`resource-${index}`} className={stocksExceeded[index + 1] ? "exceed-cost" : ""}>
                                {cost} {resourceCostName[index]}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

        </div>
    )
}

export default CitizenCost