import React from "react";
import ResourceIcon from "../resource-icon/resource-icon";
import {thDelimiter} from "../../services/utils";


function FaceCompact({ onClick, citizen }) {


    return <div onClick={onClick}
        key={citizen.id}
        className={`tile tile-compact tile-header`}>
        <ResourceIcon style={{borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} type={citizen.resource.type} />
        <h3>{citizen.resource.name}</h3>
        <p>{thDelimiter(citizen.quantity)}</p>
    </div>;
}

export default FaceCompact