import React, { useState, useEffect } from "react";
import './search-players-by-lands.scss';
import DelayedInput from "../../layout/form/delayed-input";
import PlayerSelector from "../../layout/player-selector/player-selector";
import Pagination from "../../layout/pagination/pagination";
import backendRepository from "../../repository/backend-repository";
import {Alert, Skeleton} from "@mui/material";
import Chip from "../../layout/chip/chip";
import ConquestCounter from "../conquest-counter/conquest-counter";
import PlayerName from "../../layout/player-name";

/**
 * @param onPlayerSelected
 * @param player
 * @param lastConquests
 * @param tooLowFreeLand
 * @param displayQuota
 * @param allowSelf
 * @returns {JSX.Element}
 * @constructor
 */
function SearchPlayersByLands({
                                  onPlayerSelected = () => {},
                                  player = null,
                                  lastConquests = [],
                                  tooLowFreeLand = false,
                                  displayQuota = true,
                                  allowSelf = false
}) {

    const [target, setTarget] = useState(player)

    //userResult is an array of the repository method call searchUsersByLands and all players with same quantity of lands
    const [results, setResult] = useState([]);
    //query is an argument tu searchUsersByLand. It take a string and return players who's contain the query
    const [query, setQuery] = useState("");
    //searchUsersByLands includes pagination function. Each page contain a number of players
    const [pages, setPages] = useState(1)
    const [pending, setPending] = useState(false)

    // Fetch data for the first time
    useEffect(() => {
        const fetchData = async (query) => {
            try {
                const results = await backendRepository.searchUsersByLands(query, pages, allowSelf);
                setResult(results);
            } catch (error) {
                console.error(error);
            }
        };

        setPending(true)
        fetchData(query).finally(() => {
            setPending(false)
        });
    }, [query, pages, allowSelf]);


    const handlePlayerSelection = player => {
        onPlayerSelected(player)
        setTarget(player)
    }

    //catch text in the form and send to query. The pagination return to the first page every render
    const handleInputChange = (event) => {
        setPages(1)
        setQuery(event);
    };

    return (
        <>
            {results.length === 0 ?
                <Skeleton animation="wave" height={400} />
                :
                <div className="search-user-container">
                    <div className={"mt-8 text-center"}>
                        Choisissez la cible de votre attaque
                    </div>
                    <form className="users-search-form">
                        <h3>Cible sélectionnée : {target && <Chip><PlayerName player={target} /> </Chip>}</h3>
                        {displayQuota && target && <ConquestCounter conquests={lastConquests} />}
                        <DelayedInput onChange={handleInputChange} timeout={1000} />
                    </form>


                    {results && results.players &&
                        <PlayerSelector players={results.players} onChange={handlePlayerSelection} pending={pending} />
                    }

                    <Pagination
                        currentPage={results.currentPage}
                        totalPages={results.totalPages}
                        onPageSelection={setPages}
                    />

                    {tooLowFreeLand && <Alert className={"mt-8"} severity={"error"}>Vous ne pouvez pas attaquer ce joueur, il a un niveau trop faible</Alert>}
                </div>
            }
        </>
    );
}

export default SearchPlayersByLands;
