import './spying.scss'
import usePlayerStore from "../../store/use-player-store";
import ConquestVersus from '../../components/conquest-versus/conquest-versus';
import SpyingForChip from '../../components/spying-for-chip/spying-for-chip';

function SpyingSummary({target, spyQuantity,spyType}) {
    const player = usePlayerStore(state => state.player)
    return <div>
        <h2>Résumé de votre mission d'espionnage</h2>
        <ConquestVersus defender={target} attacker={player} />
        <div>
            <span className={"strong"}>Espionnage pour :</span>
            <SpyingForChip spyType={spyType} detailed />
        </div>
        <p>Vous mobilisez <strong>{spyQuantity} espions</strong></p>

        <p>
            Espionner un joueur est une operation risquée. Si la mission échoue votre
            adversaire sera informé de vos agissements.
        </p>
    </div>
}

export default SpyingSummary