import {Chip} from "@mui/material";
import game from "../../assets/game/game";

function ConquestForChip({conquestType, detailed = false}) {
    if (! detailed) {
        return <span>{game.CONQUEST_TYPES[conquestType].ICON(detailed)}</span>
    }

    return <Chip
        size={"small"}
        variant={"filled"}
        icon={game.CONQUEST_TYPES[conquestType].ICON(detailed)}
        color={game.CONQUEST_TYPES[conquestType].COLOR}
        className={"strong"}
        label={game.CONQUEST_TYPES[conquestType].NAME} />
}

export default ConquestForChip