import React, { useEffect, useState } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import backendRepository from '../../repository/backend-repository';
import usePlayerStore from "../../store/use-player-store";

import ContentBox from '../../layout/content-box/content-box'
import CollapseBox from "../../layout/collapse-box/collapse-box";
import Chip from "../../layout/chip/chip"

import { Button, Skeleton, Table, TableRow, TableContainer, TableBody, TableCell, TableHead } from '@mui/material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LandscapeIcon from '@mui/icons-material/Landscape';
import BadgeIcon from '@mui/icons-material/Badge';
import TimelineIcon from '@mui/icons-material/Timeline';
import Groups3Icon from '@mui/icons-material/Groups3';
import HandshakeIcon from '@mui/icons-material/Handshake';

function Account() {
    const { id } = useParams();
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const player = usePlayerStore(state => state.player)
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const [searchParams] = useSearchParams()

    const fromRanking = searchParams.get("from-ranking") === ""


    useEffect(() => {
        const getPlayerInfo = async () => {
            try {
                const playerData = await backendRepository.getPlayer(id);
                setCurrentPlayer(playerData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchPlayer()
        getPlayerInfo();
    }, [id, player, fetchPlayer]);

    return (
        <div>
            {currentPlayer ? (
                <>
                    {fromRanking && <Button variant={"outlined"} component={Link} to={"/ranking"}>Retour</Button>}
                    <ContentBox header={currentPlayer.nickname}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Infos</TableCell>
                                        <TableCell align="right">Seigneur</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                            <BadgeIcon style={{ marginRight: '5px' }} />
                                            <p>Nom</p>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Chip type="danger">{currentPlayer.nickname}</Chip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                            <Groups3Icon style={{ marginRight: '5px' }} />
                                            Race
                                        </TableCell>
                                        <TableCell align="right">
                                            <Chip type="warning">{currentPlayer.race}</Chip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                            <TimelineIcon style={{ marginRight: '5px' }} />
                                            Age
                                        </TableCell>
                                        <TableCell align="right">
                                            <Chip type="success">{currentPlayer.age} ans</Chip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                            <HandshakeIcon style={{ marginRight: '5px' }} />
                                            Alliance
                                        </TableCell>
                                        <TableCell align="right">
                                            {currentPlayer.alliance !== null ? <Chip type="info">{currentPlayer.alliance}</Chip> : null}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                            <LandscapeIcon style={{ marginRight: '5px' }} />
                                            Terres totales
                                        </TableCell>
                                        <TableCell align="right">
                                            <Chip>{currentPlayer.totalLands}</Chip>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <CollapseBox icon={<HistoryEduIcon />} header="Background">
                            {currentPlayer.background === null ?
                                "Ce joueur n'a pas encore ecrit le background de son seigneur"
                                :
                                currentPlayer.background
                            }
                        </CollapseBox>

                        {/*Check if the player is the owner of this player's page */}
                        <div className={'w-full flex flex-row justify-flex-end mr-10'}>
                            {currentPlayer.id === player.id ?
                                <Button variant="contained" component={Link} to={"/update-account"}>Paramètres du compte</Button>
                                :
                                <Button variant="contained" component={Link} to={`/conquest#conquest`}>Attaquer {currentPlayer.nickname}</Button>
                            }
                        </div>
                    </ContentBox>
                </>
            ) : (
                <Skeleton animation="wave" height={550} />
            )}
        </div>
    );
}

export default Account;