import { useEffect, useRef, useState } from "react";
import backendRepository from "../../repository/backend-repository";
import OrderList from "../../components/order-list/order-list";
import ContentPane from "../../layout/content-pane/content-pane";
import useGameStore from "../../store/use-game-store";
import usePlayerStore from "../../store/use-player-store";
import Chip from "../../layout/chip/chip";
import "./orders.scss"
import RuleLink from "../../layout/rule-link/rule-link";

function Orders() {
    const findConstruction = useGameStore(state => state.findConstruction)
    const fetchConstructions = useGameStore(state => state.fetchConstructions)
    const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)
    const findResourceStockByResource = usePlayerStore(state => state.findResourceStockByResource)
    const totalOrders = useRef(0)

    const [orders, setOrders] = useState({
        constructionOrders: [],
        explorationOrders: [],
        upgradeVillagerOrders: [],
    })

    useEffect(() => {
        Promise.all([fetchConstructions(), fetchPlayerResources()]).then(() => {
            backendRepository.getOrders().then(d => {
                for (const i in d.constructionOrders) {
                    d.constructionOrders[i].construction = findConstruction(d.constructionOrders[i].constructionId)
                }

                for (const i in d.upgradeVillagerOrders) {
                    d.upgradeVillagerOrders[i].resource = findResourceStockByResource(d.upgradeVillagerOrders[i].resourceId).resource
                }
                totalOrders.current = d.constructionOrders.length + d.upgradeVillagerOrders.length + d.explorationOrders.length
                setOrders(d)
            })
        })
    }, [fetchConstructions, fetchPlayerResources, findConstruction, findResourceStockByResource])
    return <>
        <ContentPane title={"Actions pour ce tour"}>
            <div className={"text-center"}>
                <p>
                    Les actions suivantes sont celles que vous avez déclenchées à ce tour.
                </p>
                <p>
                    Elles seront prises en compte <strong>au début du prochain tour</strong>.
                </p>
                <p className={"text-right"}>
                    <RuleLink section={"cycles"}>Les cycles</RuleLink>
                </p>
                <div className={"order-kpi-container"}>
                    <div className={"order-kpi"}>
                        <div className={"strong"}>Total</div>
                        <div className={"mt-6"}>
                            <Chip size={"lg"}>{totalOrders.current}</Chip>
                        </div>
                    </div>
                    <div className={"order-kpi"}>
                        <div className={"strong"}>Explorations</div>
                        <div className={"mt-6"}>
                            <Chip size={"lg"}>{orders.explorationOrders.length}</Chip>
                        </div>
                    </div>
                    <div className={"order-kpi"}>
                        <div className={"strong"}>Construction</div>
                        <div className={"mt-6"}>
                            <Chip size={"lg"}>{orders.constructionOrders.length}</Chip>
                        </div>
                    </div>
                    <div className={"order-kpi"}>
                        <div className={"strong"}>Formations</div>
                        <div className={"mt-6"}>
                            <Chip>{orders.upgradeVillagerOrders.length}</Chip>
                        </div>
                    </div>
                </div>
            </div>
        </ContentPane>
        <OrderList
            title={"Explorations"}
            elements={orders.explorationOrders}
            rowRenderer={e => `${e.quantity} terres en cours d'exploration`}
            onEmpty={"Pas d'exploration en cours"}
        >
            Liste des explorations en cours
        </OrderList>
        <OrderList
            title={"Constructions"}
            elements={orders.constructionOrders}
            rowRenderer={e => <>{e.quantity} <strong>{e.construction.name}</strong> en cours de construction</>}
            onEmpty={"Pas de batiment en cours de construction"}
        >
            Liste des batiments en cours de construction
        </OrderList>
        <OrderList
            title={"Armée"}
            elements={orders.upgradeVillagerOrders}
            rowRenderer={e => <>{e.quantity} villageois en formation pour devenir <strong>{e.resource.name}</strong></>}
            onEmpty={"Pas de citoyens en formation"}
        >
            Liste des soldats en cours de formation
        </OrderList>
    </>
}

export default Orders