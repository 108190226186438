import { createTheme } from "@mui/material";

const theme = createTheme({
    /*typography: {
        allVariants: {
            color: "white"
        }
    },*/
    palette: {
        mode: "dark",
        primary: {
            main: "#75ABBC"
        },

        secondary: {
            main: "#384972"
        },

        background: {
            //#161622
            main: "#75ABBC",
            paper: "#31313B"
        },

        grey: {
            //main: "#fff"
        },

        text: {
            //primary: "#eaeaea"
        },
    }
})

export default theme