import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import PageTitle from "../../layout/page-title/page-title";
import { useEffect, useState } from "react";
import backendRepository from "../../repository/backend-repository";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import moment from "moment";
import ContentPane from "../../layout/content-pane/content-pane";
import ConquestForChip from "../../components/conquest-for-chip/conquest-for-chip";
import SpyingForChip from "../../components/spying-for-chip/spying-for-chip"
import FeedItemDialog from "./feed-item-dialog";

function Feed() {

    const [autoRefresh, setAutoRefresh] = useState(true)
    const [history, setHistory] = useState({
        conquestAndSpying: [],
        currentPage: 1,
        totalPages: 1,
    })

    const [openDialog, setOpenDialog] = useState(false)
    const [dialogElement, setDialogElement] = useState(null)
    const [dialogType, setDialogType] = useState(null)

    useEffect(() => {
        backendRepository.getFeed({ page: 1 }).then(d => {
            setHistory(d)
        })
    }, [])

    useEffect(() => {
        if (!autoRefresh) {
            return;
        }
        const interval = setInterval(() => {
            backendRepository.getFeed({ page: history.currentPage }).then(d => {
                setHistory(d)
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [autoRefresh, history.currentPage])

    const handleClick = (element, type) => () => {
        setDialogType(type)
        setDialogElement(element)
        setOpenDialog(true)
    }

    const handleDialogClose = () => {
        setDialogElement(null)
        setDialogType(null)
        setOpenDialog(false)
    }

    return <>
        <PageTitle>Feed</PageTitle>
        <ContentPane>
            <p>
                Tenez vous informés des 50 dernières conquetes
            </p>
            <div>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            checked={autoRefresh}
                            onChange={(event) => setAutoRefresh(event.target.checked)}
                            control={<Checkbox />}
                            label="Rafraichissement auto."
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </div>
            <List dense sx={{ bgcolor: 'background.paper' }}>
                {history.conquestAndSpying.map((report) => {
                    if (report.conquestId !== null) {

                        return <ListItem alignItems={"center"} key={report.conquestId} sx={{ color: "white" }}>
                            <ListItemIcon>
                                <GpsFixedIcon style={{ fontSize: "32px" }} className={report.isWin ? "victory-text" : "defeat-text"} fontSize={"large"} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<div className={"flex flex-row justify-space-between"}>
                                    <div>
                                        <ConquestForChip conquestType={report.conquestType} /> <strong>{report.attackerNickname}</strong> a {report.isWin ? "gagné" : "perdu"} sa conquete contre <strong>{report.defenderNickname}</strong>
                                    </div>
                                    <Button onClick={handleClick(report, "conquest")} variant={"contained"} color={"primary"}>Detail</Button>
                                </div>}
                                secondary={
                                    <>{moment(report.createdAt).format("YYYY/MM/DD HH:mm:SS")}</>
                                }
                            />
                        </ListItem>
                    } else if (report.spyingId !== null) {
                        return <ListItem alignItems={"center"} key={report.spyingId} sx={{ color: "white" }}>
                            <ListItemIcon>
                                <GpsFixedIcon style={{ fontSize: "32px" }} className="defeat-text" fontSize={"large"} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<div className={"flex flex-row justify-space-between"}>
                                    <div>
                                        <SpyingForChip spyType={report.spyingType} /> <strong>{report.attackerNickname}</strong> a perdu son espionnage contre <strong>{report.defenderNickname}</strong>
                                    </div>
                                    <Button onClick={handleClick(report, "spying")} variant={"contained"} color={"primary"}>Detail</Button>
                                </div>}
                                secondary={
                                    <>{moment(report.createdAt).format("YYYY/MM/DD HH:mm:SS")}</>
                                }
                            />
                        </ListItem>
                    } else {
                        return null
                    }
                })}
            </List>
        </ContentPane>
        <FeedItemDialog open={openDialog} element={dialogElement} type={dialogType} onClose={handleDialogClose} />
    </>
}

export default Feed