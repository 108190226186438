import ManaPane from '../../components/mana-pane/mana-pane';
import PageTitle from '../../layout/page-title/page-title';
import HelperPane from '../../layout/helper-pane/helper-pane';
import SpellsList from '../../components/spell-tile/spell-list'
import { Button } from '@mui/material';
import { Link } from "react-router-dom";

function Spells() {

  return <>
    <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/spell-cast"} variant={"outlined"}>Lancer un sort</Button>
    <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/active-effect"} variant={"outlined"}>Sorts actifs</Button>
    <PageTitle>Liste des sorts</PageTitle>
    <HelperPane linkTo={"magic"}>
      <p>
        Vous trouverez ici la liste des sorts disponibles dans le jeu. Pour débloquer un sort, vous devez posséder suffisamment d'écoles de magie.
        Le chiffre en bas à gauche du sort représente la quantité de mana nécessaire pour l'utiliser.
      </p>
      <p>
        Les sorts débloqués ont un "temps de recharge" défini en cycles. Si un sort est en train de se recharger, une icône d'horloge apparaîtra.
        En survolant l'icône, vous pourrez connaître l'heure à laquelle le sort sera à nouveau disponible.
      </p>
    </HelperPane>


    <ManaPane />
    <SpellsList title="Gallerie des sorts" />
  </>
}

export default Spells;
