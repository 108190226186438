import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import BonusList from "../../layout/bonus/bonus-list";
import ResourceIcon from "../../components/resource-icon/resource-icon";
import ShieldIcon from '@mui/icons-material/Shield';
import {thDelimiter} from "../../services/utils";

function DefenderDetails({meta, defender}) {
    return <>
        <h3 className={"conquest-recap-title"}><ShieldIcon className={"mr-2"} />Détail défensif :</h3>
        <p>
            Défenseur : {defender.nickname}
        </p>
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Unité</strong></TableCell>
                        <TableCell><strong>Valeur défensive</strong></TableCell>
                        <TableCell><strong>Engagés</strong></TableCell>
                        <TableCell><strong>Perdus</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <div className={"flex flex-row flex-items-center"}>
                                <ResourceIcon type={"soldier"} />
                                <span className={"strong ml-2"}>Soldats</span>
                            </div>
                        </TableCell>
                        <TableCell>{thDelimiter(meta.troops.soldier.value)}</TableCell>
                        <TableCell>{thDelimiter(meta.troops.soldier.quantity)}</TableCell>
                        <TableCell>{meta.lostTroops.soldier ? thDelimiter(meta.lostTroops.soldier) : "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <div className={"flex flex-row flex-items-center"}>
                                <ResourceIcon type={"fortification"} />
                                <span className={"strong ml-2"}>Fortifications</span>
                            </div>
                        </TableCell>
                        <TableCell>{thDelimiter(meta.troops.fortification.value)}</TableCell>
                        <TableCell>{thDelimiter(meta.troops.fortification.quantity)}</TableCell>
                        <TableCell>-</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <BonusList bonus={meta.bonus}  emptyMessage={"Pas de bonus magique appliqué sur le défenseur"} />
    </>
}

export default DefenderDetails