import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Bonus from "./bonus";
import SpellCastEffect from "../../components/spells/spell-cast-effect";

function BonusList({ bonus = [], emptyMessage = "Pas de bonus appliquée sur cette conquete" }) {
    if (bonus.length === 0) {
        return null;
    }
    // console.log(bonus)
    return <div className={"mt-2"}>
        <h4>Effets actifs :</h4>
        {bonus.map((b, i) => (
            <span key={i} className={"flex flex-row flex-items-center"}>
                <ArrowForwardIosIcon sx={{ height: "0.8em" }} /><SpellCastEffect spellCast={{ type: b.type, effect: b.effect }} detailed={false} />
            </span>
        ))}
        {bonus.length === 0 && <span>{emptyMessage}</span>}
    </div>
}
export default BonusList