import {thDelimiter} from "../../services/utils";

export function FireBallSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Boule de Feu qui a <strong>échoué</strong>.
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Boule de Feu qui a brûlé <strong>{thDelimiter(notification.body.spell.effect.woodDestroyed)} bois</strong> dans votre stock.
    </div>
}

export function FireBallSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Vous avez brûlé {thDelimiter(spellCast.effect.woodDestroyed)} bois dans le stock de {spellCast.defender.nickname}</span>
    }
    return <span>Vous avez brûlé {thDelimiter(spellCast.effect.woodDestroyed)} bois</span>
}
