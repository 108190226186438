import { Alert, AlertTitle, Box, LinearProgress } from "@mui/material";

function RequiredSchool({ playerSpell, dialog = false }) {

    const value =
        playerSpell.wizardSchool >= playerSpell.spell.requiredSchool ?
            (playerSpell.spell.requiredSchool / playerSpell.spell.requiredSchool) * 100
            :
            (playerSpell.wizardSchool / playerSpell.spell.requiredSchool) * 100

    const wizardSchoolRemaining = playerSpell.spell.requiredSchool - playerSpell.wizardSchool
    return (
        <>
            <div className="w-full flex flex-col justify text-center">
                <span className={"strong"}>Ecoles requises :</span>
                {playerSpell.wizardSchool < playerSpell.spell.requiredSchool ? (
                    <>
                        <span>{playerSpell.wizardSchool} / {playerSpell.spell.requiredSchool}</span>
                    </>
                ) : (
                    <>

                        <span>{playerSpell.spell.requiredSchool} / {playerSpell.spell.requiredSchool}</span>
                    </>
                )}
            </div>

            <Box sx={{ width: '100%', pt: 1 }}>
                <LinearProgress
                    sx={{ height: 8 }}
                    color={value >= 100 ? "success" : "primary"}
                    value={value}
                    variant={"determinate"} />
            </Box>
            {dialog && (
                <Alert
                    sx={{ width: '100%', paddingLeft: '0%', paddingRight: '0%', display: "flex", alignItems: "center", justifyContent: 'center' }}
                    severity={playerSpell.wizardSchool < playerSpell.spell.requiredSchool ? (
                        "warning"
                    ) : (
                        "success"
                    )}
                >
                    <AlertTitle>{playerSpell.wizardSchool < playerSpell.spell.requiredSchool ? (
                        <p className="spell-unlock-text">{wizardSchoolRemaining} écoles manquantes</p>
                    ) : (
                        <p className="spell-unlock-text">Sort débloqué</p>
                    )}
                    </AlertTitle>
                </Alert >
            )
            }
        </>
    )
}

export default RequiredSchool