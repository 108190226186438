import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backendRepository from "../../repository/backend-repository";
import Pagination from "../../layout/pagination/pagination";
import usePlayerStore from "../../store/use-player-store";
import { Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PlayerName from "../../layout/player-name";

function RankingArray() {

    const navigate = useNavigate()
    const currentPlayer = usePlayerStore(state => state.player)
    const [result, setResult] = useState(null);
    const [sortedQuery, setSortedQuery] = useState('by-lands')
    const [activeButton, setActiveButton] = useState('by-lands');
    const [pages, setPages] = useState(1)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await backendRepository.getAllPlayers(sortedQuery, pages);
                setResult(result);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [currentPlayer, pages, sortedQuery]);



    const handleQuery = (type) => {
        setSortedQuery(type)
        setActiveButton(type);
    }

    const handleLink = (player) => {
        navigate(`/players/${player.id}?from-ranking`);
    };

    if (result === null || currentPlayer === null) {
        return <Skeleton animation="wave" height={600} />
    }

    return (
        <>
            <Pagination
                currentPage={result.currentPage}
                totalPages={result.totalPages}
                onPageSelection={setPages}
            />

            <TableContainer sx={{ backgroundColor: "rgb(22, 22, 34)", marginTop: '20px', marginBottom: '20px' }} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    variant="disabled"
                                >Rang</Button>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleQuery('by-nickname')}
                                    variant={activeButton === 'by-nickname' ? 'contained' : 'outlined'}
                                >Seigneur</Button>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleQuery('by-lands')}
                                    variant={activeButton === 'by-lands' ? 'contained' : 'outlined'}
                                >Lands</Button>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleQuery('by-lands-gained')}
                                    variant={activeButton === 'by-lands-gained' ? 'contained' : 'outlined'}
                                >Lands conquises</Button>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleQuery('by-attack')}
                                    variant={activeButton === 'by-attack' ? 'contained' : 'outlined'}
                                >Ratio off</Button>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleQuery('by-defense')}
                                    variant={activeButton === 'by-defense' ? 'contained' : 'outlined'}
                                >Ratio def</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.players.map((player) => (
                            <TableRow key={player.id} onClick={() => handleLink(player)}
                                sx={{
                                    textDecoration: 'none',
                                    ...(currentPlayer && currentPlayer.id === player.id && { backgroundColor: 'rgb(59, 59, 78)' }),
                                }}>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    {player.position}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                        <PlayerName player={player} />
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{player.totalLands}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{player.totalFreeLandsGained}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{player.attackRatio} {player.attackRatio === null ? "" : "%"}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{player.defenseRatio} {player.defenseRatio === null ? "" : "%"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                currentPage={result.currentPage}
                totalPages={result.totalPages}
                onPageSelection={setPages}
            />
        </>
    );
}

export default RankingArray;
