import { React, useEffect } from "react";
import usePlayerStore from "../../store/use-player-store";
import ContentPane from "../../layout/content-pane/content-pane";
import CitizenTile from "./citizen-tile";

function CitizenList({ title }) {
    const fetchOrders = usePlayerStore(state => state.fetchOrders)
    const playerResourceStocks = usePlayerStore(state => state.resourceStocks)
    const productions = usePlayerStore(state => state.productions)
    const orders = usePlayerStore(state => state.orders)

    useEffect(() => {
        fetchOrders()
    }, [fetchOrders])


    const citizenArray = playerResourceStocks.filter((playerResourceStock) => playerResourceStock.resource.isCitizen === true);


    return (
        <>
            <ContentPane title={title}>
                <div className="tiles-container">
                    {citizenArray.map((citizen, index) => {

                        const citizenOrder = orders.upgradeVillagerOrders
                            ? orders.upgradeVillagerOrders
                                .filter((citizenOrder) => citizenOrder.resourceId === citizen.resource.id)
                                .reduce((total, order) => total + order.quantity, 0)
                            : 0;
                        return (
                            <CitizenTile
                                key={index}
                                citizen={citizen}
                                productions={productions}
                                upgradeVillagerOrders={citizenOrder}
                            />
                        )
                    })}
                </div>
            </ContentPane>
        </>
    )
}

export default CitizenList