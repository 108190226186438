class KeyPress {
    _keys = {}

    _events = []

    _keyUpListener = e => this.up(e)
    _keyDownListener = e => this.down(e)

    /**
     * Keydown event handling
     * @param event
     */
    down(event) {
        if (! event.key) {
            return
        }
        if (this._keys[event.key.toLowerCase()]) {
            return
        }
        this._keys[event.key.toLowerCase()] = true
        this._executeEnterEvents()
    }

    /**
     * Keyup event handling
     * @param event
     */
    up(event) {
        if (! event.key) {
            return
        }
        this._executeExitEvents()
        this._keys[event.key.toLowerCase()] = false
    }

    _executeEnterEvents() {
        if (this._isFormFocused()) {
            return
        }
        for (const {keys, enter} of this._events) {
            if (! enter) {
                continue
            }

            let valid = true
            for (const key of keys) {
                if (!this._keys.hasOwnProperty(key) || !this._keys[key]) {
                    valid = false
                    break
                }
            }

            if (! valid) {
                continue
            }

            enter()
        }
    }

    _executeExitEvents() {
        if (this._isFormFocused()) {
            return
        }
        for (const {keys, exit} of this._events) {
            if (! exit) {
                continue
            }

            let valid = true
            for (const key of keys) {
                if (!this._keys.hasOwnProperty(key) || !this._keys[key]) {
                    valid = false
                    break
                }
            }

            if (! valid) {
                continue
            }

            exit()
        }
    }

    addEventListener(keys, enter, exit = null) {
        this._events.push({
            keys,
            enter,
            exit
        })
    }

    registerWindowListener() {
        window.addEventListener('keydown', this._keyDownListener)
        window.addEventListener('keyup', this._keyUpListener)
    }

    unregisterWindowListener() {
        window.removeEventListener("keydown", this._keyDownListener)
        window.removeEventListener("keyup", this._keyUpListener)
    }

    _isFormFocused() {
        const activeElement = document.activeElement.tagName.toLowerCase();
        const activeClasses = document.activeElement.className.toLowerCase();

        const classElements = [
            'ql-editor'
        ]

        let invalidClass = false
        for (const classElement of classElements) {
            if (activeClasses.includes(classElement)) {
                invalidClass = true
            }
        }

        const formElements = [
            'input',
            'textarea',
            'select',
        ];

        return formElements.indexOf( activeElement ) > -1 || invalidClass;
    }
}

const keypress = new KeyPress()

export default keypress