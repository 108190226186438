import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './login-form.css'


import { Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import usePlayerStore from "../../store/use-player-store";
import backendRepository from "../../repository/backend-repository";
import { useCookies } from "react-cookie";
import moment from "moment";

function LoginForm() {
    const setToken = usePlayerStore(state => state.setToken)
    const setRefreshToken = usePlayerStore(state => state.setRefreshToken)
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const token = usePlayerStore(state => state.token)
    const [, setCookie] = useCookies(['Refresh-Token']);
    const [stayConnected, setStayConnected] = useState(true)


    const navigate = useNavigate();

    // Local state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formSent, setFormSent] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormSent(true)

        //use the repository loginPlayer
        backendRepository.login({ email, password })
            .then((data) => {
                setToken(data.token)
                setRefreshToken(data.refreshToken)
                fetchPlayer()

                // Create a cookie for 1 year

                setCookie("Refresh-Token", data.refreshToken, {
                    path: "/",
                    expires: stayConnected ? moment().add(1, "y").subtract(1, "d").toDate() : null
                })

                toast.success('Connexion effectuée', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });

                setTimeout(() => {
                    navigate(`/dashboard`);
                }, 2000);
            })
            .catch((error) => {
                setFormSent(false)
                toast.error('Problème lors de la connexion', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                console.error(error);
            });
    };

    // Avoid access to login when already connected
    useEffect(() => {
        if (null !== token) {
            navigate("/dashboard")
        }
    }, [token, navigate]);

    return (
        <form className='form-container' onSubmit={handleSubmit}>
            <Stack spacing={2} className="login-form">
                <TextField
                    label="Email"
                    type="email"
                    value={email}
                    disabled={formSent}
                    variant={"filled"}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    disabled={formSent}
                    variant="filled"
                    onChange={(event) => setPassword(event.target.value)}
                />
                <FormControlLabel control={<Checkbox checked={stayConnected} onChange={(e, v) => setStayConnected(v)} />} label={"Rester connecté"} />
            </Stack>
            <div className={"mt-10"}>
                <Button variant="contained" disabled={formSent} type="submit">Connexion</Button>
            </div>
        </form>

    );
}

export default LoginForm;
