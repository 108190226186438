import { Link } from "react-router-dom";
import BaseNotification from "./base-notification";
import UndoIcon from '@mui/icons-material/Undo';
import { BsStars, BsFillChatDotsFill } from "react-icons/bs"
import { GiBroadsword } from "react-icons/gi"
import { TbMeat, TbMeatOff } from "react-icons/tb"
import { Alert, Button } from "@mui/material";
import useUiStore from "../../store/use-ui-store";
import { FurrySpellNotification } from "../spells/furry-spell";
import { FireBallSpellNotification } from "../spells/fire-ball-spell";
import { VoraciousSwarmSpellNotification } from "../spells/voracious-swarm-spell";
import { FatalNecrosisSpellNotification } from "../spells/fatal-necrosis-spell"
import { PhoenixTailSpellNotification } from "../spells/phoenix-tail";
import { MagicShieldSpellNotification } from "../spells/magic-shield";
import { MagicHammerNotification } from "../spells/magic-hammer";
import { BacchusFavorNotification } from "../spells/bacchus-favor";
import { SwiftRepatriationNotification } from "../spells/swift-repatriation";
import { MeteoriteFallsSpellNotification } from '../spells/meteorite-fall-spell'
import { RampartSpellNotification } from '../spells/rampart-spell'
import { thDelimiter } from "../../services/utils";
import { DedaleMapNotification } from "../spells/dedale-map";

export function ConquestNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        icon={props => <GiBroadsword {...props} />}
        title={"Votre royaume est attaqué !"}
        computeSeverity
    >
        <div className={"w-full"}>
            <div className={"text-center w-full"}>
                Vous avez été attaqué par {notification.body.attacker.nickname}
            </div>
            <Button onClick={handleClose} variant={"outlined"} component={Link} to={`/conquests/${notification.body.conquestId}`}>Détails</Button>
        </div>
    </BaseNotification>
}

export function SpyingNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        icon={props => <GiBroadsword {...props} />}
        title={"Vous avez subi un espionnage !"}
        computeSeverity
    >
        <div className={"w-full"}>
            <div className={"text-center w-full"}>
                Vous avez été espionné par {notification.body.attacker.nickname}
            </div>
            <Button onClick={handleClose} variant={"outlined"} component={Link} to={`/spyings/${notification.body.spyingId}`}>Détails</Button>
        </div>
    </BaseNotification>
}

export function TroopsReturnNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        icon={(props) => <UndoIcon {...props} />}
        title={"Des troupes sont rentrées de campagne"}
    >
        <div>
            <div>
                <strong>{thDelimiter(notification.body.soldiersCount)}</strong> soldats sont rentrés de la campagne contre <strong>{notification.body.defender.nickname}</strong>
            </div>
            <Button variant={"outlined"} onClick={handleClose} component={Link} to={`/conquests/${notification.body.conquestId}`}>Détails</Button>
        </div>
    </BaseNotification>
}

export function SpyReturnNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        icon={(props) => <UndoIcon {...props} />}
        title={"Des espions sont rentrés de mission"}
    >
        <div>
            <div>
                <strong>{thDelimiter(notification.body.spyCount)}</strong> espions sont rentrés de la mission contre <strong>{notification.body.defender.nickname}</strong>
            </div>
            <Button variant={"outlined"} onClick={handleClose} component={Link} to={`/spyings/${notification.body.spyingId}`}>Détails</Button>
        </div>
    </BaseNotification>
}

const starvationHelp = (handleClose) => <Alert severity={"info"}>
    <div>
        <strong>Construisez des fermes</strong> pour augmenter votre production <strong>ou démantelez des maisons</strong> afin de diminuer votre démographie et donc sa consommation de nourriture
    </div>
    <div>
        <Button onClick={handleClose} component={Link} to={"/construction"} variant={"outlined"}>Construction</Button>
    </div>
</Alert>

export function StarvationNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    const helper = useUiStore(state => state.helper)
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        title={"Votre royaume est en famine !"}
        computeSeverity
        icon={props => <TbMeatOff {...props} />}
    >
        <div>
            <strong>{thDelimiter(notification.body.citizenDiedCount)}</strong> citoyens sont morts de faim au tour précédent. <br />
            Vos sujets sont en famine, votre production ne couvre pas la consommation de vos citoyens et vos stock sont vides.
        </div>
        {helper && starvationHelp(handleClose)}
    </BaseNotification>
}

export function ShortageNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    const helper = useUiStore(state => state.helper)
    if (!notification) {
        return
    }

    return <BaseNotification
        onClick={onClick}
        notification={notification}
        title={"Votre royaume est en disette"}
        computeSeverity
        icon={props => <TbMeat {...props} />}
    >
        <div>
            Votre production de nourriture n'est plus capable de subvenir aux besoins de vos citoyens.
            Votre production de <strong>{thDelimiter(notification.body.foodProduction)} de nourriture</strong> ne peut plus nourrir les <strong>{thDelimiter(notification.body.foodConsumption)} citoyens</strong> de votre royaume. <br />
            Si vous ne faites rien votre royaume entrera en <strong>famine</strong>.
        </div>

        {helper && starvationHelp(handleClose)}
    </BaseNotification>
}

export function SpellCastNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }
    return <BaseNotification
        onClick={onClick}
        notification={notification}
        title={"Votre royaume est sous l'effet d'un sort"}
        computeSeverity
        icon={props => <BsStars {...props} />}
    >
        {notification.body.spell.type === "furry" && <FurrySpellNotification notification={notification} />}
        {notification.body.spell.type === "fire" && <FireBallSpellNotification notification={notification} />}
        {notification.body.spell.type === "voracious-swarm" && <VoraciousSwarmSpellNotification notification={notification} />}
        {notification.body.spell.type === "fatal-necrosis" && <FatalNecrosisSpellNotification notification={notification} />}
        {notification.body.spell.type === "phoenix-tail" && <PhoenixTailSpellNotification notification={notification} />}
        {notification.body.spell.type === "magic-shield" && <MagicShieldSpellNotification notification={notification} />}
        {notification.body.spell.type === "magic-hammer" && <MagicHammerNotification notification={notification} />}
        {notification.body.spell.type === "bacchus-favor" && <BacchusFavorNotification notification={notification} />}
        {notification.body.spell.type === "swift-repatriation" && <SwiftRepatriationNotification notification={notification} />}
        {notification.body.spell.type === "meteorite-fall" && <MeteoriteFallsSpellNotification notification={notification} />}
        {notification.body.spell.type === "rampart" && <RampartSpellNotification notification={notification} />}
        {notification.body.spell.type === "dedale-map" && <DedaleMapNotification notification={notification} />}
    </BaseNotification>
}

export function SystemNotification({ notification, onClick = () => { }, handleClose = () => { } }) {
    if (!notification) {
        return
    }
    return <BaseNotification
        onClick={onClick}
        notification={notification}
        title={notification.body.title}
        computeSeverity
        icon={props => <BsFillChatDotsFill {...props} />}
    >
        <div dangerouslySetInnerHTML={{ __html: notification.body.message }} />
    </BaseNotification>
}
