import { useEffect, useRef, useState } from "react"
import { Link, useParams } from 'react-router-dom';
import "./conquest-recap.scss"
import ContentPane from "../../layout/content-pane/content-pane";
import PageTitle from "../../layout/page-title/page-title";
import ContentBox from "../../layout/content-box/content-box";
import backendRepository from "../../repository/backend-repository";
import { Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import moment from "moment";
import usePlayerStore from "../../store/use-player-store";
import ConquestVersus from "../../components/conquest-versus/conquest-versus";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AttackerDetails from "./attacker-details";
import DefenderDetails from "./defender-details";
import PlayerName from "../../layout/player-name";
import { thDelimiter } from "../../services/utils";
import ConquestForChip from "../../components/conquest-for-chip/conquest-for-chip";

function ConquestRecap() {
    const player = usePlayerStore(state => state.player)
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const [conquest, setConquest] = useState(null)
    const conquestId = useParams().id
    const [isWin, setIsWin] = useState(false)
    const isTroopReturn = useRef(false)

    // Preload player
    useEffect(() => {
        fetchPlayer()
    }, [fetchPlayer])

    // When player loaded fetch the conquest
    useEffect(() => {
        if (!player || !conquestId) {
            return
        }

        async function fetchData() {
            try {
                //call to the repository
                const c = await backendRepository.getConquest(conquestId);

                setIsWin((c.attacker.id === player.id && c.isWin)
                    || (c.defender.id === player.id && !c.isWin))
                isTroopReturn.current = (new Date(c.createdAt).getTime() + (30 * 60 * 1000)) > Date.now()

                setConquest(c)
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [player, conquestId]);

    const renderRoleplay = () => {
        return { __html: conquest.roleplay };
    };

    // Todo : Plus de container width:100% dans une div c'est inefficace
    if (!conquest || !player) {
        return <Skeleton animation="wave" height={800} />
    }
    return <div className={"mt-4"}>
        <Button variant={"outlined"} component={Link} to={"/conquests/me"}>Retour</Button>
        <PageTitle>Recapitulatif de votre conquete</PageTitle>
        {isTroopReturn.current &&
            <ContentPane>
                <p>
                    La conquête est terminée et votre adversaire a été averti. Regardez le rapport de vos troupes ci-dessous.
                    Vos soldats encore en vie mettront 30 minutes pour revenir dans votre royaume.
                </p>
            </ContentPane>}


        <div className="action-container">
            <div className="action-card action-card-single">
                <div className={`banner banner-single 
                ${conquest.isWin && conquest.attackerId === player.id ? 'banner-victory' :
                        conquest.isWin && conquest.defenderId === player.id ? 'banner-defeat' :
                            !conquest.isWin && conquest.attackerId === player.id ? 'banner-defeat' :
                                !conquest.isWin && conquest.defenderId === player.id ? 'banner-victory' : ''}`}>
                    <div className="action-title">
                        {isWin ?
                            <h2 className="conquest-victory">VICTOIRE</h2>
                            :
                            <h2 className="conquest-defeat">DEFAITE</h2>}
                    </div>
                </div>
                <ContentPane>
                    <ConquestVersus attacker={conquest.attacker} defender={conquest.defender} />

                    <TableContainer>
                        <h2 className={"mb-8"}>Détail de la conquete</h2>
                        <Table size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Date de l'attaque</TableCell>
                                    <TableCell colSpan={3}>{moment(conquest.createdAt).format("YYYY/MM/DD HH:mm:SS")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attaquant</TableCell>
                                    <TableCell><strong><PlayerName player={conquest.attacker} /></strong></TableCell>
                                    <TableCell>Défenseur</TableCell>
                                    <TableCell><strong><PlayerName player={conquest.defender} /></strong></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attaque</TableCell>
                                    <TableCell>{thDelimiter(conquest.attackValue)}</TableCell>
                                    <TableCell>Défense</TableCell>
                                    <TableCell>{conquest.defenseValue ? thDelimiter(conquest.defenseValue) : <VisibilityOffIcon sx={{ height: "0.8em" }} />}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bonus offensif</TableCell>
                                    <TableCell>{((conquest.attackBonus - 1) * 100).toFixed(1)}%</TableCell>
                                    <TableCell>Bonus défensif</TableCell>
                                    <TableCell>{conquest.defenseBonus ? `${((conquest.defenseBonus - 1) * 100).toFixed(1)}%` : <VisibilityOffIcon sx={{ height: "0.8em" }} />}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Soldats perdus</TableCell>
                                    <TableCell>{thDelimiter(conquest.attackerLostSoldiers)}</TableCell>
                                    <TableCell>Soldats perdus</TableCell>
                                    <TableCell>{thDelimiter(conquest.defenderLostSoldiers)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Conquete pour</TableCell>
                                    <TableCell colSpan={3}><ConquestForChip conquestType={conquest.conquestType} detailed /> </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Terres gagnées</TableCell>
                                    <TableCell colSpan={3}>{thDelimiter(conquest.freeLandGained)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>% des terres perdus</TableCell>
                                    <TableCell colSpan={3}>{(conquest.lostFreeLandRatio * 100).toFixed(2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {[conquest.attackerId, conquest.defenderId].includes(player.id) && conquest.attackerMeta.troops && <AttackerDetails meta={conquest.attackerMeta} attacker={conquest.attacker} />}
                    {conquest.defenderId === player.id && conquest.defenderMeta.troops && <DefenderDetails meta={conquest.defenderMeta} defender={conquest.defender} />}
                </ContentPane>
                <ContentBox header="Raison de l'attaque">
                    <div dangerouslySetInnerHTML={renderRoleplay()} />
                </ContentBox>
            </div>
        </div>
    </div>
}

export default ConquestRecap