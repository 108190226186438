import React from 'react';
import { Button, ButtonGroup, TextField } from '@mui/material';

function UpgradeCitizenInput({ citizen, quantity, handleQuantityChange, handleMax, handleReset, handleSubmit, handleDisabled, displaySacrify, toggleDisplaySacrify }) {
    return (
        <>
            <label htmlFor={`quantityInput${citizen.resource.id}`}>Quantité:</label>

            <div className='action-input-container'>
                <TextField
                    className='action-input'
                    id={`quantityInput${citizen.resource.id}`}
                    type="number"
                    //if value is not defined, value === "" (serve to controlled component)
                    value={isNaN(quantity) ? '' : quantity}
                    onChange={(event) => handleQuantityChange(event)} />

                <ButtonGroup variant={"contained"}>
                    <Button onClick={handleMax}>
                        Max
                    </Button>
                    <Button onClick={handleReset}>
                        Reset
                    </Button>
                    <Button
                        color={"secondary"}
                        onClick={handleSubmit}
                        disabled={handleDisabled()}
                    >
                        Former
                    </Button>
                </ButtonGroup>
            </div>
            <div className={"mt-8"}>
                <Button color={"error"} variant={displaySacrify ? "contained" : "outlined"} onClick={toggleDisplaySacrify}>Sacrifice</Button>
            </div>
        </>
    );
}

export default UpgradeCitizenInput;
