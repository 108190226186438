import ContentBox from '../../layout/content-box/content-box'


function Alliance() {
    return <>
    <ContentBox header='Alliance'>
        Bientot disponible
    </ContentBox>
    </>
}

export default Alliance