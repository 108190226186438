import React, { useState } from "react";
import { Button, Dialog, Divider, Table, TableCell, TableContainer, TableHead, TableRow, Alert, AlertTitle } from '@mui/material';

import CitizenCost from "./citizen-cost";
import ArmyAction from "../army-action/army-action";
import { thDelimiter } from "../../services/utils";

function FaceDetailed({ onClick, citizen, productions, upgradeVillagerOrders }) {

    const villagerProductions = productions.find((production) => production.resource.type === "villager");

    const [dialogOpen, setDialogOpen] = useState(false);
    const handleOnClick = () => {
        if (!dialogOpen) {
            onClick()
        }
    }

    const openDialog = (event) => {
        event.stopPropagation();
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);

    };


    return (
        <div key={citizen.id} onClick={handleOnClick} className="tile tile-detailed">
            <div className="tile-header tile-detailed-header">
                <img
                    src={require(`../../assets/img/icon/${citizen.resource.type}.webp`)}
                    className="ressource-icon"
                    alt="resoure-icon"
                    rel="preload"
                    as="image"
                    style={{ borderTopLeftRadius: "5px" }}
                />
                <h3>{citizen.resource.name}</h3>
                {citizen.resource.type === "villager" ?
                    <Button variant="contained" onClick={openDialog}>
                        Examiner
                    </Button>
                    :
                    <Button variant="contained" onClick={openDialog}>
                        Former
                    </Button>
                }
            </div>
            <Divider />
            <div className="tile-body">

                <Alert severity="success" icon={false}>
                    <AlertTitle>{citizen.resource.name}</AlertTitle>
                    <p>{citizen.resource.description}</p>
                </Alert>
                <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ lineHeight: "100px" }}>
                                <TableCell>{citizen.resource.name} actuels </TableCell>
                                <TableCell>{thDelimiter(citizen.quantity)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ lineHeight: "100px" }}>
                                <TableCell>{citizen.resource.name} maximum </TableCell>
                                <TableCell>{thDelimiter(citizen.maxResourcesAllowed)}</TableCell>
                            </TableRow>
                            {citizen.resource.type !== "villager" ? (
                                <TableRow>
                                    <TableCell>{citizen.resource.name} en formation</TableCell>
                                    <TableCell>{thDelimiter(upgradeVillagerOrders)}</TableCell>
                                </TableRow>
                            ) :
                                <>
                                    <TableRow>
                                        <TableCell>Naissances au prochain cycle</TableCell>
                                        <TableCell>{thDelimiter(villagerProductions.productionOnNextCycle)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total de villageois au prochain cycle</TableCell>
                                        <TableCell>{thDelimiter(villagerProductions.stockQuantityOnNextCycle)}</TableCell>
                                    </TableRow>
                                </>
                            }
                        </TableHead>
                    </Table>
                </TableContainer>

                {citizen.resource.type !== "villager" && <CitizenCost citizen={citizen} />}


                <Dialog open={dialogOpen} onClose={closeDialog}>
                    <ArmyAction filter={citizen.resource.id} />
                </Dialog>

            </div>
        </div>
    );
}

export default FaceDetailed