import ManaPane from '../../components/mana-pane/mana-pane';
import PageTitle from '../../layout/page-title/page-title';
import HelperPane from '../../layout/helper-pane/helper-pane';
import ContentPane from "../../layout/content-pane/content-pane";
import SpellCastStepper from "../../components/spell-cast-stepper/spell-cast-stepper";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function SpellCast() {

  return <>
    <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/active-effect"} variant={"outlined"}>Sorts actifs</Button>
    <Button component={Link} sx={{ marginRight: '5px', marginBottom: "5px" }} to={"/spells"} variant={"outlined"}>Mes sorts</Button>
    <PageTitle>Lancer un sort</PageTitle>
    <HelperPane linkTo={"magic"}>
      <p>
        Vous voici sur la page de lancement de sorts. Ici, vous pouvez sélectionner une cible (vous y compris), choisir le
        sort que vous désirez lancer sur la cible, puis confirmer le lancement du sort.
      </p>
      <p>Pour lancer un sort, vous devez remplir les conditions suivantes détaillées dans les <Button component={Link} to="/rules/#magic">Règles du jeu</Button>.</p>
      <p>Une fois le sort lancé, vous aurez accès à un récapitulatif dans une page dédiée.</p>
    </HelperPane>
    <ManaPane />
    <ContentPane>
      <SpellCastStepper />
    </ContentPane>
  </>
}

export default SpellCast;
