import ContentPane from "../../layout/content-pane/content-pane";
import CollapseBox from "../../layout/collapse-box/collapse-box";
import PageTitle from "../../layout/page-title/page-title";
import { Alert, AlertTitle, Link } from "@mui/material";

import KebabDiningIcon from '@mui/icons-material/KebabDining';
import ForestIcon from '@mui/icons-material/Forest';
import GrainIcon from '@mui/icons-material/Grain';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import HomeIcon from '@mui/icons-material/Home';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import CabinIcon from '@mui/icons-material/Cabin';
import FoundationIcon from '@mui/icons-material/Foundation';
import ShieldIcon from '@mui/icons-material/Shield';
import FortIcon from '@mui/icons-material/Fort';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IoIosTimer } from 'react-icons/io'
import { FaHatWizard } from 'react-icons/fa'
import { GiPotionBall } from 'react-icons/gi'
import { BsFillLightningChargeFill } from 'react-icons/bs'
import { BsHourglassSplit } from 'react-icons/bs'
import "../../index.scss"
import "../rules-page/rules-page.scss"

import Summary from "./summary"
import SpellEfficiencyChip from "../../components/spell-efficiency-chip/spell-efficiency-chip";
import game from "../../assets/game/game";
import Chip from "../../layout/chip/chip";

function RulesPage() {

    return (
        <>
            <PageTitle>Règles du jeu</PageTitle>
            <Summary />
            <ContentPane title="Introduction">
                <div>
                    <p>Bienvenue dans les règles du jeu ! </p>
                    <p>
                        Cette page contient toutes les informations essentielles pour comprendre et maîtriser les règles de Conquest.
                        Vous y trouverez des directives claires sur la manière de jouer, de progresser et d'interagir avec les autres joueurs.</p>
                    <p>
                        Une bonne connaissance des règles permet d'apprécier pleinement l'expérience. Veuillez les lires attentivement avant de commencer.
                        Posez des questions en cas de besoin. Profitez de votre expérience de jeu et amusez-vous !</p>

                    <p className="text-right">L'équipe du jeu</p>
                </div>
            </ContentPane>

            <ContentPane title="Conquest, qu'est-ce que c'est ?">
                <div>
                    <p>Conquest est un jeu de stratégie par navigateur.</p>
                    <p>Devenez un seigneur dans un monde guerrier où la loi du plus fort règne. Gérez et agrandissez votre royaume en explorant, construisant et attaquant les autres seigneurs.</p>
                    <p>Explorez des terres inconnues, construisez des bâtiments et gérez vos ressources pour faire prospérer votre empire avant de conquérir les royaumes voisins.</p>
                </div>
            </ContentPane>


            <ContentPane title="Le système de cycles" id="cycles">
                <div>
                    <p>Conquest fonctionne avec un système de cycles. Chaque heure, un nouveau cycle commence.
                        Vos actions effectuées lors du tour précédent seront traitées et mises à jour, y compris vos explorations,
                        constructions et stock de ressources.</p>
                    <Alert severity="info">Le compteur en haut à droite du site représente le temps restant avant le prochain cycle.</Alert>
                    <p>Surveillez attentivement votre production, car les valeurs de chaque ressource indiqueront la quantité ajoutée ou perdue pour le prochain tour.</p>
                </div>
            </ContentPane>

            <ContentPane title="L'exploration" id="exploration">
                <div>
                    <p>Aucun bâtiment ne peut être construit sans avoir préalablement débloqué des terrains vides.
                        Vous pouvez récupérer des terrains vides par la conquête ou l'exploration.</p>
                    <p>Pour explorer des terres sauvages, vous aurez besoin de nourriture et de villageois.
                        Une fois l'exploration lancée, vous devrez attendre la fin du cycle en cours pour obtenir vos terrains vides.</p>
                    <p>Vous récupérerez un certain pourcentage des villageois envoyés en exploration, mais la nourriture utilisée sera perdue.</p>
                </div>
            </ContentPane>

            <ContentPane title="Les ressources" id="resources">
                <div>
                    <p>La plupart de vos bâtiments produiront des ressources à chaque cycle.</p>
                    <p>Les ressources sont essentielles, car elles définissent la richesse de votre royaume.
                        Elles vous permettent de construire, d'explorer et d'améliorer vos citoyens.
                        Toutes les actions entreprises par votre seigneur consommeront des ressources.</p>
                    <p>En règle générale, vos ressources seront produites à chaque cycle par vos constructions.
                        Les seules ressources qui ne sont pas produites à chaque cycle sont les citoyens améliorés.
                        Vous devrez vous rendre sur la page de l'armée pour améliorer vos villageois en échange de ressources.</p>

                    <CollapseBox className='w-full' icon={<KebabDiningIcon />} header="La nourriture">
                        <p>La nourriture est produite à chaque tour par vos fermes. Tous vos citoyens consomment 1 unité de nourriture par cycle.</p>
                        <p>Elle est également nécessaire pour l'exploration et certaines de vos constructions.</p>
                        <Alert severity="warning">Surveillez attentivement votre production de nourriture sinon vous risquez de
                            passer en famine. Voir le chapitre sur <Link href="#starvation">la famine</Link>.</Alert>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<ForestIcon />} header="Le bois">
                        <p>Le bois est la ressource utilisée par quasiment toutes vos constructions.</p>
                        <p>C'est une ressource indispensable qui sera nécessaire dans beaucoup de situations.</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<GrainIcon />} header="Les différents métaux">
                        <p>Il existe 5 types de métaux. Voici la liste du plus commun au plus rare :</p>
                        <ul className="list-type-none">
                            <li>Le fer</li>
                            <li>Le cuivre</li>
                            <li>L'argent</li>
                            <li>L'or</li>
                            <li>Les gemmes</li>
                        </ul>

                        <Alert severity="info">
                            <p>Chaque métal est produit par le type de mine qui lui est associé. Plus un métal est rare, moins vos mines pourront en extraire par cycle.</p>
                        </Alert>
                        <p>Les métaux servent pour les constructions et l'amélioration de citoyens. Il est important de posséder des types de métaux différents pour profiter pleinement des possibilités offertes par le système de jeu.</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<ShieldIcon />} header="La défense">
                        <p>La défense est une ressource produite par les fortifications. Elle augmentera passivement votre défense en cas d'invasion ennemie de la même manière que les soldats.</p>
                        <p>Le principal avantage de la défense offerte par vos fortifications est qu'elle n'est pas comptabilisée dans les rapports d'espionnage, ce qui en fait un critère déterminant pour brouiller les pistes vis-à-vis des effectifs défensifs de votre royaume.</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<WbSunnyIcon />} header="La bénédiction">
                        <p>Disponible prochainement...</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<WhatshotIcon />} header="Le mana">
                        <p>Disponible prochainement...</p>
                    </CollapseBox>
                    <Alert severity="info">
                        <AlertTitle>Les citoyens :</AlertTitle>
                        <p>Les citoyens sont une ressource particulière de votre royaume. Veuillez vous référer au chapitre sur <Link href="#citizen">les citoyens.</Link></p>
                    </Alert>
                </div>
            </ContentPane>



            <ContentPane title="Les constructions" id="constructions">
                <div>
                    <p>Une grande partie de vos constructions sert à produire des ressources à chaque cycle.
                        Chaque construction produit la ressource qui lui est associée.
                        Il est important de noter que chaque bâtiment possède une capacité maximale de stockage pour sa ressource.</p>
                    <Alert severity="info">
                        <AlertTitle>Exemple :</AlertTitle>
                        <p>Supposons que j'ai 10 maisons. Chaque maison produit 5 villageois par cycle et peut contenir jusqu'à 50 villageois.</p>
                        <p>Mes maisons produiront donc 50 villageois à la fin du cycle et je peux posséder jusqu'à 500 villageois au total.</p>
                        <p>Si j'ai déjà 470 villageois lors du cycle en cours, je ne peux pas dépasser mon stock de 500 villageois.
                            Je ne récupérerai donc que 30 villageois. <strong>470 + 50 = 520</strong>, et 520 est supérieur à 500.</p>
                    </Alert>
                    <p>Pour réaliser une construction, vous aurez besoin de terres vides et des ressources nécessaires pour chaque bâtiment.
                        Une bonne répartition de vos constructions vous assurera une production homogène de vos ressources à chaque cycle.</p>
                    <CollapseBox className='w-full' icon={<HomeIcon />} header="Maison">
                        <p>Les maisons sont des batiments indispensables à votre royaume car elles produisent vos villageois.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<FoodBankIcon />} header="Ferme">
                        <p>Les fermes produiront de la nourriture à chaque cycle.</p>
                        <p>Une bonne quantité de fermes vous assurera des vivres en abondance pour nourrir vos citoyens.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<CabinIcon />} header="Scierie">
                        <p>Les scieries produisent du bois, utilisé pour la quasi-totalité des bâtiments constructibles.</p>
                        <p>Les scieries coûtent un prix raisonnable et vous fourniront un stock de bois important, ne les négligez pas.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<FoundationIcon />} header="Les mines">
                        <p>Il existe differentes mines qui produisent un metal qui leur est propre. Voici la liste des mines:
                        </p>
                        <ul className="list-type-none">
                            <li>Mine de fer</li>
                            <li>Mine de cuivre</li>
                            <li>Mine d'argent</li>
                            <li>Mine d'or</li>
                            <li>Mine de gemme</li>
                        </ul>
                        <Alert severity="info">
                            <p>Vous pourrez construire des mines de cuivre avec du fer, des mines d'argent avec du cuivre,
                                des mines d'or avec de l'argent et des mines de gemme avec de l'or.
                            </p>
                            <p>Les minerais produits n'ont pas tous la même rareté.</p>
                        </Alert>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<ShieldIcon />} header="Fortification">
                        <p>La fortification permet d'ajouter une valeur defensive à votre royaume. Pour en construire, il vous faudra du bois et des soldats.</p>
                        <p>Ce bâtiment possède plusieurs avantages :</p>
                        <ul className="list-type-none">
                            <li>C'est un moyen de rajouter de la defense qui ne consomme pas de nourriture, contrairement à vos citoyens.</li>
                            <li>La valeur defensive rajoutée par vos fortifications n'est pas visible par les espions ennemis.</li>
                        </ul>
                        <Alert severity="warning" title="ATTENTION">La defense de vos fortifications n'augmente pas à chaque cycle.
                            Une fois construite, elle rajoutera un bonus de defense fixe. Pour l'augmenter vous devrez construire
                            des nouvelles fortifications
                        </Alert>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<FortIcon />} header="Garnison">
                        <p>La garnison est un bâtiment utilisé pour agrandir le stock maximum de vos soldats.
                            Vous ne pourrez pas avoir d'armée sans garnison.
                        </p>
                        <Alert severity="info">Chaque garnison augmente votre stock maximum de 10 soldats.
                            Si vous possedez 10 garnisons, vous pourrez avoir 100 soldats au maximum.</Alert>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<HistoryEduIcon />} header="Ecoles de magie/d'espionnage">
                        <p>Les différentes écoles vous permettront d'augmenter votre capacité maximum d'espions ou de mage disponibles.</p>
                        <Alert severity="info">Chaque ecole augmente votre stock maximum de 10 citoyens qui lui sont associés.
                            Si vous possedez 10 ecole de mage, vous pourrez avoir 100 mages au maximum.</Alert>
                        <p>Les écoles de magie permettent également de débloquer des sorts dans la section <Link href="#magic">magie</Link>.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<AccountBalanceIcon />} header="Temple">
                        <p>Disponible prochainement...</p>
                    </CollapseBox>
                </div>
            </ContentPane>



            <ContentPane title="Les citoyens" id="citizen">
                <div>
                    <p>Les citoyens sont une ressource particulière dans votre royaume. En construisant des maisons, vous produirez des villageois à
                        chaque tour en fonction de votre stock maximal de villageois. Un villageois peut être amélioré manuellement contre des ressources dans la page dédiée.</p>
                    <Alert severity="info">
                        <p>Tous les citoyens consomment 1 unité de nourriture par cycle.</p>
                        <p>Si vous possédez 10 villageois et 10 soldats, ils consommeront chacun 1 unité de nourriture. Votre stock de nourriture perdra donc 20 unités.</p>
                    </Alert>

                    <CollapseBox className='w-full' icon={<PersonIcon />} header="Les villageois">
                        <p>Les villageois sont une ressource utilisée pour différentes actions dans le jeu.</p>
                        <p>Ils peuvent explorer, servir à la construction de certains bâtiments et peuvent être améliorés.</p>
                        <p>Ce sont les seuls citoyens qui sont créés automatiquement. Leur taux de natalité dépend de votre nombre de maisons.</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<MilitaryTechIcon />} id="army" header="L'armée">
                        <p>L'armée est une ressource pour la défense et l'attaque de votre royaume.</p>
                        <p>Vous pouvez envoyer une partie de vos soldats attaquer d'autres seigneurs.
                            Les soldats présents dans votre royaume seront automatiquement comptés dans votre défense en cas d'attaque.</p>
                        <Alert severity="info">
                            <p>La capacité maximale de soldats que vous pouvez posséder est déterminée par votre quantité de <strong>garnisons</strong>.</p>
                        </Alert>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<AutoFixHighIcon />} header="Les mages">
                        <p>Les mages peuvent utiliser des pouvoirs magiques et stratégiques dans la page dédiée.
                            La quantité de mage que vous utilisez déterminera les chances de réussite du sort ainsi que son efficacité.
                            Chaque sort consomme du mana qui se recharge partiellement à chaque cycle.</p>
                        <p>La capacité maximale de mages que vous pouvez posséder est déterminée par votre quantité d'<strong>école de magie</strong>.</p>
                    </CollapseBox>

                    <CollapseBox className='w-full' icon={<VisibilityIcon />} header="Les espions">
                        <p>Les espions sont des unités utilisées pour l'espionnage et la collecte de renseignements.</p>
                        <p>La capacité maximale d'espions que vous pouvez posséder est déterminée par votre quantité d'<strong>école d'espions</strong>.</p>
                    </CollapseBox>

                    <Alert id="starvation" severity="warning">
                        <AlertTitle>La famine :</AlertTitle>
                        <p>Surveillez votre production de nourriture et votre quantité totale de citoyens.
                            N'oubliez pas que chaque citoyen consommera 1 unité de nourriture par cycle.</p>
                        <p>Si vous possédez moins de nourriture que de citoyens, ils deviendront affamés, mourront de faim et seront perdus pour le prochain tour.</p>
                        <p>Vos citoyens sont supprimés par ordre croissant :</p>
                        <ul className="list-type-none">
                            <li>Villageois</li>
                            <li>Soldats</li>
                            <li>Espions</li>
                            <li>Mages</li>
                        </ul>
                        <p>Pour pallier à la famine, construisez des fermes ou trouvez une solution pour réduire votre nombre de citoyens.</p>
                    </Alert>
                </div>
            </ContentPane>


            <ContentPane title="La conquête" id="conquest">
                <div>
                    <p>La conquête est une mécanique essentielle. Si vous possédez une armée en nombre suffisant,
                        vous pouvez attaquer d'autres joueurs pour les déposséder de leurs terres.</p>
                    <p>Vous devez tout d'abord choisir une cible à attaquer dans le menu de conquête.</p>
                    <Alert severity="info">
                        Vous ne pouvez attaquer que les joueurs ayant un nombre de terres proche du vôtre.
                        Il n'est pas possible d'attaquer un joueur ayant beaucoup moins de terres que vous.
                    </Alert>
                    <p>Une fois votre cible choisie, vous devez déterminer une quantité de soldats à mobiliser sur le front.
                        Vos soldats vont affronter l'armée adverse et vous et votre adversaire recevrez instantanément le résultat de la bataille.</p>

                    <p>La règle de résolution de conquête est simple :</p>
                    <ul className="list-type-none">
                        <li><strong>Victoire :</strong> Vous envoyez plus de troupes que l'adversaire, alors vous remportez une partie de ses terres.</li>
                        <li><strong>Défaite :</strong> Vous envoyez moins de troupes que l'adversaire, alors vous ne remportez aucune terre.</li>
                    </ul>
                    <p>Une fois la conquête résolue, le défenseur et l'attaquant perdront proportionnellement des citoyens en fonction de la résistance adverse,
                        puis les troupes de l'attaquant mettront 30 minutes pour retourner dans leur royaume.
                        Il faut donc faire attention à ne pas laisser votre cité sans défense au risque de devenir une cible facile.</p>
                    <Alert severity="info">
                        Pour avoir une estimation des effectifs de la cible, vous pouvez utiliser vos espions au préalable.
                    </Alert>
                </div>
            </ContentPane>


            <ContentPane title="La magie" id="magic">
                <div>
                    <p>
                        La magie est une mécanique de jeu permettant d'influencer positivement ou négativement votre royaume ou ceux des autres joueurs. Elle se manifeste à travers des sorts, qui sont au cœur de cette mécanique. Les sorts se divisent en deux catégories :
                    </p>
                    <CollapseBox className='w-full mt-10' icon={<BsFillLightningChargeFill />} header="Les sorts instantanés">
                        <p>Un sort instantané a un effet immédiat et est à usage unique. Une fois lancé, son effet s'applique instantanément. Par exemple :</p>
                        <Alert severity="info">"Le Joueur A lance une boule de feu sur le Joueur B. Le sort réussit et le Joueur B perd 5% de son stock de bois."</Alert>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<BsHourglassSplit />} header="Les sorts cycliques">
                        <p>Les sorts cycliques se lancent comme les sorts instantanés, mais leur effet dure un temps déterminé. Cet effet reste actif pendant toute la durée du sort. Par exemple :</p>
                        <Alert severity="info">"Le Joueur A lance le Bouclier Magique sur lui-même, augmentant ainsi la puissance défensive de son armée pendant 4 tours."</Alert>
                        <p>L'effet du sort est <strong>instantanément</strong> appliqué et est actif pendant une durée detérminée de <strong>tours complets</strong>.</p>
                    </CollapseBox>
                    <p>Pour lancer un sort, vous devez respecter certaines conditions :</p>
                    <CollapseBox className='w-full' icon={<HistoryEduIcon />} header="Les écoles de magie">
                        <p>Chaque sort doit être débloqué en ayant une certaine quantité d'écoles de magie. Plus vous avez d'écoles de magie, plus vous avez de sorts à disposition.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<FaHatWizard />} header="Les magiciens">
                        <p>Le nombre de magiciens que vous possédez influence les chances de réussite du sort ainsi que sur son efficacité.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<GiPotionBall />} header="La Mana">
                        <p>La mana est une ressource unique utilisée exclusivement pour les sorts. Chaque sort a un coût en mana que vous devez respecter pour pouvoir l'utiliser.</p>
                        <p>Tous les joueurs possèdent 100 points de mana, se régénérant automatiquement de 2 points par tour.</p>
                    </CollapseBox>
                    <CollapseBox className='w-full' icon={<IoIosTimer />} header="Le temps de recharge">
                        <p>Les sorts ont un temps de recharge. Si un sort est utilisé, il faudra attendre un certain nombre de cycles complets avant de pouvoir le relancer. Par exemple :</p>
                        <Alert severity="info">"Le Joueur A lance une Boule de Feu sur le Joueur B à 12h45. Le temps de recharge de la Boule de Feu est de 4 cycles complets. Le Joueur A pourra relancer son sort à 17h."</Alert>
                    </CollapseBox>
                    <CollapseBox id={"magic-efficiency"} className='w-full' icon={<IoIosTimer />} header="L'efficacité">
                        <p>
                            Lorsque un sort est lancé, il va etre appliqué avec un certain degré d'efficacité par rapport a son effet affiché. Les magiciens du lanceur sont comparé à une valeur de la cible.
                        </p>
                        <Alert severity="info">
                            Le joueur A lance un sort qui bloque maximum 50% de la production de bois du joueur B. L'efficacité est donné par l'élément suivant :
                            <div style={{ maxWidth: "200px", minWidth: "30px" }}>
                                <SpellEfficiencyChip wizardStock={{ quantity: 1000 }} spell={{ efficiencyHelp: { caster: { type: "wizard", weight: 1 }, target: { type: "wood", weight: 2000 } } }} />
                            </div>

                            <p>
                                En <strong>cliquant</strong> sur cet indicateur il vous affiche pour votre stock de magicien la quantité max de bois que doit avoir l'adversaire pour que l'effet soit maximal. En l'occurence pour 1 000 magiciens, la cible doit avoir 2 000 000 de bois ou moins pour que l'effet soit maximal
                            </p>

                            <p>Cela indique que pour que l'effet fonctionne totalement il faut atteindre le ratio de 1 magicien pour 2 000 de bois. Soit :</p>

                            <ul>
                                <li>Si le lanceur engage 100 magiciens pour 200 000 de bois adverse, l'efficacité est complète, alors 50% de la production va etre bloqué. Ce qui correspond au maximum de l'effet</li>
                                <li>Si le lanceur engage 50 magiciens pour 200 000 de bois adverse, l'efficacité est partielle, alors 25% du bois va etre bloqué. Ce qui correspond à 50% de l'effet</li>
                            </ul>
                        </Alert>
                    </CollapseBox>
                    <p>
                        Une fois que vous avez rempli toutes les conditions pour lancer votre sort, rendez-vous sur la page "Lancer un sort". Choisissez une cible et sélectionnez un sort. Un récapitulatif vous sera présenté et vous pourrez confirmer votre choix.
                    </p>
                    <p>Le rapport de sort fournira les détails de l'action. Vous pourrez retrouver l'historique des sorts ainsi que les sorts actifs dans la page "Effets actifs".</p>
                </div>
            </ContentPane>

            <ContentPane title="L'espionnage" id="spy">
                <div>
                    <p>L'espionnage est une mécanique utilisée pour obtenir des informations sur les ressources de l'adversaire.</p>
                    <p>Pour lancer une opération d'espionnage, vous devez posséder des espions, que vous pouvez obtenir en construisant des écoles d'espionnage.</p>
                    <ul>
                        <li>Une opération d'espionnage se déroule en plusieurs étapes :</li>
                        <ol>
                            <li>Choisissez une cible, un type d'espionnage et la quantité d'espions à envoyer.</li>
                            <li>La résolution de l'opération d'espionnage est calculée instantanément.</li>
                            <li>Vos espions encore en vie mettent 30 minutes pour revenir dans votre royaume.</li>
                        </ol>
                    </ul>
                    <p>La réussite de l'opération d'espionnage dépend du nombre d'espions envoyés par rapport au nombre d'espions de la cible, et cela déterminera le nombre de ressources indiqué dans le rapport. Plus l'opération est réussie, plus le joueur obtiendra d'informations sur les ressources de sa cible :</p>
                    <div>
                        <Chip>Mission parfaite</Chip>
                        <p>La quantité d'espions de l'attaquant est deux fois supérieure à celle de la cible. Le rapport affiche 70 % des ressources de la cible.</p>
                        <p>La cible n'est pas alertée, vos espions agissent comme des fantômes.</p>
                    </div>
                    <div>
                        <Chip>Mission réussie</Chip>
                        <p>Vous avez envoyé plus d'espions que la cible. Le rapport affiche 50 % des stocks de ressources de l'adversaire.</p>
                        <p>La cible reçoit une notification, mais votre nom reste caché.</p>
                    </div>
                    <div>
                        <Chip>Mission avortée</Chip>
                        <p>La mission a mal tourné, vous avez envoyé moins d'espions que la cible. Le rapport n'affiche que 30 % des ressources du joueur ciblé.</p>
                        <p>La cible reçoit une notification avec votre nom.</p>
                    </div>
                    <div>
                        <Chip>Mission échouée</Chip>
                        <p>La mission a été un désastre. Vous avez envoyé moins de deux fois la quantité d'espions de la cible.</p>
                        <p>La cible est alertée avec votre nom, et cette action est visible publiquement.</p>
                        <Alert severity="warning">La cible possédera un rapport détaillé sur les ressources de votre royaume !</Alert>
                    </div>
                </div>
            </ContentPane>


            <ContentPane title="L'echange entre joueurs" id="gift">
                <div>
                    <p>Disponible prochainement...</p>
                </div>
            </ContentPane>

            <ContentPane title="Le marché" id="market">
                <div>
                    <p>Le marché permet aux joueurs d'échanger leur resources avec "le jeu". Ce n'est pas des échanges entre joueurs.</p>
                    <Alert severity={"info"}>
                        Les resources de types citoyens (villageois, soldats, espions, magiciens) ne peuvent pas etre échangées.
                    </Alert>
                    <p>
                        Les resources sont échangées selon un taux fixe et chaque transaction est soumise à une taxe de {game.MARKET.TAX_PERCENT()}.
                    </p>
                    <p>
                        Pour réaliser un échange :
                        <ul>
                            <li>Sélectionnez la resource que vous souhaitez échanger</li>
                            <li>Cliquez sur le bouton "ECHANGER" de la resource que vous voulez récupérer</li>
                            <li>Indiquez la quantité de resource que vous voulez échanger dans le champ textuel</li>
                            <li>Cliquez sur "ECHANGER"</li>
                        </ul>
                    </p>
                    <p>
                        Vous pouvez afficher une vue détaillée de l'échange en cliquant sur le bouton en haut à droite du menu d'échange
                    </p>
                </div>
            </ContentPane>

            <ContentPane title="Le classement" id="ranking">
                <div>
                    <p>Conquest est un jeu PVP où la comparaison avec les autres joueurs revêt une grande importance.</p>
                    <p>Vous pouvez évaluer votre position en consultant votre classement ainsi que celui de vos amis, en les triant selon plusieurs critères :</p>
                    <ul className="list-type-none">
                        <li><strong>Seigneurs :</strong>Noms des seigneurs par ordre alphabétique.</li>
                        <li><strong>Ratio offensif :</strong>Ratio d'attaques réussies le plus élevé en premier.</li>
                        <li><strong>Ratio défensif :</strong>Ratio de défenses réussies le plus élevé en premier.</li>
                        <li><strong>Terres conquises :</strong> Nombre de terres conquises à leurs adversaires.</li>
                        <li><strong>Terres totales :</strong> Nombre total de terres que les joueurs possèdent.</li>
                    </ul>
                </div>
            </ContentPane>
        </>
    )

}

export default RulesPage