import "./market.scss"
import ContentPane from "../../layout/content-pane/content-pane";
import {useEffect, useState} from "react";
import backendRepository from "../../repository/backend-repository";
import MarketResource from "./market-resource";
import {
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import ResourceIcon from "../../components/resource-icon/resource-icon";
import MarketDialog from "./market-dialog";
import game from "../../assets/game/game";
import Chip from "../../layout/chip/chip";
import HelperPane from "../../layout/helper-pane/helper-pane";
import PageTitle from "../../layout/page-title/page-title";
import Banner from "../../components/banner/banner";

function Market() {
    const [marketResources, setMarketResources] = useState([])
    const [currentResource, setCurrentResource] = useState(null)
    const [currentTargetResource, setCurrentTargetResource] = useState(null)

    useEffect(() => {
        backendRepository.getMarketResources().then(r => {
            setMarketResources(r)
        })
    }, [])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        for (const marketResource of marketResources) {
            if (marketResource.type === value) {
                setCurrentResource(marketResource);
                break;
            }
        }
    };

    const handleExchange = (resource) => {
        setCurrentTargetResource(resource)
    }

    return <>
        <PageTitle>Le marché</PageTitle>
    <HelperPane linkTo={"market"}>
        <p>
            Le marché vous permet d'échanger les ressources que vous possédez contre d'autres ressources.
        </p>
        <p>
            Ce service vous est fournis par la confrérie des marchands qui prend une commission de {game.MARKET.TAX_PERCENT()} sur chaque vente.
        </p>
    </HelperPane>

    <ContentPane title='Echanger des ressources'>
        <Banner img={require("./../../assets/img/market.jpg")}>La confrérie des marchands vous ouvrent leurs portes. Vous pouvez échanger vos ressources</Banner>
        <div className={"mt-8"}>Sélectionnez la ressource vous appartenant que vous souhaitez échanger, ensuite cliquez sur le bouton <strong>ECHANGER</strong> sur la resource que vous souhaitez acquérir</div>
        <div className={"market-resource-selector"}>
            <div>
                Ressource à échanger :
            </div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Ressource</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={currentResource ? currentResource.type : ""}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                >
                    {marketResources.map((resource) => (
                        <MenuItem key={resource.id} value={resource.type}>
                            <div className={"flex flex-row flex-items-center"}>
                                <ResourceIcon type={resource.type} className={"mr-3"} />
                                <ListItemText primary={resource.name} />
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
        <div className={"mb-8"}>
            <Chip type={"warning"} className={"strong"}>Taxes : {(game.MARKET.TAX * 100).toFixed(0)}%</Chip>
        </div>
        <Grid container spacing={2}>
            {marketResources.map(resource => <Grid key={resource.id} xs={12} sm={6} md={4} item>
                <MarketResource resource={resource} comparisonResource={currentResource} onExchange={handleExchange} />
            </Grid> )}
        </Grid>
        <MarketDialog
            sourceResource={currentResource}
            targetResource={currentTargetResource}
            onClose={() => setCurrentTargetResource(null)}
            open={currentTargetResource !== null}
        />
    </ContentPane>
  </>
}

export default Market