import {Avatar, IconButton, Menu, MenuItem} from "@mui/material";
import usePlayerStore from "../../store/use-player-store";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function UserAvatar() {
    const player = usePlayerStore(state => state.player);
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (player) {
            return
        }

        fetchPlayer();
    }, [fetchPlayer, player]);


    return (
        player ? (
            <div className="ml-5">
                <IconButton size="large" onClick={handleClick}>
                    <Avatar
                        sx={{
                            bgcolor: '#75ABBC',
                            width: 36,
                            height: 36,
                        }}>
                        {player.nickname.split('')[0].toUpperCase()}
                    </Avatar>
                </IconButton>
                <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                    <MenuItem component={Link} to={`/players/${player.id}`} onClick={handleClose}>Mon profil</MenuItem>
                    <MenuItem component={Link} to={`/update-account`} onClick={handleClose}>Paramètres</MenuItem>
                </Menu>
            </div>
        ) : null
    );
}

export default UserAvatar;
