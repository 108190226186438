import LandingWelcome from '../../components/landing-component/landing-welcome'
import '../landing-page/landing-page.scss'
import LandingComponent from '../../components/landing-component/landing-component'
import { Button } from '@mui/material'

function LandingPage() {
    return (
        <>
            <LandingWelcome />
            <LandingComponent
                title="Découvrez un nouveau monde"
                content={<>
                    <p>Rassemblez des armées de soldats valeureux, déployez des espions pour déjouer vos ennemis,
                        ou engagez-vous dans une production effrénée de ressources pour bâtir un empire prospère.</p>
                    <p>Votre destin est entre vos mains alors que vous créez votre seigneur, et façonnez l'histoire de votre royaume.
                        Lancez-vous dans l'inconnu en explorant des terres vierges, où chaque coin recèle de mystères à dévoiler et de richesses à réclamer. </p>
                    <p>Levez l'étendard de votre seigneur et partez à l'aventure dès aujourd'hui !</p>
                </>}
                backgroundClass="background-discover"
                alignment="left"
            />
            <LandingComponent
                title="Batissez votre royaume"
                content={
                    <>
                        <p>Créez et aménagez votre royaume, élevez des forteresses majestueuses et explorez les mystères de la magie pour forger votre propre destin.</p>
                        <p>Rassemblez des armées de soldats valeureux, déployez des espions pour déjouer vos ennemis, ou engagez-vous dans une production effrénée de ressources pour bâtir un empire prospère.</p>
                    </>
                }
                backgroundClass="background-build"
                alignment="right"
            />
            <LandingComponent
                title='Guerroyez entre seigneurs'
                content={<>
                    <p>Partez à l'assaut des autres seigneurs dans une quête sans merci pour la domination suprême !</p>
                    <p>
                        La conquête est une aventure audacieuse où la stratégie et la bravoure sont vos meilleurs alliés.
                        Recrutez des armées, planifiez des tactiques et défiez vos rivaux pour prendre le contrôle de leurs terres.
                    </p>
                    <p>L'heure de la bataille approche, préparez-vous à forger votre destin dans les feux de la guerre !</p>
                </>
                }
                backgroundClass="background-conquest"
                alignment="left"
            />
            <LandingComponent
                title='Rejoignez une alliance'
                content={
                    <>
                        <p>Plongez dans un monde où les alliances se tissent et se brisent, où la diplomatie et la trahison sont monnaie courante.</p>
                        <p>Forgez des amitiés avec d'autres seigneurs, formez des coalitions pour asseoir votre domination ou trahissez vos alliés.
                            Rejoignez-nous maintenant et découvrez le côté social et politique de Conquest, où se mêlent amitié, trahison et stratégie sans fin !</p>
                        <p className='text-center'><Button variant="contained" href="/signup">JOUEZ</Button></p>
                    </>
                }
                backgroundClass="background-alliance"
                alignment="right"
            />
        </>
    )
}

export default LandingPage