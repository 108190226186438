import './footer.scss'
import { Link } from "react-router-dom";
import { Link as LinkMui } from "@mui/material";


function Footer() {
    return (

        <div className="footer-container">

            <div>
                <p>©Conquest-world 2023</p>
            </div>

            <div className={"link-container"}>
                <LinkMui component={Link} to={"/changelog"}>Changelog</LinkMui>
                <LinkMui component={Link} to={"https://discord.gg/VNSSsd9kDN"} target="_blank">Discord</LinkMui>
                <LinkMui component={Link} to={"/help"}>Help</LinkMui>
            </div>

            <div>
                <p>Made by C and T</p>
                <p>With React, Node and ♡</p>
            </div>
        </div>
    )
}

export default Footer