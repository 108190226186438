import ContentPane from "../../layout/content-pane/content-pane";
import { Button, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useEffect, useState } from "react";
import backendRepository from "../../repository/backend-repository";
import moment from "moment";
import { Link } from "react-router-dom";
import usePlayerStore from "../../store/use-player-store";
import { thDelimiter } from "../../services/utils";

function TroopsReturnInfo() {
    const troopsReturn = usePlayerStore((state) => state.troopsReturn)
    const spyReturn = usePlayerStore((state) => state.spyReturn)
    const fetchTroopsReturn = usePlayerStore((state) => state.fetchTroopsReturn)
    const fetchSpyReturn = usePlayerStore((state) => state.fetchSpyReturn)

    useEffect(() => {
        fetchSpyReturn()
        fetchTroopsReturn()
    }, [fetchSpyReturn, fetchTroopsReturn])

    if (troopsReturn.length === 0) {
        return null
    }

    return <ContentPane title={"Retour de vos troupes"}>
        <List>
            {spyReturn.map((e, i) => <ListItem key={i}>
                <ListItemAvatar>
                    <WatchLaterIcon />
                </ListItemAvatar>
                <ListItemText
                    primary={<>Retour de <strong>{thDelimiter(e.spyQuantity)}</strong> espions le {moment(e.deliveredAt).format("YYYY/MM/DD à HH:mm:SS")}</>}
                    secondary={<Button variant={"outlined"} component={Link} to={`/spyings/${e.spyingId}`}>Voir le rapport</Button>}
                />
            </ListItem>)}
            {troopsReturn.map((e, i) => <ListItem key={i}>
                <ListItemAvatar>
                    <WatchLaterIcon />
                </ListItemAvatar>
                <ListItemText
                    primary={<>Retour de <strong>{thDelimiter(e.soldierQuantity)}</strong> troupes le {moment(e.deliveredAt).format("YYYY/MM/DD à HH:mm:SS")}</>}
                    secondary={<Button variant={"outlined"} component={Link} to={`/conquests/${e.conquestId}`}>Voir le rapport</Button>}
                />
            </ListItem>)}
        </List>
    </ContentPane>
}

export default TroopsReturnInfo