import "../../layout/content-pane/content-pane"
import "../../index.scss"
import React from "react"
import RuleLink from "../../layout/rule-link/rule-link";
import { Button } from "@mui/material";
import useUiStore from "../../store/use-ui-store";
import {toast} from "react-toastify";

function HelperPane({ title = "", children, id, linkTo }) {
    const toggleHelper = useUiStore(state => state.toggleHelper)

    const helper = useUiStore((state) => state.helper);
    const handleClose = () => {
        toggleHelper();
        toast.info("Aide désactivés. Vous pouvez les réactiver à tout moment sur la page de configuration de votre compte", {
            autoClose: 5000,
            position: "top-center"
        })
    };
    return (
        <div>
            {helper && (
                <div className="content-pane" id={id}>
                    <div className="content-pane" id={id}>
                        <h2>{title}</h2>
                        <div className={"content-pane-content text-left"}>
                            {children}
                            <div className="flex justify-space-between">
                                <Button size="small" onClick={handleClose}>Désactiver aide</Button>
                                <RuleLink section={linkTo} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HelperPane