import { Chip } from "@mui/material";
import game from "../../assets/game/game";

function SpyingForChip({ spyType, detailed = false }) {
    if (!spyType) {
        return null;
    }
    if (!detailed) {
        return <span>{game.SPY_TYPES[spyType].ICON(detailed)}</span>
    }

    return <Chip
        size={"small"}
        variant={"filled"}
        icon={game.SPY_TYPES[spyType].ICON(detailed)}
        color={game.SPY_TYPES[spyType].COLOR}
        className={"strong"}
        label={game.SPY_TYPES[spyType].NAME} />
}

export default SpyingForChip