
import ContentBox from '../../layout/content-box/content-box'
import './landing-component.scss'
import { Button } from '@mui/material';
import ConquestLogo from "../../assets/img/logo.webp"

function LandingWelcome() {

    //return ()
    return (
        <>
            <div className="background-landing background-welcome">
                <ContentBox opacity={true} header="Conquest World" >
                    <div className='flex flex-col px-5'>
                        <img className='welcome-logo border-rad' src={ConquestLogo} alt='war medieval fantasy' />
                        <p>Bienvenue dans l'univers épique de Conquest !</p>
                        <p>Ici, vous deviendrez le maître de votre propre destin en créant votre seigneur et en bâtissant
                            un royaume digne des légendes. À travers des batailles épiques et des stratégies habiles, vous dirigerez votre
                            royaume vers la gloire et la puissance.
                            Rejoignez-nous maintenant et préparez-vous à conquérir le monde !</p>
                        <p className='text-center'><Button variant="contained" href="/signup">JOUEZ</Button></p>
                    </div>
                </ContentBox>
            </div>
        </>

    )
}

export default LandingWelcome