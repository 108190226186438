import { useState } from 'react'
import React from 'react'
import './spying.scss'
import SpyingSummary from './spying-summary'
import SpyingCard from '../../components/spying-card/spying-card'
import SearchPlayersByLands from '../../components/search-players-by-lands/search-players-by-lands'
import PageTitle from '../../layout/page-title/page-title'
import HelperPane from '../../layout/helper-pane/helper-pane'
import { toast } from 'react-toastify'
import backendRepository from '../../repository/backend-repository'
import { useNavigate } from 'react-router'
import usePlayerStore from '../../store/use-player-store'
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import ContentPane from '../../layout/content-pane/content-pane'

const steps = ['Choisissez un joueur à espionner', 'Planifiez votre espionnage', "Résumé"]

function Spying() {

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [spyingValidity, setSpyingValidity] = React.useState(false);
  const [playerToSpy, setPlayerToSpy] = useState(null)
  const [spyQuantity, setSpyQuantity] = useState(0)
  const [spyType, setSpyType] = useState(null)
  const [validPlayerToSpy, setValidPlayerToSpy] = useState(false)
  const navigate = useNavigate()
  const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)


  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setSpyQuantity(null)
    setPlayerToSpy(null)
    setActiveStep(0);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    toast.info(
      <div>
        <p>Voulez-vous vous espionner {playerToSpy.nickname} avec {spyQuantity} espions ?</p>
        <div className='toastify-button-container'>
          <Button className='button-hamburger' onClick={handleSpying}>Oui</Button>
          <Button className='button-hamburger' onClick={handleCancel}>Non</Button>
        </div>
      </div>,
      {
        position: toast.POSITION.CENTER_CENTER,
        autoClose: false,
        closeOnClick: true,
        closeButton: false
      }
    );
  };

  async function handleSpying(event) {

    event.preventDefault()

    try {
      const spying = await backendRepository.createSpying(spyQuantity, playerToSpy, spyType)
      reloadPlayerData()
      toast.success(`Vous espionnez ${playerToSpy.nickname}`)
      setTimeout(() => {
        navigate(`/spyings/${spying.id}`);
      }, 2000);

    } catch (error) {
      toast.error('l\'espionnage n\'a pas fonctionné')
      console.log(error)
    }
  }

  const handleCancel = (event) => {
    event.preventDefault()
  }

  const handlePlayerSelected = (defender) => {
    setPlayerToSpy(defender)
    setValidPlayerToSpy(true)
  }

  let canNext = true;
  if (activeStep === 0 && !validPlayerToSpy) {
    canNext = false
  }
  if (activeStep === 1 && !spyingValidity) {
    canNext = false
  }

  return <>
    <div className='spying-container'>
      <PageTitle>Espionnage</PageTitle>
      <HelperPane linkTo={"spy"}>
        <p>
          Utilisez vos espions dans les missions d'espionnage disponibles. Selectionnez une cible,
          choisissez un type d'espionnage, une quantité d'espions et renversez les royaumes
          avec des trahisons et des coups de couteau à la cour du roi.
        </p>
        <p>
          L'espionnage est une mecanique puissante mais dangereuse. Si vos espions echouent votre cible sera alerté ou pire,
          vos espions pourraient etre torturés et livrer des informations sur votre royaume !
        </p>
      </HelperPane>

      <ContentPane>
        <Box>
          <Stepper>
            {steps.map((label, index) => {
              const stepProps = {
                active: index === activeStep
              };
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && <SearchPlayersByLands
                player={playerToSpy}
                displayQuota={false}
                onPlayerSelected={handlePlayerSelected}
              />}
              {activeStep === 1 && <SpyingCard
                spyQuantity={spyQuantity}
                type={spyType}
                player={playerToSpy}
                onChange={(q, ct) => {
                  setSpyQuantity(q);
                  setSpyType(ct)
                }}
                onValidityChange={(valid) => {
                  setSpyingValidity(valid)
                }}
              />}
              {activeStep === 2 && <SpyingSummary spyQuantity={spyQuantity} target={playerToSpy} spyType={spyType} />}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  variant={"outlined"}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Retour
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                {activeStep !== steps.length - 1 && <Button variant={"contained"} color={"primary"} disabled={!canNext} onClick={handleNext}>
                  Suivant
                </Button>}
                {activeStep === steps.length - 1 && <Button
                  variant="contained"
                  color={"secondary"}
                  onClick={handleConfirm}
                >
                  Espionner {playerToSpy ? playerToSpy.nickname : ""}
                </Button>}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </ContentPane>
    </div>
  </>
}

export default Spying