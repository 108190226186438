export function MagicShieldSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Bouclier Divin qui a <strong>échoué</strong>.
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Bouclier Divin qui augmente la défense de vos soldats de <strong>{(notification.body.spell.effect.soldier_defense_bonus * 100).toFixed(2)}%</strong>.
    </div>
}

export function MagicShieldSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Les soldats de {spellCast.defender.nickname} protègent le royaume avec une défense accrue de {(spellCast.effect.soldier_defense_bonus * 100).toFixed(2)}%</span>
    }
    return <span>Les soldats défendent le royaume avec une efficacité supplémentaire de {(spellCast.effect.soldier_defense_bonus * 100).toFixed(2)}%</span>
}
