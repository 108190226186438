import React from "react";
import ResourceIcon from "../resource-icon/resource-icon";
import "./construction-tile.scss"
import {thDelimiter} from "../../services/utils";


function FaceCompact({ onClick, playerConstruction }) {


    return <div onClick={onClick}
        key={playerConstruction.id}
        className={`tile tile-compact tile-header`}>
        <ResourceIcon style={{borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} type={playerConstruction.construction.type} />
        <h3>{playerConstruction.construction.name}</h3>
        <p>{thDelimiter(playerConstruction.quantity)}</p>
    </div>;
}

export default FaceCompact