import ContentPane from "../../layout/content-pane/content-pane";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function NotFound() {
    return <>
        <ContentPane title={"404 - Oops !"}>
            <img className={"w-full"} alt="defeat-conquest" src={require("./../../assets/img/conquest-defeat.jpg")} />
            <h3 className={"text-center"}>
                Aucune page trouvée pour l'adresse demandée
            </h3>
            <Button component={Link} to={"/"}>Retour à l'accueil</Button>
        </ContentPane>
    </>
}

export default NotFound