import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {Badge, MenuItem, Typography} from "@mui/material";
import "./notification-button.scss"
import moment from "moment";
import {MOMENT_FORMAT_HM} from "../../services/utils";
import {useState} from "react";

const iconSize = "1.5em"
const severityColor = {
    info: "#29b6f6",
    warning: "#ff9800",
    success: "#4caf50",
    error: "#ef5350",
    primary: "#75ABBC",
    secondary: "#384972"
}
function BaseNotification({
    notification = null,
    onClick = () => {},
    title = "",
    children = null,
    icon = (props) => <FiberManualRecordIcon {...props} />,
    computeSeverity = false,
}) {
    const [detailed, setDetailed] = useState(false)
    const iconProps = {
        color: computeSeverity ? severityColor[notification.severity] : notification.severity,
        sx: {fontSize: iconSize},
        size: iconSize,
    }

    const handleOnClick = (e) => {
        setDetailed(! detailed)
        onClick(e, notification)
    }
    return <MenuItem onClick={handleOnClick}  style={{whiteSpace: 'normal'}} className={"flex flex-row"}>
        <div className={"flex flex-col"}>
            <div className={"flex flex-row flex-items-center"}>
                <div className={"mr-4 flex flex-row flex-items-center flex-center"}>
                    <Badge
                        variant={"dot"}
                        invisible={notification.isRead}
                        sx={{
                            "& .MuiBadge-badge": {
                                color: "black",
                                backgroundColor: "#e3b505"
                            }
                        }}
                    >
                        {icon(iconProps)}
                    </Badge>
                </div>
                <div className={"notification-item-content"}>
                <span className={"notification-item-subcontent"}>
                    {moment(notification.createdAt).format(MOMENT_FORMAT_HM)}
                </span>
                    <Typography sx={{fontWeight: "bold"}} variant={"inherit"} noWrap>
                            {title}
                    </Typography>
                </div>
            </div>
            {detailed && <div className={"notification-item-detailed"}>
                {children}
            </div>}
        </div>
    </MenuItem>
}

export default BaseNotification