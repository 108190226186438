import "./buy-construction-card.scss"
import usePlayerStore from "../../store/use-player-store";
import { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, TextField } from "@mui/material"
import ProductionChip from "./production-chip";
import game from "../../assets/game/game";
import DestroyConstructionInput from "./destroy-construction-input";
import { thDelimiter } from "../../services/utils";

//&& matchingStock.resource.id === cost.resourceId
//Card used to create a new construction
function BuyConstructionCard({ construction, player, onBuy = () => { }, onDestroy = () => { } }) {
    const playerConstructions = usePlayerStore(state => state.playerConstructions)
    const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const productions = usePlayerStore(state => state.productions)
    const fetchPlayerConstructions = usePlayerStore(state => state.fetchPlayerConstructions)
    const fetchPlayerProductions = usePlayerStore(state => state.fetchPlayerProductions)
    const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)
    const findProductionByResource = usePlayerStore(state => state.findProductionByResource)

    //logic to the construction order
    const [quantity, setQuantity] = useState(0)
    const [exceedCost, setExceedCost] = useState(false)
    const updatedMatchingStock = useRef([]);
    const [playerConstruction, setPlayerConstruction] = useState(null)
    const computedProductions = useRef([])
    const [displayDestroy, setDisplayDestroy] = useState(false)

    function computeCostExceeded(newQuantity, force = false) {
        if (!force && newQuantity === 0) {
            setExceedCost(false)
            return
        }

        updatedMatchingStock.current = []
        if (newQuantity === 0) {
            newQuantity = 1
        }

        let exceeded = false
        for (const cost of construction.cost) {
            const stock = resourceStocks.find(
                (stock) => stock.resource.id === cost.resource.id
            );

            if (stock && cost.quantity * newQuantity > stock.quantity) {
                exceeded = true
                updatedMatchingStock.current.push(true);
            } else {
                updatedMatchingStock.current.push(false);
            }
        }

        setExceedCost(exceeded)
    }

    function computeProduction(playerConstruction, quantity = 0) {
        if (quantity === 0 || quantity === '' || isNaN(quantity)) {
            quantity = 0
        }
        computedProductions.current = []
        for (const production of playerConstruction.construction.productions) {
            if (production.quantity === 0) {
                continue
            }

            const resourceStock = resourceStocks.find((resourceStock) => resourceStock.resource.id === production.resourceId);
            const playerProduction = findProductionByResource(resourceStock.resource.id)

            computedProductions.current.push({
                quantity: production.quantity,
                computedProduction: production.quantity * quantity,
                resourceName: resourceStock.resource.name,
                type: resourceStock.resource.type,
                currentProduction: playerProduction.quantity * quantity,
                nextCycleProduction: playerProduction.productionOnNextCycle
            })
        }
    }

    // Preload data
    useEffect(() => {
        fetchPlayerConstructions()
        fetchPlayerResources()
        fetchPlayerProductions()
    }, [fetchPlayerConstructions, fetchPlayerResources, fetchPlayerProductions])

    // Compute cost exceed on load component
    useEffect(() => {
        if (playerConstructions.length === 0 || resourceStocks.length === 0 || productions.length === 0) {
            return
        }
        computeCostExceeded(quantity, true)


        for (const playerC of playerConstructions) {
            if (playerC.construction.id === construction.id) {
                setPlayerConstruction(playerC)
                computeProduction(playerC)
                break
            }
        }
        setQuantity(0)
    }, [playerConstructions, resourceStocks, productions, construction.id])

    // Handle change on quantity input
    const handleQuantityChange = (event) => {
        event.preventDefault();
        let newQuantity = parseInt(event.target.value);
        setQuantity(newQuantity);
        computeCostExceeded(newQuantity)
        computeProduction(playerConstruction, newQuantity)
    };

    // Emmit onBuy when button clicked
    const handleBuy = (event) => {
        event.preventDefault()
        onBuy(construction, quantity)
        setQuantity(0)
        computeCostExceeded(0)
        computeProduction(playerConstruction)
    }

    const handleMax = event => {
        let max = null
        for (const cost of construction.cost) {
            const stock = resourceStocks.find(
                (stock) => stock.resource.id === cost.resource.id
            );

            let localMax = Math.floor(stock.quantity / cost.quantity)
            if (localMax > player.freeLand) {
                localMax = player.freeLand
            }
            if ((null === max || localMax < max) && localMax !== Infinity) {
                max = localMax
            }
        }

        computeCostExceeded(max ?? 0, true)
        computeProduction(playerConstruction, max)
        setQuantity(max ?? 0)
    }


    const handleReset = () => {
        setQuantity(0)
        computeCostExceeded(0)
        computeProduction(playerConstruction)
    }

    const computeCapacity = () => {
        return game.COMPUTE_HOUSE_CAPACITY(construction.type, playerConstruction.quantity)
    }

    return (
        <div className="action-card">
            <div className={`banner banner-${construction.type}`}>
                <div className="action-title">
                    <h3>{construction.name}</h3>
                    <ul className='action-cost'>Coût :
                        {/* In this map, we look if the cost of the construction and the quantity in the input is NaN or < 0.
                    If it is, so we display the cost of the construction*/}
                        {construction.cost.map((cost, j) => (
                            <li key={j} className={exceedCost && updatedMatchingStock.current[j] ? "exceedCost" : ""}>
                                {isNaN(cost.quantity * quantity) || cost.quantity * quantity === 0
                                    ? cost.quantity
                                    : cost.quantity * quantity}
                                {' ' + cost.resource.name}
                            </li>
                        ))}</ul>
                </div>
            </div>
            <form className='action-form'>
                <p className='action-description'>{construction.description}</p>
                {playerConstruction && (
                    <div>
                        {game.HOUSE_CONSTRUCTIONS.includes(construction.type) && <>
                            <div><span className={"strong"}>Capacité actuelle :</span> {thDelimiter(computeCapacity())}</div>
                        </>}
                        <div><span className={"strong"}>Terrain restants :</span> {thDelimiter(player.freeLand)}</div>
                        <div><span className={"strong"}>Bâtiments possédés :</span> {thDelimiter(playerConstruction.quantity)}</div>
                        <div className={"strong"}>Production par tour :</div>
                        {computedProductions.current.map((p, j) => (
                            <p key={j}>
                                <span className={"mr-2"}>{p.quantity} {p.resourceName} </span>
                                <ProductionChip
                                    computedProduction={p.computedProduction}
                                    currentProduction={p.currentProduction}
                                    nextCycleProduction={p.nextCycleProduction}
                                />
                            </p>
                        ))}
                        {computedProductions.current.length === 0 && <p>
                            Ce batiment ne produit aucune resource
                        </p>}
                    </div>
                )}
                <label className={"strong"} htmlFor={`quantityInput${construction.id}`}>Quantité à construire:</label>

                <div className='action-input-container'>
                    <TextField
                        size={"small"}
                        className='action-input'
                        id={`quantityInput${construction.id}`}
                        type="number"
                        value={isNaN(quantity) ? '' : quantity}
                        onChange={(event) => handleQuantityChange(event)} />

                    <ButtonGroup variant={"outlined"}>
                        <Button onClick={handleMax}>Max</Button>
                        <Button onClick={handleReset}>Reset</Button>
                        <Button
                            variant="contained"
                            disabled={exceedCost || quantity === 0 || isNaN(quantity) || quantity === ''}
                            onClick={handleBuy}>
                            Construire
                        </Button>
                    </ButtonGroup>
                </div>
                <div className={"mt-8"}>
                    <Button color={"error"} variant={displayDestroy ? "contained" : "outlined"} onClick={() => setDisplayDestroy(!displayDestroy)}>Démantelement</Button>
                </div>
                {displayDestroy && <DestroyConstructionInput
                    playerConstruction={playerConstruction}
                    construction={construction}
                    onDestroy={onDestroy}
                />}
            </form>
        </div>
    )
}

export default BuyConstructionCard