import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import "./sign-up.scss"
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Alert } from "@mui/material";
import ContentBox from "../../layout/content-box/content-box";
import backendRepository from "../../repository/backend-repository";


function SignUp() {

    const races = ["Humain", "Elfe", "Nain", "Orc", "Halfelin", "Géant"];
    const navigate = useNavigate();

    //all the state of the necessary form for the account creation
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [race, setRace] = useState('');
    const [background, setBackground] = useState('');
    const [age, setAge] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //handle the disabled button
    const [buttonDisabled, setButtonDisabled] = useState(true);

    //handle the current input used by the user
    const [isInputFocused, setIsInputFocused] = useState("");

    const isNicknameValid = nickname.length >= 4 && nickname.length <= 20 && /^[A-Za-z0-9 _-]+$/.test(nickname);
    const isEmailValid = email !== '' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isRaceValid = race !== '';
    const isAgeValid = age !== '' && Number.isInteger(Number(age));
    const isPasswordValid = password !== '';
    const isConfirmPasswordValid = confirmPassword === password
    const isBackgroundValid = background.length <= 15000;

    useEffect(() => {
        const isDisabled = !(
            isNicknameValid &&
            isEmailValid &&
            isRaceValid &&
            isAgeValid &&
            isPasswordValid &&
            isConfirmPasswordValid &&
            isBackgroundValid
        );
        setButtonDisabled(isDisabled);
    }, [nickname, email, race, age, password, background, isNicknameValid, isEmailValid, isRaceValid, isAgeValid, isPasswordValid, isConfirmPasswordValid, isBackgroundValid]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (password !== confirmPassword) {
                toast.error("Les mots de passe ne correspondent pas.");
                return
            }
            //call create player in the repository
            await backendRepository.subscribe({
                nickname,
                email,
                race,
                background,
                age,
                password,
            });
            toast.success('Votre compte a été créé, veuillez vous connecter')
            setTimeout(() => {
                navigate(`/login`);
            }, 2000);

        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue lors de la création de votre compte. Veuillez re-essayer plus tard')
        }
    };


    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    //form to create account
    return (
        <ContentBox
            header={"S'inscrire à Conquest"}
        >

            <p>Veuillez rentrer vos informations pour creer un compte:</p>
            <form onSubmit={handleSubmit} className={"signup-container"}>
                <div className="form-element">
                    <TextField
                        className={"form-element"}
                        label="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onFocus={() => setIsInputFocused(email)}
                        onBlur={() => setIsInputFocused("")}
                    />
                    {isEmailValid || email === '' || isInputFocused === email ? null : <Alert severity='warning'>Veuillez rentrer une adresse email valide</Alert>}
                </div>
                <div className="form-element">
                    <TextField
                        className={"form-element"}
                        label="Mot de passe"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onFocus={() => setIsInputFocused(password)}
                        onBlur={() => setIsInputFocused("")}
                    />
                </div>
                <div className="form-element">
                    <TextField
                        className={"form-element"}
                        label="Confirmez votre mot de passe"
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        onFocus={() => setIsInputFocused(confirmPassword)}
                        onBlur={() => setIsInputFocused("")}
                    />
                    {(password === '' && isInputFocused === password) || confirmPassword === '' || (isPasswordValid && isConfirmPasswordValid) ? null : <Alert severity='warning'>Votre mot de passe et votre confirmations doivent correspondrent</Alert>}
                </div>
                <div className="form-element">
                    <TextField
                        className={"w-full"}
                        label="Nom de votre seigneur"
                        value={nickname}
                        onChange={(event) => setNickname(event.target.value)}
                        onFocus={() => setIsInputFocused(nickname)}
                        onBlur={() => setIsInputFocused("")}
                    />
                    {isNicknameValid || nickname === '' || isInputFocused === nickname ? null : <Alert severity='warning'>votre nom doit faire entre 4 et 20 caractères et ne pas posseder de caractères spéciaux</Alert>}
                </div>
                <div className="form-element">
                    <FormControl className="w-full">
                        <InputLabel id="race-label">Race</InputLabel>
                        <Select
                            labelId="race-label"
                            id="race"
                            label="Race"
                            value={race}
                            onChange={(event) => setRace(event.target.value)}
                            onFocus={() => setIsInputFocused(race)}
                            onBlur={() => setIsInputFocused("")}
                        >
                            <MenuItem value="">Sélectionnez une race</MenuItem>
                            {races.map((raceOption, index) => (
                                <MenuItem key={index} value={raceOption}>
                                    {raceOption}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {isRaceValid || race === '' ? null : <Alert severity='warning'>Veuillez choisir une race pour votre seigneur</Alert>}
                </div>
                <div className="form-element">
                    <TextField
                        className={"w-full"}
                        type={"number"}
                        label="Age du personnage"
                        value={age}
                        onChange={(event) => setAge(event.target.value)}
                        onFocus={() => setIsInputFocused(age)}
                        onBlur={() => setIsInputFocused("")}
                    />
                    {isAgeValid || age === '' || isInputFocused === age ? null : <Alert severity='warning'>Veuillez rentrer un âge valide</Alert>}
                </div>
                <div className="form-element">
                    <TextField
                        multiline
                        className={"w-full"}
                        type={"text"}
                        label="Background"
                        value={background}
                        rows={8}
                        onChange={(event) => setBackground(event.target.value)}
                        onFocus={() => setIsInputFocused(background)}
                        onBlur={() => setIsInputFocused("")}
                    />
                    {isBackgroundValid || background !== '' ? null : <Alert severity='warning'>Le background est optionnel, mais fortement recommandé</Alert>}
                </div>
                <div className="form-element flex justify-flex-end">
                    <Button type={"submit"}
                        disabled={buttonDisabled}
                        variant={"contained"}>S'inscrire</Button>
                </div>
            </form>
        </ContentBox>
    );
}

export default SignUp;
