import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useState} from "react";

function HamburgerDropdown({title = "", children = "", onOpen = () => {}, icon = ""}) {
    const [dropdown, setDropdown] = useState(false)

    const displayDropdown = (prevent = false) => () => {

        const newVal = !dropdown

        if (prevent) {
            onOpen()
        }

        setDropdown(newVal)
    }

    function dropdownClassnames() {
        return "dropdown " + (dropdown ? "visible" : "hidden")
    }

    return <>
        <ListItem key={title} disablePadding onClick={displayDropdown(true)}>
            <ListItemButton>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} />
                <ListItemIcon>
                    {dropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
        <div className={dropdownClassnames("conquest")} onClick={displayDropdown()} >
            {children}
        </div>
    </>
}

export default HamburgerDropdown