import "./chip.scss"

/**
 * @param children
 * @param {boolean} selected
 * @param onClick
 * @param {string} size
 * @param {string} type
 */
function Chip({children, selected, onClick, size = "md", type = null, className = ""}) {
    selected = selected ?? false

    const computeClassName = () => {
        let classes = ["chip"]

        if (size === "sm") {
            classes.push("chip-small")
        } else if (size === "lg") {
            classes.push("ship-large")
        }

        if (selected) {
            classes.push("chip-selected")
        }

        switch(type) {
            case "success":
                classes.push("chip-success")
                break;

            case "warning":
                classes.push("chip-warning")
                break;

            case "danger":
                classes.push("chip-danger")
                break;

            case "info":
            default:
                classes.push("chip-info");
                break;
        }

        return [...classes, ...[className]].join(" ")
    }

    return <span onClick={onClick} className={computeClassName()}>{children}</span>
}

export default Chip