import "./conquest-counter.scss"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {Alert, Tooltip} from "@mui/material";
import moment from "moment";

function ConquestCounter({conquests = [], count = 3}) {
    return <div className={"flex flex-center flex-col"}>
        <div className={"flex flex-center mb-4"}>
            <h3 className={"mr-4"}>Quota :</h3>
            {Array.from(Array(count).keys()).map(i => {
                if (conquests.length <= i) {
                    return <RadioButtonUncheckedIcon key={i} />
                }
                return <Tooltip key={i} placement={"top"} title={moment(conquests[i].createdAt).format("YYYY/MM/DD HH:mm:SS")}>
                    <RadioButtonCheckedIcon />
                </Tooltip>
            })}
        </div>
        {conquests.length === count && <Alert severity={"warning"}>
            <div>
                Vous avez atteint votre quota d'attaque sur ce joueur.
            </div>
            <div>
                La limite est de 3 attaques sur 24 heures glissantes.
            </div>
        </Alert>}
    </div>
}

export default ConquestCounter