import {create} from "zustand";
import backendRepository from "../repository/backend-repository";

const useGameStore = create((set, get) => ({
    constructions: [],
    fetchConstructions: async (force = false) => {
        const token = localStorage.getItem("token")
        if (!force && (! token || get().constructions.length !== 0)) {
            return
        }
        const constructions = await backendRepository.getConstructionCost()

        set({constructions})
    },
    findConstruction: (id) => {
        for (const construction of get().constructions) {
            if (construction.id === id) {
                return construction
            }
        }
        return null
    }
}))

export default useGameStore