import {Card, CardContent, CardHeader, Grid, Radio, Typography} from "@mui/material";
import ConquestForChip from "../conquest-for-chip/conquest-for-chip";
import "./conquest-type-selector.scss"

function ConquestTypeSelector({options = [], value = null, onChange = (value) => {}}) {
    const handleChange = (option) => (e) => {
        onChange(option.value)
    }

    const handleClassName = (option) => {
        const classes = ["cursor-pointer"]

        if (option.value === value) {
            classes.push("conquest-type-selected")
        }

        return classes.join(" ")
    }

    return <Grid container spacing={2}>
        {options.map((option, index) => <Grid item xs={12} md={6} key={index}>
            <Card variant={value === option.value ? "elevation" : "outlined"} onClick={handleChange(option)} className={handleClassName(option)}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        <Radio checked={value === option.value} /> <ConquestForChip conquestType={option.value} /> {option.title}
                    </Typography>
                    {option.description}
                </CardContent>
            </Card>
        </Grid>)}
    </Grid>
}

export default ConquestTypeSelector