import {thDelimiter} from "../../services/utils";

export function VoraciousSwarmSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort nuée vorace qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort nuée vorace qui a détruit <strong>{thDelimiter(notification.body.spell.effect.foodDestroyed)} nourriture</strong> dans votre stock.
    </div>
}

export function VoraciousSwarmSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Vous avez détruit {thDelimiter(spellCast.effect.foodDestroyed)} nourriture dans le stock de {spellCast.defender.nickname}</span>
    }
    return <span>Vous avez détruit {thDelimiter(spellCast.effect.foodDestroyed)} nourriture</span>
}