import React from "react"
import "./army.scss"
import ArmyAction from '../../components/army-action/army-action'
import PlayerResourceInfo from "../../components/player-resource-info/player-resource-info"
import PageTitle from "../../layout/page-title/page-title"
import HelperPane from "../../layout/helper-pane/helper-pane"
import {BrowserView} from 'react-device-detect';

function Army() {


  return <>
    <PageTitle>Creation de troupes</PageTitle>
    <HelperPane linkTo={"citizen"}>
      <p>
        Pour créer des nouvelles troupes, vous devez posséder les ressources nécessaires
        ainsi que des bâtiments liés à chaque troupe.
      </p>
    </HelperPane>
    <BrowserView>
      <PlayerResourceInfo
          title="Ressources"
      />
    </BrowserView>
    <ArmyAction />
  </>
}

export default Army