import {useCallback, useEffect, useState} from "react";
import backendRepository from "../../repository/backend-repository";
import './/conquest-history-list.scss'
import usePlayerStore from "../../store/use-player-store";
import Pagination from "../../layout/pagination/pagination";
import PaginationLength from "../../layout/pagination/pagination-length";
import PaginationFilter from "../../layout/pagination/pagination-filter";
import { Button, List, ListItem, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import ShieldIcon from '@mui/icons-material/Shield';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import moment from "moment";
import {addQueryParam} from "../../services/utils";
import ConquestForChip from "../conquest-for-chip/conquest-for-chip";

function ConquestHistoryList() {

    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const player = usePlayerStore(state => state.player)

    const [conquestHistory, setConquestHistory] = useState(null);

    const [pages, setPages] = useState(addQueryParam("page", 1, false))
    const [pageSize, setPageSize] = useState(addQueryParam("length", 20, false));
    const [role, setRole] = useState(addQueryParam("role", "", false))

    const fetchData = useCallback(async (pages, pageSize, role) => {
        try {
            const history = await backendRepository.getConquestHistory(pages, pageSize, role);
            setConquestHistory(history);
        } catch (error) {
            console.error(error);
        }
    }, [setConquestHistory])

    useEffect(() => {
        fetchPlayer().then(() => {
            fetchData(pages, pageSize, role)
        })
    }, [fetchData]);


    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        addQueryParam("length", pageSize, true)
        setPages(1);
        addQueryParam("page", 1, true)

        fetchData(1, pageSize, role)
    };

    const handleRoleChange = (role) => {
        setRole(role);
        addQueryParam("role", role, true)
        setPages(1);
        addQueryParam("page", 1, true)
        fetchData(1, pageSize, role)
    }

    const handlePageSelection = (page) => {
        setPages(page)
        addQueryParam("page", page, true)
        fetchData(page, pageSize, role)
    }

    if (conquestHistory === null || player === null) {
        return <Skeleton animation="wave" height={600} />
    }
    return (
        <>
            <PaginationFilter onRoleChange={handleRoleChange} filters={{ 'as-attacker': "Attaque", 'as-defender': "Defense", '': 'Tout' }} />
            <Pagination
                currentPage={conquestHistory.currentPage}
                totalPages={conquestHistory.totalPages}
                onPageSelection={handlePageSelection}
            />
            <PaginationLength value={pageSize} onPageSizeChange={handlePageSizeChange} />

            <List dense sx={{ bgcolor: 'background.paper' }}>
                {conquestHistory.conquests.map((conquest) => {
                    let role = player.id === conquest.attacker.id ? "Attaque" : "Défense"

                    let isWin
                    let result;
                    if (player.id === conquest.attackerId) {
                        isWin = conquest.isWin
                        result = conquest.isWin ? <span className="victory-text">Victoire</span> : <span className="defeat-text">Défaite</span>
                    } else {
                        isWin = !conquest.isWin
                        result = conquest.isWin ? <span className="defeat-text">Défaite</span> : <span className="victory-text">Victoire</span>
                    }

                    return (
                        <ListItem alignItems={"center"} key={conquest.id} sx={{ color: "white" }}>
                            <ListItemIcon>
                                {player.id === conquest.defender.id ? <ShieldIcon className={isWin ? "victory-text" : "defeat-text"} fontSize={"large"} /> : <GpsFixedIcon className={isWin ? "victory-text" : "defeat-text"} fontSize={"large"} />}
                                <span className={"conquest-for-icon"}>
                                    <ConquestForChip conquestType={conquest.conquestType} detailed />
                                </span>
                            </ListItemIcon>
                            <ListItemText
                                primary={<>
                                    <strong>{conquest.attacker.nickname}</strong> VS <strong>{conquest.defender.nickname}</strong><br />
                                    <strong>{result}</strong> sur {role}
                                </>}
                                secondary={
                                    <>{moment(conquest.createdAt).format("YYYY/MM/DD HH:mm:SS")}</>
                                }
                            />
                            <Button variant={"outlined"} component={Link} to={`/conquests/${conquest.id}`}>Détail</Button>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}

export default ConquestHistoryList