export function FurrySpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Furie Guerrière qui a <strong>échoué</strong>.
        </div>
    }
    const atkBoost = (notification.body.spell.effect.atkBoost * 100).toFixed(2);
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Furie Guerrière qui augmente votre attaque de <strong>{atkBoost}%</strong>.
    </div>
}

export function FurrySpellBonus({ bonus }) {
    return <span><strong>Furie Guerrière :</strong> Augmentation de la puissance d'attaque de <span className={"strong"}>+{(bonus.bonus * 100).toFixed(1)}%</span></span>
}

export function FurrySpellCast({ spellCast, detailed = true }) {

    if (detailed) {
        return <span>La puissance d'attaque de {spellCast.defender.nickname} a été augmentée de {(spellCast.effect.atkBoost * 100).toFixed(2)}%</span>
    }

    return <span>Augmentation de la puissance d'attaque de {(spellCast.effect.atkBoost * 100).toFixed(2)}%</span>
}
