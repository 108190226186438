import {Chip as MuiChip} from "@mui/material";
import {thDelimiter} from "../../services/utils";
import Chip from "../../layout/chip/chip";
import {PiArrowFatLineDownDuotone} from "react-icons/pi";
import usePlayerStore from "../../store/use-player-store";
import {useEffect, useState} from "react";
import "./spell-effect-efficiency-chip.scss"

const efficiencyDict = {
    "wizard": "Magiciens",
    "mines": "Mines",
    "land": "Terres",
    "house": "Maisons",
    "fortification": "Fortification",
    "wood": "Bois",
    "soldier": "Soldats",
    "farm": "Fermes",
}
function SpellEfficiencyChip({spell, wizardStock}) {
    const [targetQuantity, setTargetQuantity] = useState(0)
    const [casterQuantity, setCasterQuantity] = useState(0)
    const [isDetailed, setIsDetailed] = useState(false)

    useEffect(() => {
        computeQuantities(isDetailed)
    }, [])

    const computeQuantities = (detailed) => {
        let target
        let caster
        if (!detailed) {
            target = spell.efficiencyHelp.target.weight
            caster = spell.efficiencyHelp.caster.weight
        } else {
            target = (spell.efficiencyHelp.target.weight * wizardStock.quantity) / spell.efficiencyHelp.caster.weight
            caster = wizardStock.quantity
        }
        setTargetQuantity(Math.round(target))
        setCasterQuantity(Math.round(caster))
    }

    const handleClick = () => {
        computeQuantities(!isDetailed)
        setIsDetailed(!isDetailed)

    }

    if (wizardStock === null) {
        return null;
    }

    return <>
        <div className={"flex flex-col mx-auto"} style={{width: "min-content"}}>
            <div onClick={handleClick} className={"spell-effect-efficiency-container"}>
                <Chip type={"info"} className={"flex flex-items-center"}>
                    <MuiChip className={"mr-4"} color={"secondary"} label={thDelimiter(casterQuantity)} size={"small"} />
                    {efficiencyDict[spell.efficiencyHelp.caster.type]}
                </Chip>
            </div>
            <div className={"mx-4 flex flex-items-center flex-center"}>
                {<PiArrowFatLineDownDuotone color={"white"} size={25} />}
            </div>
            <div onClick={handleClick} className={"spell-effect-efficiency-container"}>
                <Chip type={"warning"} className={"flex flex-items-center"}>
                    <MuiChip className={"mr-4"} color={"secondary"} label={thDelimiter(targetQuantity)} size={"small"} />
                    {efficiencyDict[spell.efficiencyHelp.target.type]}
                </Chip>
            </div>
        </div>
        <div className={"spell-efficiency-help-text"}>
            Avec <strong>{thDelimiter(casterQuantity)} {efficiencyDict[spell.efficiencyHelp.caster.type]}</strong> il faut que la cible ai{" "}
            <strong>{thDelimiter(targetQuantity)} {efficiencyDict[spell.efficiencyHelp.target.type]}</strong> ou moins pour que le sort soit{" "}
            totalement efficace
        </div>
    </>
}

export default SpellEfficiencyChip