import Chip from "../../layout/chip/chip";
import {Tooltip} from "@mui/material";
import {thDelimiter} from "../../services/utils";

function ProductionChip({nextCycleProduction = 0, computedProduction = 0, currentProduction = 0}) {
    return <Chip type={nextCycleProduction + computedProduction >= 0 ? 'info' : 'danger'}>
        <Tooltip placement={"top"} title={<span style={{fontSize: "1.5em"}}>Contribution des constructions au tour suivant</span>}>
            <span>{thDelimiter(currentProduction)}</span>
        </Tooltip> | <Tooltip placement={"top"} title={<span style={{fontSize: "1.5em"}}>Production actuelle de cette resource</span>}>
        <span>{thDelimiter(nextCycleProduction)}</span>
    </Tooltip> | <Tooltip placement={"top"} title={<span style={{fontSize: "1.5em"}}>Production finale une fois les constructions terminées</span>}>
        <span>{thDelimiter(nextCycleProduction + computedProduction)}</span>
    </Tooltip>
    </Chip>
}

export default ProductionChip