import {Alert, Box, Button, Step, StepLabel, Stepper, Typography} from "@mui/material";
import React from "react";
import SearchPlayersByLands from "../search-players-by-lands/search-players-by-lands";
import {toast} from "react-toastify";
import SpellList from "../spell-tile/spell-list";
import SpellCastSummary from "./spell-cast-summary";
import backendRepository from "../../repository/backend-repository";
import usePlayerStore from "../../store/use-player-store";
import {useNavigate} from "react-router-dom";

const steps = ['Choisissez un joueur', 'Sélection du sort', "Résumé"]
function SpellCastStepper() {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = React.useState(0);
    const [spellTarget, setSpellTarget] = React.useState(null)
    const [selectedSpell, setSelectedSpell] = React.useState(null)
    const [skipped, setSkipped] = React.useState(new Set());

    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)
    const player = usePlayerStore(state => state.player)


    const handleReset = () => {
        setSpellTarget(null)
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSpellCast = () => {
        backendRepository.castSpell(selectedSpell.spell, spellTarget).then(data => {
            reloadPlayerData().then(() => {
                toast.success(`Vous avez lancé un sort à ${spellTarget.nickname}`)
                setTimeout(() => {
                    navigate(`/spell-casts/${data.id}`)
                }, 2000);
            })
        })
    }

    const handleCancel = (event) => {
        event.preventDefault()
    }

    const handleTargetSelected = (player) => {
        setSpellTarget(player)
    }

    const handleConfirm = (event) => {
        event.preventDefault();
        toast.info(
            <div>
                <p>Voulez-vous vous lancer un sort sur {spellTarget.nickname} ?</p>
                <div className='toastify-button-container'>
                    <Button className='button-hamburger' onClick={handleSpellCast}>Oui</Button>
                    <Button className='button-hamburger' onClick={handleCancel}>Non</Button>
                </div>
            </div>,
            {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: false,
                closeOnClick: true,
                closeButton: false
            }
        );
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleSpellSelection = (playerSpell) => {
        setSelectedSpell(playerSpell)
    }

    let notEnoughMana = false
    if (player && selectedSpell !== null && selectedSpell.spell.cost > player.mana) {
        notEnoughMana = true
    }

    // Compute if next step is available
    let canNext = true;
    if (activeStep === 0 && !spellTarget) {
        canNext = false
    }
    if (activeStep === 1 && (!selectedSpell || notEnoughMana)) {
        canNext = false
    }
    return <>
        <Box>
            <Stepper>
                {steps.map((label, index) => {
                    const stepProps = {
                        active: index === activeStep
                    };
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {activeStep === 0 && <SearchPlayersByLands
                        player={spellTarget}
                        onPlayerSelected={handleTargetSelected}
                        displayQuota={false}
                        allowSelf
                    />}
                    {activeStep === 1 && <>
                        <p>Sort sélectionné : {selectedSpell && selectedSpell.spell.name}</p>
                        <SpellList selectable={true} onSelected={handleSpellSelection} selected={selectedSpell} />
                        {notEnoughMana && <Alert severity={"error"}>Vous n'avez pas assez de mana pour lancer ce sort</Alert>}
                    </>}
                    {activeStep === 2 && <SpellCastSummary playerSpell={selectedSpell} target={spellTarget} />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            variant={"outlined"}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Retour
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        {activeStep !== steps.length - 1 && <Button variant={"contained"} color={"primary"} disabled={!canNext} onClick={handleNext}>
                            Suivant
                        </Button>}
                        {activeStep === steps.length - 1 && <Button
                            variant="contained"
                            color={"secondary"}
                            onClick={handleConfirm}
                        >
                            Ensorceller {spellTarget ? spellTarget.nickname : ""}
                        </Button>}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    </>
}

export default SpellCastStepper