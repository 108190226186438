import { useEffect, useRef, useState } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom';
import ContentPane from "../../layout/content-pane/content-pane";
import PageTitle from "../../layout/page-title/page-title";
import backendRepository from "../../repository/backend-repository";
import { Alert, Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import moment from "moment";
import usePlayerStore from "../../store/use-player-store";
import ConquestVersus from "../../components/conquest-versus/conquest-versus";
import SpyingReport from "./spying-report";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PlayerName from "../../layout/player-name";
import { thDelimiter } from "../../services/utils";
import SpyingForChip from "../../components/spying-for-chip/spying-for-chip";
function SpyingRecap() {
  const player = usePlayerStore(state => state.player)
  const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
  const [spying, setSpying] = useState(null)
  const [forbiddenAccess, setForbiddenAccess] = useState(false)
  const spyingId = useParams().id
  const isSpyReturn = useRef(false)

  const navigate = useNavigate()

  useEffect(() => {
    fetchPlayer()
  }, [fetchPlayer])

  // When player loaded fetch the spying
  useEffect(() => {
    if (!player || !spyingId) {
      return
    }

    async function fetchData() {
      try {
        //call to the repository
        const c = await backendRepository.getSpying(spyingId);
        setSpying(c)

      } catch (error) {
        if (error.response.status === 404) {
          navigate('/404')
        } else if (error.response.status === 403) {
          setForbiddenAccess(true)
        }
        console.error(error.response.status);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, navigate]);


  if (forbiddenAccess) {
    return <ContentPane title={"Accès non autorisé"}>
      <Alert severity={"error"}>Vous n'avez pas les droits pour consulter cette page</Alert>
    </ContentPane>
  }
  else if (!spying || !player) {
    return <Skeleton animation="wave" height={800} />
  }


  return <div className={"mt-4"}>
    <Button variant={"outlined"} component={Link} to={"/spyings/me"}>Retour</Button>
    <PageTitle>Rapport d'espionnage</PageTitle>
    {isSpyReturn.current &&
      <ContentPane>
        <p>
          Votre mission d'espionnage touche à sa fin. Vous pouvez analyser le rapport de vos espions ci dessous.
          Les espions encore en vie mettront 30 minutes pour revenir dans votre royaume.
        </p>
      </ContentPane>}


    <div className="action-container">
      <div className="action-card action-card-single">
        <div className={`banner banner-single 
                ${spying.successLevel >= 3 && spying.attackerId === player.id ? 'banner-spying-victory' :
            spying.successLevel >= 3 && spying.defenderId === player.id ? 'banner-spying-defeat' :
              spying.successLevel <= 2 && spying.attackerId === player.id ? 'banner-spying-defeat' :
                spying.successLevel <= 2 && spying.defenderId === player.id ? 'banner-spying-victory' : ''}`}>
          <div className="action-title">
            {spying.successLevel === 4 ? (
              <h2 className={spying.attackerId === player.id ? "conquest-victory" : "conquest-defeat"}>
                MISSION PARFAITE
              </h2>
            ) : (
              <h2 className={
                (spying.successLevel === 3 || spying.successLevel === 4) && spying.attackerId === player.id ? "conquest-victory" :
                  (spying.successLevel === 1 || spying.successLevel === 2) && spying.attackerId === player.id ? "conquest-defeat" :
                    (spying.successLevel === 3 || spying.successLevel === 4) && spying.defenderId === player.id ? "conquest-defeat" :
                      (spying.successLevel === 1 || spying.successLevel === 2) && spying.defenderId === player.id ? "conquest-victory" : ""
              }>
                {spying.successLevel === 4 ? "MISSION PARFAITE" :
                  spying.successLevel === 3 ? "MISSION REUSSIE" :
                    spying.successLevel === 2 ? "MISSION ABORDEE" :
                      spying.successLevel === 1 ? "MISSION ECHOUEE" : ""}
              </h2>
            )}
          </div>
        </div>
        <ContentPane>

          {spying.successLevel === 3 && player.id === spying.defenderId ? (
            <ConquestVersus attacker="Inconnu" defender={spying.defender} />
          ) : (
            <ConquestVersus attacker={spying.attacker} defender={spying.defender} />
          )}

          <TableContainer>
            <h2 className={"mb-8"}>Details</h2>
            <Table size={"small"}>
              <TableBody>
                <TableRow>
                  <TableCell>Date de l'espionnage</TableCell>
                  <TableCell colSpan={3}>{moment(spying.createdAt).format("YYYY/MM/DD HH:mm:SS")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Attaquant</TableCell>
                  {spying.successLevel <= 3 && player.id === spying.defenderId ? (
                    <TableCell><strong><PlayerName player={"Inconnu"} /></strong></TableCell>
                  ) : (
                    <TableCell><strong><PlayerName player={spying.attacker} /></strong></TableCell>
                  )
                  }
                  <TableCell>Défenseur</TableCell>
                  <TableCell><strong><PlayerName player={spying.defender} /></strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Attaque</TableCell>
                  <TableCell>{thDelimiter(spying.attackValue)}</TableCell>
                  <TableCell>Défense</TableCell>
                  <TableCell>{spying.defenseValue ? thDelimiter(spying.defenseValue) : <VisibilityOffIcon sx={{ height: "0.8em" }} />}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bonus offensif</TableCell>
                  <TableCell>{((spying.attackBonus - 1) * 100).toFixed(1)}%</TableCell>
                  <TableCell>Bonus défensif</TableCell>
                  <TableCell>{spying.defenseBonus ? `${((spying.defenseBonus - 1) * 100).toFixed(1)}%` : <VisibilityOffIcon sx={{ height: "0.8em" }} />}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Espions perdus</TableCell>
                  <TableCell>{thDelimiter(spying.attackerLostSpy)}</TableCell>
                  <TableCell>Espionnage pour</TableCell>
                  <TableCell colSpan={3}><SpyingForChip spyType={spying.spyType} detailed /> </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SpyingReport spying={spying} player={player} />
        </ContentPane>
      </div>
    </div>
  </div>
}

export default SpyingRecap