import "./content-box.scss"
import PageTitle from "../page-title/page-title";

/**
 * @param {string} props.header
 * @return {JSX.Element}
 * @constructor
 */
function ContentBox(props) {
    let header = ""
    if (props.header) {
        header = <div className={"w-full"}>
            <PageTitle>{props.header}</PageTitle>
        </div>
    }
    return (
        <div className={props.opacity === true ? 'content-box-container content-box-container-opacity' : "content-box-container"}>
            {header}
            <div className={props.opacity === true ? "content-box-content content-box-content-opacity" : "content-box-content"}>
                {props.children}
            </div>
        </div>
    )
}

export default ContentBox