import { useEffect } from "react";
import ContentPane from "../../layout/content-pane/content-pane";
import usePlayerStore from "../../store/use-player-store";
import { BsStars } from 'react-icons/bs'
import { Table, TableBody, TableHead, TableContainer, TableRow, TableCell } from '@mui/material'
import './player-active-effect.scss'
import SpellCastEffect from "../spells/spell-cast-effect";

function PlayerActiveEffect() {

    const activeEffects = usePlayerStore(state => state.activeEffects)
    const fetchActiveEffects = usePlayerStore(state => state.fetchActiveEffects)

    useEffect(() => {
        async function fetchData() {
            try {
                await fetchActiveEffects()

            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [fetchActiveEffects]);

    return (
        <>
            <ContentPane title="Effets en cours">

                <TableContainer sx={{ backgroundColor: "rgb(22, 22, 34)", marginTop: '20px', marginBottom: '20px' }} >
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell><h4>Buff</h4></TableCell>
                                <TableCell><h4>Nom du sort</h4></TableCell>
                                <TableCell><h4>Effets en cours</h4></TableCell>
                                <TableCell><h4>Durée du sort</h4></TableCell>
                                <TableCell><h4>Cycles restants</h4></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeEffects.map((activeEffect) => (
                                <TableRow key={activeEffect.cycleEffect.id}>
                                    <TableCell><BsStars
                                        className={activeEffect.spell.friendly ? 'friendly' : 'not-friendly'} />
                                    </TableCell>
                                    <TableCell>{activeEffect.spell.name}</TableCell>
                                    <TableCell><SpellCastEffect spellCast={activeEffect.spellCast} detailed={false} /></TableCell>
                                    <TableCell>{activeEffect.spell.duration} Cycles</TableCell>
                                    <TableCell>{activeEffect.cycleEffect.remainingCycle} Cycles</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ContentPane>

        </>
    )
}

export default PlayerActiveEffect