import ContentPane from "../../layout/content-pane/content-pane";
import SpyingResources from "./spying-resources";
import Chip from "../../layout/chip/chip";
import { Alert, AlertTitle } from "@mui/material";

function SpyingReport({ spying, player }) {
    const dataToDisplay = spying.successLevel > 1
        ? spying.report.attacker.info
        : spying.report.defender.info;

    return (
        <>
            <ContentPane>
                <h2>Cible du rapport: <Chip type={'warning'}>{spying.successLevel > 1 ? spying.defender.nickname : spying.attacker.nickname}</Chip></h2>
                {spying.successLevel === 1 && spying.attackerId === player.id ?
                    <Alert severity="warning">
                        <AlertTitle>Échec critique</AlertTitle>
                        <span>Vos espions ont subi un échec cuisant. Capturés et emprisonnés, ils ont révélé des informations sur votre royaume sous la torture.</span>
                    </Alert>
                    :
                    null
                }
                <div className="spying-resources-container">
                    {dataToDisplay
                        .sort((a, b) => a.resource.name.localeCompare(b.resource.name))
                        .map((resource) => (
                            <SpyingResources key={resource.resource.id} resource={resource} />
                        ))}
                </div>
            </ContentPane>
        </>
    );
}

export default SpyingReport