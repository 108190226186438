import { React, useEffect } from "react"
import '../army-action/army-action.scss'
import { toast } from "react-toastify";
import usePlayerStore from "../../store/use-player-store";
import CitizenUpgradeCard from "../citizen-upgrade-card/citizen-upgrade-card";
import backendRepository from "../../repository/backend-repository";
import { Skeleton } from "@mui/material";

function ArmyAction(filter = null) {

    //use game files to catch cost of citizen upgrade (manual update if cost is modified in backend)
    const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const fetchResources = usePlayerStore(state => state.fetchPlayerResources)
    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)

    useEffect(() => {
        async function fetchData() {
            try {
                await fetchResources()
            } catch (error) {
                toast.error(error);
            }
        }

        fetchData();
    }, [fetchResources]);


    //function use to send post query on UpgradeVillagerOrder
    async function handleUpgrade(citizenName, citizenType, quantity) {
        if (quantity <= 0) {
            toast.error("Veuillez saisir une quantité supérieure à zéro");
            return;
        }

        try {
            await backendRepository.createUpgradeVillagerOrder(quantity, citizenType)
            reloadPlayerData()
            //update resourcestock with the trigger effect
            toast.success(`Vous avez formé ${quantity} ${citizenName}`);
        } catch (error) {
            console.error(error);
            //traduction EN ==> FR of error.response.data.type
            if (error.response.data.type === "not_enough_accommodation_capacity") {
                toast.error("Capacité d'hébergement insuffisante.");
            }
            else if (error.response.data.type === "not_enough_villager") {
                toast.error("Pas assez de villageois.");
            }
            else if (error.response.data.type === "not_enough_steel") {
                toast.error("Pas assez de metaux.");
            } else {
                toast.error(error.response.data.type)
            }
        }
    }

    async function handleSacrify(quantityToDestroy, citizen) {
        try {
            await backendRepository.sacrifyCitizen(citizen, quantityToDestroy)
            reloadPlayerData();
            toast.success(`Vous avez sacrifié ${quantityToDestroy} ${citizen.name}`);
        } catch (error) {
            toast.error('Une erreur est survenue lors du sacrifice')
            console.error(error);
        }
    }

    return (
        <>
            <div className="army-container">
                {resourceStocks.length === 0 ? (
                    <Skeleton animation="wave" height={400} width={800} />
                ) : (
                    resourceStocks
                        //filter resource stock and keep only citizen AND not villager
                        .filter(citizen => citizen.resource.isCitizen)
                        .filter(citizen => {
                            if (filter && filter.hasOwnProperty('filter')) {
                                return citizen.resource.id === filter.filter;
                            }
                            return true;
                        })
                        .map((citizen, i) => (
                            <CitizenUpgradeCard
                                key={i}
                                citizen={citizen}
                                onUpgrade={handleUpgrade}
                                onSacrify={handleSacrify}
                            />
                        ))
                )}
            </div>
        </>
    );
}

export default ArmyAction;