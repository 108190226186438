import {thDelimiter} from "../../services/utils";

export function FatalNecrosisSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Nécrose Fatale qui a <strong>échoué</strong>.
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Nécrose Fatale qui a tué <strong>{thDelimiter(notification.body.spell.effect.villagerDestroyed

    )} villageois</strong> dans votre stock.
    </div>
}

export function FatalNecrosisSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Vous avez tué {thDelimiter(spellCast.effect.villagerDestroyed)} villageois dans le stock de {spellCast.defender.nickname}</span>
    }
    return <span>Vous avez tué {thDelimiter(spellCast.effect.villagerDestroyed)} villageois</span>
}
