import { React, useEffect } from "react";
import ConstructionTile from "./construction-tile";
import usePlayerStore from "../../store/use-player-store";
import ContentPane from "../../layout/content-pane/content-pane";

function PlayerConstructionList({ title, filterFunction }) {

    const fetchOrders = usePlayerStore(state => state.fetchOrders)
    const playerConstructions = usePlayerStore(state => state.playerConstructions)
    const playerProductions = usePlayerStore(state => state.productions)
    const playerResourceStocks = usePlayerStore(state => state.resourceStocks)
    const orders = usePlayerStore(state => state.orders)

    useEffect(() => {
        fetchOrders()
    }, [fetchOrders, orders])

    const sumOfCitizen = playerResourceStocks.reduce((total, resourceStock) => {
        if (resourceStock.resource.isCitizen === true) {
            return total + resourceStock.quantity;
        }
        return total;
    }, 0);

    const filteredConstructions =
        filterFunction === "isSpecial"
            ? playerConstructions.filter((playerConstruction) => playerConstruction.construction.isSpecial)
            : playerConstructions.filter((playerConstruction) => playerConstruction.construction.isSpecial === false);

    return (
        <ContentPane title={title}>
            <div className="tiles-container">
                {filteredConstructions.map((playerConstruction, index) => {
                    const production = playerProductions.filter(
                        (playerProduction) => playerProduction.constructionId === playerConstruction.construction.id);
                    const constructionOrder = orders.constructionOrders
                        ? orders.constructionOrders
                            .filter((order) => order.constructionId === playerConstruction.construction.id)
                            .reduce((total, order) => total + order.quantity, 0)
                        : 0;
                    return (
                        <ConstructionTile
                            key={index}
                            playerConstruction={playerConstruction}
                            playerProduction={production}
                            resourceStocks={playerResourceStocks}
                            sumOfCitizen={sumOfCitizen}
                            constructionOrder={constructionOrder}
                        />
                    );
                })}
            </div>
        </ContentPane>
    );
}

export default PlayerConstructionList;