import { useEffect, useState } from "react"
import PlayerResourceInfo from "../../components/player-resource-info/player-resource-info"
import FreeLandsInfo from "../../components/free-lands-info/free-lands-info"
import PageTitle from "../../layout/page-title/page-title";
import usePlayerStore from "../../store/use-player-store";
import HelperPane from "../../layout/helper-pane/helper-pane"
import PlayerConstructionList from "../../components/construction-tile/construction-list"
import CitizenList from "../../components/citizen-tile/citizen-list";
import ManaPane from "../../components/mana-pane/mana-pane";
import TroopsReturnInfo from "../../components/troops-return-info/troops-return-info";


function Dashboard() {

    // const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const fetchResources = usePlayerStore(state => state.fetchPlayerResources)
    // const playerConstructions = usePlayerStore(state => state.playerConstructions)
    const fetchPlayerConstructions = usePlayerStore(state => state.fetchPlayerConstructions)
    const player = usePlayerStore(state => state.player)

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (null === player) {
            return
        }
        function fetchData() {
            try {
                //call to the repository
                Promise.all([
                    fetchPlayerConstructions(),
                    fetchResources()
                ]).then(() => setDataLoaded(true))

            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [fetchPlayerConstructions, fetchResources, player]);

    return (
        <div>
            <PageTitle>Dashboard</PageTitle>
            <HelperPane >
                <p>
                    D'ici vous pouvez analyser et piloter votre royaume. Vous trouverez votre stock de ressources ainsi
                    que la production de votre royaume pour chaque cycle. En cliquant sur un élément, vous aurez des
                    informations supplémentaires ainsi que la possibilité de construire des bâtiments et former des citoyens.
                </p>
            </HelperPane>
            {dataLoaded && <>
                <FreeLandsInfo />
                <ManaPane />
                <TroopsReturnInfo />
                <PlayerResourceInfo
                    title="Ressources"
                />
                <CitizenList
                    title="Citoyens"
                />
                <PlayerConstructionList
                    title="Constructions"
                />
                <PlayerConstructionList
                    title="Bâtiments speciaux"
                    filterFunction="isSpecial"
                />
            </>}
        </div>
    );
}

export default Dashboard;