import ConstructionAction from "../../components/construction-action/construction-action"
import FreeLandsInfo from "../../components/free-lands-info/free-lands-info"
import PlayerResourceInfo from "../../components/player-resource-info/player-resource-info"
import HelperPane from "../../layout/helper-pane/helper-pane"
import PageTitle from "../../layout/page-title/page-title"
import {BrowserView} from 'react-device-detect';

function Construction() {

    return (
        <>
            <PageTitle>Constructions</PageTitle>
            <HelperPane linkTo={"constructions"}>
                <p>
                    En échange de terrains vides et des ressources nécessaires à chaque construction, vous pouvez
                    créer des batiments. Une fois la construction lancée et les ressources dépensées, il faudra patienter jusqu'à la fin du
                    cycle pour terminer le chantier.
                </p>
                <p>
                    Il existe deux types de bâtiments:
                </p>
                <p>
                    Les bâtiments de production vont produire la ressource associée à chaque tour. Par exemple, les fermes produiront du bois.
                    Chaque bâtiments possède une capacité max de stock pour sa ressource.
                </p>
                <p>
                    Les bâtiments spéciaux ne produisent pas de ressources à chaque tour, mais permettent de former vos citoyens ou vous
                    offrent divers bonus.
                </p>
            </HelperPane>
            <BrowserView>
                <PlayerResourceInfo
                    title="Ressources"
                />
            </BrowserView>
            <FreeLandsInfo />
            <ConstructionAction />
        </>
    )
}

export default Construction