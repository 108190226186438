import '../action-css/action.scss'
import React from 'react'
import { useEffect } from 'react'
import { toast } from "react-toastify";
import usePlayerStore from "../../store/use-player-store";
import BuyConstructionCard from "../buy-construction-card/buy-construction-card";
import useGameStore from "../../store/use-game-store";
import backendRepository from "../../repository/backend-repository";
import { Skeleton } from '@mui/material';

function ConstructionAction(filter = null) {
  const fetchPlayerConstructions = usePlayerStore(state => state.fetchPlayerConstructions)
  const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)
  const constructions = useGameStore(state => state.constructions)
  const fetchConstructions = useGameStore(state => state.fetchConstructions)
  const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)
  const player = usePlayerStore((state => state.player));
  const fetchPlayer = usePlayerStore((state => state.fetchPlayer));

  useEffect(() => {
    Promise.all([
      fetchConstructions(),
      fetchPlayerConstructions(),
      fetchPlayer(),
      fetchPlayerResources()
    ]).catch(e => console.log(e))
  }, [fetchPlayerConstructions, fetchConstructions, fetchPlayerResources, fetchPlayer]);


  async function handleConstructionOrder(construction, quantity) {
    if (quantity <= 0) {
      toast.error("Veuillez saisir une quantité supérieure à zéro");
      return;
    }
    try {
      await backendRepository.createConstructionOrder(construction.id, quantity)
      reloadPlayerData()

      toast.success(`Vous avez construit ${quantity} ${construction.name}`)
    } catch (error) {
      console.error(error);
      if (error.response.data.message === "Not enough free lands") {
        toast.error("Pas assez de terrains vides")
      }
      else if (error.response.data.message === "not enough resources") {
        toast.error("Pas assez de ressources")
      } else {
        toast.error(error.response.data.message)
      }
    }
  }

  async function handleDestroy(quantityToDestroy, construction) {
    try {
      await backendRepository.destroyConstruction(construction.id, quantityToDestroy)
      reloadPlayerData();
      toast.success(`Vous avez détruit ${quantityToDestroy} ${construction.name}`);
    } catch (error) {
      toast.error('Une erreur est survenue lors de la destruction de vos constructions')
      console.error(error);
    }
  }

  return (
    <div className="action-container">
      {constructions.length === 0 ? (
        <Skeleton animation="wave" height={400} width={800} />
      ) : (
        constructions
          .filter(construction => {
            if (filter && filter.hasOwnProperty('filter')) {
              return construction.id === filter.filter;
            }
            return true;
          })
          .map((construction, i) => (
            <BuyConstructionCard
              key={i}
              player={player}
              construction={construction}
              onBuy={handleConstructionOrder}
              onDestroy={handleDestroy}
            />
          ))
      )}
    </div>
  );
}

export default ConstructionAction;