export default class ConquestTextGenerator {
    constructor(opponentNickname) {
        this.opponentNickname = opponentNickname;
        this.titles = [
            `Mon cher ennemi ${opponentNickname},`,
            "Vous et votre saleté de royaume,",
            `${opponentNickname} Seigneur de pacotille !`,
            "Terrible tyran,",
            "Vil suzerain,",
            "Rejeton de la bête,",
            "Le vent de la révolte souffle,",
            "Dans l'ombre, je me suis préparé(e) à vous affronter,",
            "Votre règne de terreur touchera à sa fin,",
            "Je vais vous enseigner l'humilité,",
            "Noble seigneur, préparez-vous à tomber devant moi,",
            "La lumière de la justice brillera bientôt sur votre royaume,",
            "Votre cupidité ne connaîtra bientôt plus de limites,",
            "Les dieux eux-mêmes se sont détournés de vous,",
            "Un nouveau champion émerge pour vous défier,",
            "C'est le crépuscule de votre règne,",
            "Vos sujets murmurent déjà contre vous,",
            "La lame de la vengeance est aiguisée pour vous,",
            "Mon armée est prête à marcher contre vous,",
            "Vous n'êtes pas digne du trône que vous occupez,",
            "Vos jours en tant que seigneur sont comptés,",
            "La folie de votre ambition vous perdra,",
            "Le temps de votre règne illégitime est révolu,",
            "Votre soi-disant invincibilité sera votre perte,",
            "La destinée s'écrit aujourd'hui,",
            "Les échos de votre tyrannie ne seront bientôt plus qu'un souvenir,",
            `${opponentNickname} vous êtes un gougnafier !`,
            `Tremblez seigneur ${opponentNickname} !`,
            `Courage, ${opponentNickname}, votre fin est proche !`,
            `Votre prétendue grandeur ne me fait pas peur, ${opponentNickname} !`,
            `Inutile de résister, ${opponentNickname}, la victoire est mienne !`,
            `Préparez-vous à faire face à ma colère ,${opponentNickname} !`,
            `Votre règne touche à sa fin, ${opponentNickname} !`,
            `Vous n'êtes qu'une marionnette entre mes mains, ${opponentNickname} !`,
            `Je vais réduire en cendres tout ce que vous avez construit, ${opponentNickname} !`,
            `Votre royaume ne sera bientôt plus qu'un champ de ruines, ${opponentNickname} !`,
            `Bientôt, ${opponentNickname}, vous mendierez ma pitié, en vain !`,
            `Votre soi-disant puissance ne me fait pas peur, ${opponentNickname} !`,
            `Je vous ai laissé prospérer trop longtemps, ${opponentNickname}, c'est fini !`,
            `Je vous défie, ${opponentNickname}, de prouver votre valeur sur le champ de bataille !`,
            `C'est avec joie que je mettrai fin à votre règne, ${opponentNickname} !`,
        ];
        this.reasons = [
            "Je vient me venger pour votre trahison envers vos alliés.",
            "Votre peuple meurt de faim depuis bien trop longtemps et justice doit être faite.",
            "Il est temps de récuperer les ressources qui me reviennent de droit.",
            "Vos agissements méritent punition.",
            "J'ai goûté à la tarte à la rhubarbe de votre femme, elle était trop grasse.",
            "Il en va de mon honneur de vous affronter.",
            "Votre château est bien plus joli que le mien, je m'en vais le détruire.",
            `Votre royaume n'est rien d'autre qu'un tas de ruines !`,
            `Je vais libérer le peuple de votre tyrannie !`,
            `Votre armée est pathétique, ${opponentNickname}, elle ne fera pas le poids face à la mienne !`,
            `Votre royaume est un ramassis de lâches et de faibles !`,
            `Votre incompétence me dépasse !`,
            `Vous avez osé me défier, ${opponentNickname}, et vous allez le regretter !`,
            `Je suis venu rétablir l'ordre dans votre royaume chaotique !`,
            `Vous n'avez aucune chance de vaincre, ${opponentNickname}, abandonnez !`,
            `Je ne me reposerai pas avant de vous voir tombé !`,
            `Votre vanité vous perdra, ${opponentNickname}, car vous n'êtes rien face à moi !`,
            `J'apporterai la justice et la punition à votre peuple !`,
            `Préparez-vous à être anéanti, ${opponentNickname}, car je suis invincible !`,
            `Vous avez provoqué ma colère, ${opponentNickname}, et vous le paierez cher !`,
            "Vous avez causé trop de souffrance autour de vous.",
            "Votre quête de pouvoir est insatiable.",
            "Les dieux condamnent vos actions.",
            "Vos mensonges et votre fourberie ne resteront pas impunis.",
            "La noblesse de votre sang ne vous sauvera pas.",
        ];
        this.insults = [
            "infâmes traîtres.",
            "pitoyables lâches.",
            "vils ennemis.",
            "misérables vermines.",
            `Vos soldats sont des poules mouillées, ${opponentNickname} !`,
            `Votre courage est aussi grand que votre royaume, ${opponentNickname}... c'est-à-dire minuscule !`,
            `Vous n'avez même pas la force de tenir votre épée correctement !`,
            `Je vous écraserai comme un insecte !`,
            `Votre folie ne fait que commencer, ${opponentNickname}, et je vais en finir avec elle !`,
            `Votre armée est composée de misérables insectes !`,
            `Vous n'êtes qu'un pantin sans valeur !`,
            `Je vais réduire votre château en cendres, rien ne m'arrêtera !`,
            `Vos soldats ne sont que des pions sur mon échiquier !`,
            `Votre prétendue force ne m'impressionne pas !`,
            `Vous serez écrasé sous le poids de ma supériorité !`,
            `Vous n'êtes qu'un faible, incapable de me résister !`,
            `Je vais effacer votre nom de l'Histoire, personne ne se souviendra de vous !`,
            `Votre règne a été marqué par l'échec et l'incompétence, ${opponentNickname} !`,
            `Je vais transformer votre royaume en un champ de désolation, ${opponentNickname} !`,
            "pitoyable créature.",
            "fourbe manipulateur.",
            "tyran sans scrupules.",
            "infâme imposteur.",
            "vaniteux prétentieux.",
        ];
        this.conclusions = [
            "Notre victoire est assurée !",
            "Votre fin est proche.",
            "Pas de quartiers !",
            "A présent léchez les bottes de mes troupes.",
            "Regrettez votre arrogance.",
            "La gloire nous attend !",
            `Vos jours sont comptés, ${opponentNickname}, et bientôt vous serez à genoux devant moi !`,
            `Votre royaume ne sera bientôt plus qu'un lointain souvenir, ${opponentNickname} !`,
            `Les dieux eux-mêmes sont de mon côté, ${opponentNickname}, vous ne pouvez pas gagner !`,
            `Préparez-vous à goûter à la défaite, ${opponentNickname}, car elle est imminente !`,
            `Votre soif de pouvoir vous aveugle, ${opponentNickname}, et vous mènera à votre perte !`,
            `Vous ne méritez pas le titre de seigneur, ${opponentNickname}, vous n'êtes qu'un imposteur !`,
            `Vous n'êtes rien d'autre qu'une ombre face à ma grandeur, ${opponentNickname} !`,
            `La peur est votre seule alliée, ${opponentNickname}, et elle ne vous sauvera pas !`,
            `Votre royaume est voué à l'échec, ${opponentNickname}, car vous ne savez pas comment le diriger !`,
            `Je vais écraser tous ceux qui se mettront en travers de mon chemin, ${opponentNickname} !`,
            `Vous êtes un dirigeant incompétent, ${opponentNickname}, et votre chute est inévitable !`,
            `Votre règne de terreur touche à sa fin, ${opponentNickname}, et vous ne pourrez rien y faire !`,
            `Je vous défie, ${opponentNickname}, de me montrer votre vrai visage derrière votre masque !`,
            `Votre arrogance ne fera que hâter votre défaite, ${opponentNickname} !`,
            "La victoire est inéluctable !",
            "Votre règne prendra fin ici et maintenant.",
            "Aucune clémence ne vous sera accordée.",
            "Vous ne serez qu'un chapitre sombre dans l'histoire.",
            "Vos rêves de grandeur se briseront à mes pieds.",
            "Votre chute sera un avertissement pour les autres tyrans.",
            "Ma détermination sera votre perte.",
            "Votre royaume sera réduit en cendres, et vous avec.",
            "La gloire et l'honneur seront à moi.",
            "L'heure du jugement est venue.",
        ];
    }

    getRandomElement(array) {
        return array[Math.floor(Math.random() * array.length)];
    }

    generateRandomText() {
        const title = this.getRandomElement(this.titles);
        const reason = this.getRandomElement(this.reasons);
        const insult = this.getRandomElement(this.insults);
        const conclusion = this.getRandomElement(this.conclusions);

        const text = `<p>${title}</p>
        <p>${reason}</p>
        <p>${insult}</p>
        <p>${conclusion}</p>`;
        return text;
    }
}
