import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import './quill-editor.css'

// Composant pour les modules
export const QuillEditorModules = {
  modules: {
    // Configuration des modules selon vos besoins
    toolbar: [
      // options de la barre d'outils de l'éditeur
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ],
  },
};

// Composant pour les formats
export const QuillEditorFormats = {
  formats: [
    // Configuration des formats selon vos besoins
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
  ],
};

function QuillEditor() {
  return <ReactQuill modules={QuillEditorModules.modules} formats={QuillEditorFormats.formats} />;
}

export default QuillEditor;