import { create } from "zustand";
import backendRepository from "../repository/backend-repository";
import { ExpiredTokenException, UnreachableBackendException } from "../services/exceptions";
import useUiStore from "./use-ui-store";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const initialState = {
    token: null,
    refreshToken: null,
    player: null,
    isRefreshing: false,
    productions: [],
    playerConstructions: [],
    resourceStocks: [],
    playerSpells: [],
    activeEffects: [],
    troopsReturn: [],
    troopsReturnCount: 0,
    spyReturn: [],
    spyReturnCount: 0,
    orders: [],
    displayHelp: false,
    totalLands: 0,
}

const usePlayerStore = create((set, get) => ({
    ...initialState,

    setToken: (token) => {
        localStorage.setItem("token", token)
        set({ token: token })
    },
    setRefreshToken: (token) => {
        set({ refreshToken: token })
    },
    setPlayer: (player) => {
        set({ player })
    },
    setDisplayHelp: (value) => {
        set({ displayHelp: value })
    },
    logout: () => {
        set(initialState)
        localStorage.removeItem("token")
    },
    fetchOrders: async (force = false) => {
        if (force || (get().token && get().orders.length === 0)) {
            const orders = await backendRepository.getOrders()
            set({ orders: orders })
        }
    },

    fetchPlayer: async (force = false) => {
        if (force || (get().token && !get().player)) {
            const player = await backendRepository.getMe()
            set({ player: player })
        }

        return get().player
    },
    fetchPlayerProductions: async (force = false) => {
        if (force || (get().token && get().productions.length === 0)) {
            const playerProductions = await backendRepository.getPlayerProductions()
            set({ productions: playerProductions })
        }
    },
    fetchPlayerResources: async (force = false) => {
        if (force || (get().token && get().resourceStocks.length === 0)) {
            const resourceStocks = await backendRepository.getResourceStock()
            set({ resourceStocks })
        }
    },
    fetchPlayerSpells: async (force = false) => {
        if (force || (get().token && get().playerSpells.length === 0)) {
            const playerSpells = await backendRepository.getPlayerSpells()
            set({ playerSpells })
        }
    },
    fetchActiveEffects: async (force = false) => {
        if (force || (get().token && get().activeEffects.length === 0)) {
            const activeEffects = await backendRepository.getActiveEffects()
            set({ activeEffects })
        }
    },
    fetchPlayerConstructions: async (force = false) => {
        if (force || (get().token && get().playerConstructions.length === 0)) {
            const playerConstructions = await backendRepository.getPlayerConstructions()

            const totalLands = playerConstructions.map(pc => pc.quantity).reduce((acc, val) => acc + val, 0) + get().player.freeLand
            set({ playerConstructions, totalLands })
        }
    },
    fetchTroopsReturn: async (force = false) => {
        if (force || (get().token && get().troopsReturn.length === 0)) {
            const troopsReturn = await backendRepository.getTroopsReturn()
            const troopsReturnCount = troopsReturn.reduce((acc, e) => acc + e.soldierQuantity, 0)

            set({ troopsReturn, troopsReturnCount })
        }
    },
    fetchSpyReturn: async (force = false) => {
        if (force || (get().token && get().spyReturn.length === 0)) {
            const spyReturn = await backendRepository.getSpyReturn()
            const spyReturnCount = spyReturn.reduce((acc, e) => acc + e.spyQuantity, 0)

            set({ spyReturn, spyReturnCount })
        }
    },
    findResourceStockByResource(resourceId) {
        for (const stock of get().resourceStocks) {
            if (stock.resource.id === resourceId) {
                return stock
            }
        }
        return null
    },

    findResourceStockByResourceType(type) {
        for (const stock of get().resourceStocks) {
            if (stock.resource.type === type) {
                return stock
            }
        }
        return null
    },

    findProductionByResource(resourceId) {
        for (const production of get().productions) {
            if (production.resource.id === resourceId) {
                return production
            }
        }
        return null
    },

    refreshAccessToken: async () => {
        // If the access token is being refreshed, wait for new value
        if (get().isRefreshing) {
            return await new Promise(resolve => {
                const unsub = usePlayerStore.subscribe(() => {
                    if (!get().isRefreshing) {
                        if (get().token) {
                            unsub()
                            resolve(get().token)
                        }
                        resolve(null)
                    }
                })
            })
        }

        set({ isRefreshing: true })
        const refreshToken = get().refreshToken

        if (!refreshToken) {
            get().logout()
            return
        }

        // Refresh the token
        let response
        try {
            response = await backendRepository.fetchAccessFromRefresh(refreshToken)
        } catch (e) {
            if (e.response && e.response.status === 404) {
                get().logout()
                return
            }
            throw new UnreachableBackendException()
        }

        // If it does not work, throw expired token exception
        if (response.status !== 201) {
            get().logout()
            throw new ExpiredTokenException()
        }

        // Add token to data store
        localStorage.setItem("token", response.data.token)
        set({ isRefreshing: false, token: response.data.token })
    },
    restoreFromGlobals: () => {
        const token = localStorage.getItem('token');
        if (token) {
            // Restaurer le state du token à partir du localStorage
            set({ token: token })
        }

        const refreshToken = getCookie("Refresh-Token")
        if (refreshToken) {
            set({ refreshToken })
        }
    },


    reloadPlayerData: () => {
        return Promise.all([
            get().fetchPlayer(true),
            get().fetchPlayerProductions(true),
            get().fetchPlayerResources(true),
            get().fetchPlayerConstructions(true),
            get().fetchPlayerSpells(true),
            get().fetchActiveEffects(true),
            get().fetchTroopsReturn(true),
            get().fetchSpyReturn(true),
            get().fetchOrders(true),
            useUiStore.getState().fetchNotifications()
        ])
    },
}))

export default usePlayerStore