import "./citizen-upgrade-card.scss"
import { useEffect, useRef, useState } from "react";
import Game from "../../assets/game/game";
import {
    Button,
    ButtonGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import usePlayerStore from "../../store/use-player-store";
import SacrifyCitizenInput from './sacrify-citizen-input';
import UpgradeCitizenInput from "./upgrade-citizen-input";
import CitizenCost from './citizen-cost'
import { thDelimiter } from "../../services/utils";

function CitizenUpgradeCard({ citizen, onUpgrade = () => { }, onSacrify = () => { } }) {

    //find the cost of citizen upgrade into the files GAME
    const gameData = Game.CITIZEN_UPGRADE;

    const [, forceUpdate] = useState()
    const [quantity, setQuantity] = useState(0)
    const villagerCost = useRef(gameData.ALL.QUANTITY)
    const computedVillagerCost = useRef(gameData.ALL.QUANTITY)
    const resourceCost = useRef([])
    const computedResourceCost = useRef([])
    const resourceCostName = useRef([])
    const resourceCostType = useRef([])
    const villagerStock = useRef(null)
    const resourceStock = useRef(null)
    const stocksExceeded = useRef([false, false])
    const [displaySacrify, setDisplaySacrify] = useState(false)
    const troopsReturnCount = usePlayerStore(state => state.troopsReturnCount)
    const fetchTroopsReturn = usePlayerStore(state => state.fetchTroopsReturn)

    const findResourceStockByResourceType = usePlayerStore(state => state.findResourceStockByResourceType)
    const resourceStocks = usePlayerStore(state => state.resourceStocks)


    //modify quantity of the target before submit
    const handleQuantityChange = (event) => {
        event.preventDefault();
        const newQuantity = parseInt(event.target.value)
        computeStockExceeded(newQuantity || 0)
        setQuantity(newQuantity)
    };

    //send the order with onUpgrade
    const handleSubmit = (event) => {
        event.preventDefault()
        onUpgrade(citizen.resource.name, citizen.resource.type, quantity)
        computeStockExceeded(quantity)
        setQuantity(0)
    }

    const handleDisabled = () => {
        return !(Number.isInteger(quantity) && quantity > 0)
            || (stocksExceeded.current.reduce((total, current) => total + (current ? 1 : 0), 0) > 0)
    }

    const handleMax = () => {
        const newQuantity = Math.min(
            Math.floor(villagerStock.current.quantity / villagerCost.current),
            ...resourceStock.current.map((e, index) => {
                return Math.floor(e.quantity / resourceCost.current[index]);
            }),
            Math.max(citizen.maxResourcesAllowed - troopsReturnCount - citizen.quantity, 0)
        );
        computeStockExceeded(newQuantity)
        setQuantity(newQuantity)
    }
    const handleReset = () => {
        computeStockExceeded(0)
        setQuantity(0)
    }

    const computeStockExceeded = (quantity) => {

        computedVillagerCost.current = villagerCost.current * (quantity || 1)
        computedResourceCost.current = resourceCost.current.map(cost => cost * (quantity || 1))
        stocksExceeded.current = []
        stocksExceeded.current.push(computedVillagerCost.current > villagerStock.current.quantity)
        computedResourceCost.current.map((object, index) => {
            let quantity = resourceStock.current[index].quantity

            /*if (resourceStock.current[index].resource.type === "soldier") {
                quantity += troopsReturn.reduce((acc, e) => acc + e.soldierQuantity, 0)
            }*/
            return stocksExceeded.current.push(object > quantity)
        })
    }

    useEffect(() => {
        fetchTroopsReturn()

        villagerCost.current = gameData.ALL.QUANTITY
        computedResourceCost.current = gameData.ALL.QUANTITY
        resourceCostName.current = gameData.ALL.RESOURCE
        resourceCostType.current = gameData.ALL.TYPE
        if (citizen.resource.type === 'villager') {
            setDisplaySacrify(true)
        }
        else if (citizen.resource.type === "soldier") {
            resourceCost.current = gameData.SOLDIER.map(item => item.QUANTITY);
            computedResourceCost.current = gameData.SOLDIER.map(item => item.QUANTITY);
            resourceCostName.current = gameData.SOLDIER.map(item => item.RESOURCE);
            resourceCostType.current = gameData.SOLDIER.map(item => item.TYPE);
        } else if (citizen.resource.type === "spy") {
            resourceCost.current = gameData.SPY.map(item => item.QUANTITY);
            computedResourceCost.current = gameData.SPY.map(item => item.QUANTITY);
            resourceCostName.current = gameData.SPY.map(item => item.RESOURCE);
            resourceCostType.current = gameData.SPY.map(item => item.TYPE);
        } else if (citizen.resource.type === "wizard") {
            resourceCost.current = gameData.WIZARD.map(item => item.QUANTITY);
            computedResourceCost.current = gameData.WIZARD.map(item => item.QUANTITY);
            resourceCostName.current = gameData.WIZARD.map(item => item.RESOURCE);
            resourceCostType.current = gameData.WIZARD.map(item => item.TYPE);
        }

        villagerStock.current = findResourceStockByResourceType(gameData.ALL.TYPE)
        if (Array.isArray(resourceCostType.current)) {
            resourceStock.current = resourceCostType.current.map(resourceType => findResourceStockByResourceType(resourceType));
        }

        computeStockExceeded(0)
        forceUpdate({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceStocks])

    const toggleDisplaySacrify = () => {
        setDisplaySacrify(!displaySacrify);
    };

    return (
        <div className="action-card">
            <CitizenCost
                citizen={citizen}
                stocksExceeded={stocksExceeded.current}
                computedVillagerCost={computedVillagerCost.current}
                computedResourceCost={computedResourceCost.current}
                resourceCostName={resourceCostName.current}
            // maxQuantity={handleMax()}
            />
            <form className='action-form'>
                <p className='action-description'>
                    {citizen.resource.description}
                </p>
                <TableContainer >
                    <Table size={"small"}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Effectifs actuels</TableCell>
                                <TableCell>{thDelimiter(citizen.quantity)}</TableCell>
                            </TableRow>
                            {citizen.resource.type !== "villager" && (
                                <TableRow>
                                    <TableCell>Effectifs apres formation</TableCell>
                                    <TableCell><span className={!isNaN(quantity) && quantity > 0 ? "strong" : ""}>{thDelimiter(citizen.quantity + (quantity || 0))}</span></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {citizen.resource.type !== "villager" && (
                    <UpgradeCitizenInput
                        citizen={citizen}
                        quantity={quantity}
                        handleQuantityChange={handleQuantityChange}
                        handleMax={handleMax}
                        handleReset={handleReset}
                        handleSubmit={handleSubmit}
                        handleDisabled={handleDisabled}
                        displaySacrify={displaySacrify}
                        toggleDisplaySacrify={toggleDisplaySacrify}
                    />
                )}
                {displaySacrify && <SacrifyCitizenInput
                    citizen={citizen}
                    onSacrify={onSacrify}
                />}
            </form>
        </div>
    );
}

export default CitizenUpgradeCard;