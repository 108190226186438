import {
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import ContentBox from "../../layout/content-box/content-box";
import ConquestForChip from "../../components/conquest-for-chip/conquest-for-chip";
import SpyingForChip from "../../components/spying-for-chip/spying-for-chip";
import moment from "moment";

function FeedItemDialog({ element, type, open, onClose = () => { } }) {
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (type === "conquest") {
            setTitle("Conquete")
            setContent(<>
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Attaquant</TableCell>
                                <TableCell>{element.attackerNickname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Défenseur</TableCell>
                                <TableCell>{element.defenderNickname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Conquete pour</TableCell>
                                <TableCell><ConquestForChip conquestType={element.conquestType} detailed /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Date de la conquete</TableCell>
                                <TableCell>{moment(element.createdAt).format("YYYY/MM/DD HH:mm:SS")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <ContentBox header="Raison de l'attaque">
                    <div dangerouslySetInnerHTML={{ __html: element.roleplay }} />
                </ContentBox>
            </>)
        } if (type === "spying") {
            setTitle("Espionnage")
            setContent(<>
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Attaquant</TableCell>
                                <TableCell>{element.attackerNickname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Défenseur</TableCell>
                                <TableCell>{element.defenderNickname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Espionnage pour</TableCell>
                                <TableCell><SpyingForChip spyType={element.spyType} detailed /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Date de l'espionnage</TableCell>
                                <TableCell>{moment(element.createdAt).format("YYYY/MM/DD HH:mm:SS")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>)
        }
    }, [type, element])

    return <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
    </Dialog>
}

export default FeedItemDialog