import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify'
import usePlayerStore from "../../store/use-player-store";
import backendRepository from "../../repository/backend-repository";
import {
    Skeleton,
    TextField,
    Button,
    ButtonGroup,
    TableContainer,
    Table,
    TableCell,
    TableRow, TableBody, TableHead
} from "@mui/material";
import Game from "../../assets/game/game";
import game from "../../assets/game/game";
import "./exploration-card.scss"
import ResourceIcon from "../resource-icon/resource-icon";
import { thDelimiter } from "../../services/utils";

function ExplorationCard() {
    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)
    const player = usePlayerStore(state => state.player)
    const resourceStocks = usePlayerStore(state => state.resourceStocks)
    const fetchPlayerConstructions = usePlayerStore(state => state.fetchPlayerConstructions)
    const fetchPlayerProductions = usePlayerStore(state => state.fetchPlayerProductions)
    const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)

    const [quantity, setQuantity] = useState(0)
    const [exceedCost, setExceedCost] = useState(false)
    const [explorationBonus, setExplorationBonus] = useState(0)
    const [formSent, setFormSent] = useState(false)
    const updatedMatchingStock = useRef([]);

    const explorationCost = Game.EXPLORATION_COST

    function computeCostExceeded(newQuantity, force = false) {
        if (!force && newQuantity === 0) {
            setExceedCost(false)
            return
        }

        updatedMatchingStock.current = []
        if (newQuantity === 0 || isNaN(newQuantity) || newQuantity === '') {
            newQuantity = 1
        }

        setExceedCost(false)
        for (const cost of explorationCost) {
            const stock = resourceStocks.find(
                (stock) => stock.resource.type === cost.type
            );

            //if (stock && computeExplorationCost(totalLands, newQuantity, stock.resource.type) > stock.quantity) {
            if (stock && computeExplorationCost(player.totalLands, newQuantity, stock.resource.type) > stock.quantity) {
                setExceedCost(true)
                updatedMatchingStock.current.push(true);
            } else {
                updatedMatchingStock.current.push(false);
            }
        }

    }

    const computeExplorationCost = (totalLands, quantity, resourceType) => {
        return Math.floor(game.COMPUTE_EXPLORATION_COST(totalLands, quantity, resourceType) * (1 + explorationBonus))
    }

    const computeCost = (cost) => {
        return Math.floor(game.COMPUTE_EXPLORATION_COST(player.totalLands, !quantity ? 1 : quantity, cost.type) * (1 + explorationBonus))
    }

    useEffect(() => {
        fetchPlayerConstructions()
        fetchPlayerResources()
        fetchPlayerProductions()

        backendRepository.getExplorationBonus().then(v => {
            setExplorationBonus(- (1 - v))
        })
    }, [fetchPlayerConstructions, fetchPlayerProductions, fetchPlayerResources])


    async function handleExplorationOrder(event) {
        event.preventDefault();
        setFormSent(true)

        backendRepository.createExplorationOrder(quantity).then(() => {
            reloadPlayerData()
            toast.success(`Vous lancez une exploration de ${quantity} terres`)
            setQuantity(0)
            computeCostExceeded(0)
        }).catch(error => {
            if (error.response.data.type === "not_enough_villager") {
                toast.error("Pas assez de villageois")
            }
            else if (error.response.data.type === "not_enough_food") {
                toast.error("Pas assez de nourriture")
            } else {
                toast.error(error.response.data.type)
            }
        }).finally(() => {
            setFormSent(false)
        })
    }

    const handleQuantityChange = (event) => {
        event.preventDefault();
        let newQuantity = parseInt(event.target.value);
        if (newQuantity > -1 || newQuantity === '' || isNaN(newQuantity)) {
            computeCostExceeded(newQuantity);
            setQuantity(newQuantity);
        }
    };

    const handleMax = event => {
        let max = null
        for (const cost of explorationCost) {
            const stock = resourceStocks.find(
                (stock) => stock.resource.type === cost.type
            );

            const localMax = Math.floor(game.COMPUTE_REVERSE_EXPLORATION_COST(cost.type, stock.quantity, player.totalLands) / (1 + explorationBonus))
            if ((null === max || localMax < max) && localMax !== Infinity) {
                max = localMax
            }
        }

        computeCostExceeded(max ?? 0, true)
        setQuantity(max ?? 0)
    }

    const handleReset = () => {
        setQuantity(0)
        computeCostExceeded(0)
    }

    const computeBonusClass = () => {
        const classes = ["strong"]

        if (explorationBonus > 0) {
            classes.push("text-danger")
        }

        if (explorationBonus < 0) {
            classes.push("text-success")
        }

        return classes.join(" ")
    }

    return (
        <>
            {player === null ?
                <Skeleton animation="wave" height={500} />
                :
                <div className="action-container">
                    <div className="action-card action-card-single">
                        <div className={`banner banner-single banner-exploration`}>
                            <div className="action-title action-title-longer">
                                <div className={"mb-2 strong"}>Coût :</div>
                                <ul className='action-cost'>
                                    {explorationCost.map((cost, j) => (
                                        <li key={j} className={exceedCost && updatedMatchingStock.current[j] ? "exceedCost" : ""}>
                                            <span className={"flex flex-row items-center"}>
                                                <ResourceIcon className={"mr-2"} type={cost.type} /> {thDelimiter(computeCost(cost))}{" "}{cost.name}
                                            </span>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="exploration-table">
                            <TableContainer className={"mb-8"}>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Avant exploration</TableCell>
                                            <TableCell>Apres exploration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Nombre de terrains constructibles</TableCell>
                                            <TableCell>{player && player.freeLand}</TableCell>
                                            <TableCell>{player && (quantity ? player.freeLand + quantity : player.freeLand)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Nombre de terres total</TableCell>
                                            <TableCell>{player && player.totalLands}</TableCell>
                                            <TableCell>{player && quantity ? (player.totalLands + quantity) : player.totalLands}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Bonus de cout d'exploration</TableCell>
                                            <TableCell colSpan={2}>
                                                <span className={computeBonusClass()}>
                                                    {explorationBonus > 0 ? "+" : ""}{(explorationBonus * 100).toFixed(2)}%
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <form className='action-form' >
                                <div className='action-input-container'>
                                    <TextField
                                        size={"small"}
                                        className='action-input'
                                        type="number"
                                        value={isNaN(quantity) ? '' : quantity}
                                        onChange={(event) => handleQuantityChange(event)} />

                                    <ButtonGroup variant={"outlined"}>
                                        <Button
                                            onClick={handleMax}>
                                            Max
                                        </Button>
                                        <Button
                                            onClick={handleReset}>
                                            Reset
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color={"secondary"}
                                            disabled={formSent || exceedCost || quantity === 0 || isNaN(quantity) || quantity === ''}
                                            onClick={handleExplorationOrder}>
                                            Explorer
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ExplorationCard;