export function PhoenixTailSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Queue de Phoenix qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Queue de Phoenix qui protège vos soldats à hauteur de <strong>{(notification.body.spell.effect.soldier_saved_ratio * 100).toFixed(2)}%</strong> en cas de défense.
    </div>
}

export function PhoenixTailSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Les soldats de {spellCast.defender.nickname} sont protégés à hauteur de {(spellCast.effect.soldier_saved_ratio * 100).toFixed(2)}% en cas de défense</span>
    }
    return <span>Soldats protégés à hauteur de {(spellCast.effect.soldier_saved_ratio * 100).toFixed(2)}% sur défense</span>
}