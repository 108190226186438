export function DedaleMapNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Carte de dédale, mais il a <strong>échoué</strong>.
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Carte de dédale, qui diminue le cout de l'exploration de <strong>{(notification.body.spell.effect.explorationBonus * 100).toFixed(2)}%</strong>.
    </div>
}

export function DedaleSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Le cout de l'exploration de {spellCast.defender.nickname} est diminué de {(spellCast.effect.explorationBonus * 100).toFixed(2)}%</span>
    }
    return <span>Diminution du cout de l'exploration de {(spellCast.effect.explorationBonus * 100).toFixed(2)}%</span>
}
