import PageTitle from '../../layout/page-title/page-title';
import RankingArray from '../../components/ranking-array/ranking-array'
import HelperPane from "../../layout/helper-pane/helper-pane";

function Ranking() {
  return (
    <>
      <PageTitle>Classement</PageTitle>
      <HelperPane linkTo={"ranking"}>
        <p>Vous trouverez une liste de tous les joueurs.</p>
        <p>Vous pouvez trier le tableau par ordre alphabétique, nombre total de terres, nombre de terres conquises et ratio defensif/offensif.
          En cliquant sur un joueur, vous aurez accès à ses informations.
        </p>
      </HelperPane>
      <RankingArray />
    </>
  )
}

export default Ranking;