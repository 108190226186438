

export function computeSuccessLevel(player, spying, successLevel) {
    let result;
    let iconColor;
    if (player.id === spying.attackerId) {
        switch (successLevel) {
            case 4:
                result = <span className="victory-text">Mission parfaite</span>
                iconColor = 'victory-text'
                break;
            case 3:
                result = <span className="victory-text">Mission reussie</span>
                iconColor = 'victory-text'
                break;
            case 2:
                result = <span className="defeat-text">Mission abordée</span>
                iconColor = 'defeat-text'
                break;
            case 1:
                result = <span className="defeat-text">Mission echouée</span>
                iconColor = 'defeat-text'
                break;
            default:
                return null
        }
    } else if (player.id !== spying.attackerId) {
        switch (successLevel) {
            case 4:
                result = <span className="defeat-text">Mission parfaite</span>
                iconColor = 'defeat-text'
                break;
            case 3:
                result = <span className="defeat-text">Mission reussie</span>
                iconColor = 'defeat-text'
                break;
            case 2:
                result = <span className="victory-text">Mission abordée</span>
                iconColor = 'victory-text'

                break;
            case 1:
                result = <span className="victory-text">Mission echouée</span>
                iconColor = 'victory-text'

                break;
            default:
                return null
        }
    }
    return { result, iconColor }
}

