import {KeyboardArrowUp} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import "./scroll-to-top-button.scss"
import {useEffect, useState} from "react";

const scrollTop = 200

function ScrollToTopButton() {
    const [displayBtn, setDisplayBtn] = useState(window.scrollY > scrollTop)

    const handleToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const computeClasses = () => {
        const classes = ['scroll-to-top-btn']

        if (! displayBtn) {
            classes.push("scroll-to-top-btn-hidden")
        }

        return classes.join(" ")
    }

    useEffect(() => {
        const onScroll = e => {
            if (!displayBtn && e.target.documentElement.scrollTop > scrollTop) {
                setDisplayBtn(true)
            }

            if (displayBtn && e.target.documentElement.scrollTop <= scrollTop) {
                setDisplayBtn(false)
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [displayBtn])

    return <div className={computeClasses()}>
        <IconButton onClick={handleToTop} color={"primary"}>
            <KeyboardArrowUp fontSize={"large"}/>
        </IconButton>
    </div>
}

export default ScrollToTopButton