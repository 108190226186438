import axios from "axios";
import usePlayerStore from "../store/use-player-store";
import { UnreachableBackendException } from "../services/exceptions";

class BackendRepository {
    /**
     * @type {axios.AxiosInstance}
     */
    httpClient = null

    constructor(httpClient) {
        this.token = localStorage.getItem("token")
        this.httpClient = httpClient
    }

    getAuthHeader() {
        const token = usePlayerStore.getState().token
        return { Authorization: `Bearer ${token}` }
    }

    async login({ email, password }) {
        const response = await this.httpClient.post(`/login`, { email, password })
        return response.data
    }

    async fetchAccessFromRefresh(refreshToken) {
        return await this.httpClient.post("/refresh-token", { refreshToken: refreshToken })
    }

    async subscribe(player) {
        const response = await this.httpClient.post(`/subscribe`, player);
        return response.data;
    }

    async updatePlayer(background, age, alliance) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            background,
            age,
            alliance
        };
        const response = await this.httpClient.put(
            '/update-player',
            data,
            config
        );
        return response.data;
    }

    async deletePlayer() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.delete(`/players/me`, config);
        return response.data;
    }

    async getConstructionCost() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(`/constructions/costs`, config);
        return response.data;
    }

    async getMe() {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/players/me`,
            config
        );
        return response.data;
    }

    async getPlayer(id) {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/players/${id}`,
            config
        );
        return response.data;
    }

    async getAllPlayers(sortedQuery, pages) {
        const config = {
            headers: this.getAuthHeader(),
            params: {}
        };
        config.params.page = pages

        if (sortedQuery) {
            config.params[sortedQuery] = true;
        }
        const response = await this.httpClient.get(
            `/players/`,
            config
        );
        return response.data;
    }

    async getConquestHistory(page, length, role) {
        const config = {
            headers: this.getAuthHeader(),
            params: {
                page: page,
                length: length,
            },
        };

        if (role) {
            config.params[role] = true;
        }

        const response = await this.httpClient.get(`/conquests/me`, config);
        return response.data;
    }

    async getLatestConquestByPlayer(defender) {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(`/conquests/defender/${defender.id}`, config);
        return response.data;
    }

    async getPlayerProductions() {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/productions`,
            config
        );
        return response.data;
    }

    async getResourceStock() {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/resource-stocks`,
            config
        );
        return response.data;
    }

    async getPlayerConstructions() {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/player-constructions`,
            config
        );
        return response.data;
    }

    async getPlayerSpells() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/spells`,
            config
        );
        return response.data;
    }
    async getActiveEffects() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/spells/active`,
            config
        );
        return response.data;
    }
    async getSpellCastsHistory(page, length, role) {
        const config = {
            headers: this.getAuthHeader(),
            params: {
                page: page,
                length: length,
            },
        };

        if (role) {
            config.params[role] = true;
        }
        const response = await this.httpClient.get(`/casts/me`, config);
        return response.data;
    }

    async getConquest(id) {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/conquests/${id}`,
            config
        );
        return response.data;
    }

    async createConquest(soldierMobilize, roleplay, target, conquestType) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "soldierQuantity": parseInt(soldierMobilize),
            "roleplay": roleplay,
            conquestType,
        };
        const response = await this.httpClient.post(
            `/players/${target.id}/conquests`,
            data,
            config
        );
        return response.data;
    }

    async createSpying(spyQuantity, playerToSpy, spyType) {
        const config = {
            headers: this.getAuthHeader(),
        }
        const data = {
            "spyQuantity": parseInt(spyQuantity),
            spyType,
        };
        const response = await this.httpClient.post(
            `/players/${playerToSpy.id}/spying`,
            data,
            config
        );
        return response.data
    }

    async getSpying(id) {
        const config = {
            headers: this.getAuthHeader(),
        };

        const response = await this.httpClient.get(
            `/spyings/${id}`,
            config
        );
        return response.data;
    }
    async getSpyingHistory(page, length, role) {
        const config = {
            headers: this.getAuthHeader(),
            params: {
                page: page,
                length: length,
            },
        };

        if (role) {
            config.params[role] = true;
        }

        const response = await this.httpClient.get(`/spyings/me`, config);
        return response.data;
    }

    async createConstructionOrder(constructionId, quantity) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "quantity": parseInt(quantity)
        };
        const response = await this.httpClient.post(
            `/constructions/${constructionId}/construction-orders`,
            data,
            config
        );
        return response.data;
    }

    async destroyConstruction(constructionId, quantity) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "quantity": parseInt(quantity)
        };
        const response = await this.httpClient.put(
            `/player-constructions/${constructionId}/destroy`,
            data,
            config
        );
        return response.data;
    }
    async sacrifyCitizen(ressourceStockId, quantity) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "quantity": parseInt(quantity)
        };
        const response = await this.httpClient.put(
            `/resource-stock/${ressourceStockId}/sacrify-citizen`,
            data,
            config
        );
        return response.data;
    }

    async createExplorationOrder(quantity) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "quantity": parseInt(quantity)
        };
        const response = await this.httpClient.post(`/exploration-orders`, data, config);
        return response.data;
    }

    async getExplorationBonus() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get("/exploration/bonus", config);
        return response.data.explorationBonus;
    }

    async createUpgradeVillagerOrder(quantity, citizenType) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const data = {
            "quantity": quantity,
            "targetType": citizenType
        };
        const response = await this.httpClient.post(`/upgrade-villager-orders`, data, config);
        return response.data;
    }

    async searchUsersByLands(query, page, allowSelf = false) {
        const config = {
            headers: this.getAuthHeader(),
        };

        let url = `/players/search?lands-proximity&page=${page}`
        if (allowSelf) {
            url = `${url}&allow-self`
        }
        const response = await this.httpClient.post(
            url,
            { query },
            config,
        );

        return response.data
    }

    async getOrders() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/orders/me`,
            config,
        );

        return response.data
    }

    async getChangelog() {
        const response = await this.httpClient.get("/changelog")
        return response.data
    }

    async getNotifications(lastId = null) {
        const config = {
            headers: this.getAuthHeader(),
        };

        let url = `/notifications/me`

        if (lastId !== null) {
            url = `${url}?last-id=${lastId}`
        }
        const response = await this.httpClient.get(
            url,
            config,
        );

        return response.data
    }

    async markNotificationAsRead(notification) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.post(
            `/notifications/${notification.id}/read`,
            {},
            config,
        );

        return response.data
    }

    async markAllNotificationsAsRead() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.post(
            `/notifications/all/read`,
            config,
        );

        return response.data
    }

    async deleteNotifications() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.delete(
            `/notifications/me`,
            config,
        );

        return response.data
    }

    async castSpell(spell, target) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.post(
            `/players/${target.id}/casts`,
            {
                type: spell.type
            },
            config
        );

        return response.data
    }

    async getSpellCast(id) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/spell-casts/${id}`,
            config
        );

        return response.data
    }

    async getFeed({ page, length }) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/feeds?page=${page}`,
            config
        );

        return response.data
    }

    async getTroopsReturn() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/conquests/troops-return`,
            config
        );

        return response.data
    }
    async getSpyReturn() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/spyings/spy-return`,
            config
        );

        return response.data
    }

    async getMarketResources() {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.get(
            `/market/summary`,
            config
        );

        return response.data
    }

    async exchangeMarketResources(fromResource, toResource, quantity) {
        const config = {
            headers: this.getAuthHeader(),
        };
        const response = await this.httpClient.post(
            `/market/resources/${fromResource.id}/${toResource.id}/exchange`,
            {
                quantity
            },
            config
        );

        return response.data
    }
}

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
})

const NOT_INTERCEPTED_ROUTES = ["/login", "/refresh-token"]

httpClient.interceptors.response.use((res) => {
    return res
}, async (error) => {
    const config = error.config
    // If request isn't 401 or login route reject normally
    if (!config || NOT_INTERCEPTED_ROUTES.includes(config.url) || error.response === undefined || error.response.status !== 401) {
        return Promise.reject(error)
    }

    try {
        await usePlayerStore.getState().refreshAccessToken()
    } catch (e) {
        return Promise.reject(error)
    }

    if (null === usePlayerStore.getState().token) {
        return Promise.reject(error)
    }

    // Retry original request
    const originalRequestConfig = error.config
    originalRequestConfig.headers["Authorization"] = `Bearer ${usePlayerStore.getState().token}`
    return await axios.request(originalRequestConfig)
})

const repo = new BackendRepository(httpClient)
export default repo