import PageTitle from "../../layout/page-title/page-title"
import ConquestHistoryList from "../../components/conquest-history-list/conquest-history-list"
import ".//conquest-history.scss"
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import HelperPane from "../../layout/helper-pane/helper-pane";

function ConquestHistory() {
    return (
        <>
            <Button component={Link} to={"/conquest"}>Conquérir</Button>
            <PageTitle>Historique des conquetes</PageTitle>
            <HelperPane linkTo={"conquest"}>
                <p>
                    Vous trouverez ici l'historique de vos conquêtes passées. Vous pouvez utiliser le filtre pour afficher uniquement
                    les conquêtes en tant qu'attaquant ou en tant que défenseur.
                </p>

            </HelperPane>

            <ConquestHistoryList />
        </>
    )
}

export default ConquestHistory