import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import "./construction-tile.scss"
import {thDelimiter} from "../../services/utils";


function ConstructionCost({ playerConstruction }) {


    return (
        <>
            <div className="flex flex-center">
                <h3>Cout d'une construction</h3>
            </div>
            <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }} >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ressources nécessaires</TableCell>
                            <TableCell>Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {playerConstruction.construction.costs.map((cost) => (
                            <TableRow key={cost.id}>
                                <TableCell sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={require(`../../assets/img/icon/${cost.resourceType}.webp`)}
                                        className="ressource-icon mr-5 radius-100"
                                        alt="resoure-icon"
                                        rel="preload"
                                        as="image"
                                    />
                                    {cost.resource}
                                </TableCell>

                                <TableCell>{thDelimiter(cost.quantity)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ConstructionCost