import { Button } from '@mui/material';
import "../../index.scss"

const PaginationFilter = ({ onRoleChange, filters = {} }) => {

    const handleRoleChange = (event) => {
        onRoleChange(event.target.value)
    }

    return (
        <div className='flex flex-row text-center w-full'>
            {Object.keys(filters).map((key) => {
                return <Button key={key} onClick={handleRoleChange} value={key}>{filters[key]}</Button>
            })}
        </div>
    );
};

export default PaginationFilter;
