import {Box, LinearProgress, Typography} from "@mui/material";
import usePlayerStore from "../../store/use-player-store";

function ManaProgress() {
    const player = usePlayerStore(state => state.player)
    const value = (player.mana / player.maxMana) * 100

    return <Box sx={{ display: 'flex', alignItems: 'center', position: "relative" }}>
        <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{height: 15, borderRadius: "5px"}} color={"secondary"} value={value} variant={"determinate"} />
        </Box>
        <Box sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Typography variant={"body"} color="white">{player.mana} / {player.maxMana}</Typography>
        </Box>
    </Box>
}

export default ManaProgress