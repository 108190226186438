export function SwiftRepatriationNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Pieds Legers qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Pieds Legers qui diminue le temps de retour des troupes de <strong>{notification.body.spell.effect.reducedMin.toFixed(1)} minutes</strong>.
    </div>
}

export function SwiftRepatriationSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Les troupes de {spellCast.defender.nickname} rentrerons de conquetes {spellCast.effect.reducedMin.toFixed(1)} minutes plus tôt</span>
    }
    return <span>Diminution du temps de retour de troupes de {spellCast.effect.reducedMin.toFixed(1)} minutes</span>
}