export const MOMENT_FORMAT_HM = "YYYY/MM/DD HH:mm"

export function htmlToText(rawHtml) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = rawHtml;
    return tmp.textContent || tmp.innerText;

}

export function thDelimiter(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00a0");
}

export function addQueryParam(name, value, force = true) {
    const url = new URL(window.location.href);
    const currentValue = force ? value : (url.searchParams.has(name) ? url.searchParams.get(name) : value)
    url.searchParams.set(name, currentValue);
    window.history.pushState(
        {},
        "",
        `${window.location.pathname}${url.search}`
    );

    return currentValue
}

