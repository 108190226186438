import { useState } from 'react';
import React from "react"
import './conquest.scss'
import ConquestCard from '../../components/conquest-card/conquest-card'
import SearchPlayersByLands from '../../components/search-players-by-lands/search-players-by-lands'
import PageTitle from '../../layout/page-title/page-title';
import HelperPane from '../../layout/helper-pane/helper-pane';
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import ConquestSummary from "./conquest-summary";
import { toast } from "react-toastify";
import backendRepository from "../../repository/backend-repository";
import { useNavigate } from "react-router-dom";
import usePlayerStore from "../../store/use-player-store";
import ContentPane from '../../layout/content-pane/content-pane';

const steps = ['Choisissez un joueur à attaquer', 'Planifiez votre attaque', "Résumé"]
function Conquest() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [conquestValidity, setConquestValidity] = React.useState(false);
    const [playerToAttack, setPlayerToAttack] = useState(null)
    const [soldierQuantity, setSoldierQuantity] = useState(0)
    const [politicalIssue, setPoliticalIssue] = useState("")
    const [conquestType, setConquestType] = useState(null)
    const [validPlayerToAttack, setValidPlayerToAttack] = useState(false)
    const [latestConquests, setLatestConquests] = useState([])
    const [tooLowFreeLands, setTooLowFreeLands] = useState(false)
    const navigate = useNavigate()
    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSoldierQuantity(null)
        setPoliticalIssue("")
        setPlayerToAttack(null)
        setActiveStep(0);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleConfirm = (event) => {
        event.preventDefault();
        toast.info(
            <div>
                <p>Voulez-vous vous attaquer {playerToAttack.nickname} avec {soldierQuantity} soldats ?</p>
                <div className='toastify-button-container'>
                    <Button className='button-hamburger' onClick={handleConquest}>Oui</Button>
                    <Button className='button-hamburger' onClick={handleCancel}>Non</Button>
                </div>
            </div>,
            {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: false,
                closeOnClick: true,
                closeButton: false
            }
        );
    };

    async function handleConquest(event) {

        event.preventDefault()

        try {
            const conquest = await backendRepository.createConquest(soldierQuantity, politicalIssue, playerToAttack, conquestType)
            reloadPlayerData()
            toast.success(`Vous attaquez ${playerToAttack.nickname}`)
            setTimeout(() => {
                navigate(`/conquests/${conquest.id}`);
            }, 2000);

        } catch (error) {
            toast.error('la conquete n\'a pas fonctionnée')
            console.log(error)
        }
    }

    const handleCancel = (event) => {
        event.preventDefault()
    }

    const handlePlayerSelected = (defender) => {
        setValidPlayerToAttack(false)
        setPlayerToAttack(defender)

        backendRepository.getLatestConquestByPlayer(defender).then(({ conquests, canAttack }) => {
            if (canAttack && conquests.length < 3) {
                setValidPlayerToAttack(true)
            }
            setLatestConquests(conquests)
            setTooLowFreeLands(!canAttack)
        })
    }

    // Compute if next step is available
    let canNext = true;
    if (activeStep === 0 && !validPlayerToAttack) {
        canNext = false
    }
    if (activeStep === 1 && !conquestValidity) {
        canNext = false
    }

    return <>
        <div className='conquest-container'>
            <PageTitle>Conquete</PageTitle>
            <HelperPane linkTo={"conquest"}>
                <p>
                    Attaquez un autre joueur et réclamez ses terres. Choisissez une cible et mobilisez vos troupes.
                    Si vous possédez plus de force militaire que votre adversaire, vous lui subtiliserez ses terres.
                    En cas d'échec, vous ne gagnerez rien et perdrez une grosse partie de vos soldats mobilisés.
                </p>
                <p>
                    L'honneur vous oblige à attaquer un seigneur possédant un royaume de taille équivalente,
                    Ci-dessous, se trouvent uniquement les cibles que vous pouvez attaquer.
                    Attention, votre cible sera avertie de votre attaque.
                </p>
            </HelperPane>

            <ContentPane>
                <Box>
                    <Stepper>
                        {steps.map((label, index) => {
                            const stepProps = {
                                active: index === activeStep
                            };
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {activeStep === 0 && <SearchPlayersByLands
                                lastConquests={latestConquests}
                                player={playerToAttack}
                                onPlayerSelected={handlePlayerSelected}
                                tooLowFreeLand={tooLowFreeLands}
                            />}
                            {activeStep === 1 && <ConquestCard
                                soldierQuantity={soldierQuantity}
                                politicalIssue={politicalIssue}
                                type={conquestType}
                                player={playerToAttack}
                                onChange={(q, i, ct) => {
                                    setSoldierQuantity(q);
                                    setPoliticalIssue(i)
                                    setConquestType(ct)
                                }}
                                onValidityChange={(valid) => {
                                    setConquestValidity(valid)
                                }}
                            />}
                            {activeStep === 2 && <ConquestSummary politicalIssue={politicalIssue} soldierQuantity={soldierQuantity} target={playerToAttack} conquestType={conquestType} />}
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    variant={"outlined"}
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Retour
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                {activeStep !== steps.length - 1 && <Button variant={"contained"} color={"primary"} disabled={!canNext} onClick={handleNext}>
                                    Suivant
                                </Button>}
                                {activeStep === steps.length - 1 && <Button
                                    variant="contained"
                                    color={"secondary"}
                                    onClick={handleConfirm}
                                >
                                    Attaquer {playerToAttack ? playerToAttack.nickname : ""}
                                </Button>}
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </ContentPane>
        </div>
    </>
}

export default Conquest