import { useEffect, useState } from "react";

import ContentPane from "../../layout/content-pane/content-pane";
import Chip from '../../layout/chip/chip'
import usePlayerStore from "../../store/use-player-store";

import SpellTile from "./spell-tile";
import './spell-tile.scss'
import { Divider } from "@mui/material";
import SpellSorted from "./spell-sorted";
import SpellFilter from "./spell-filter";


function SpellsList({ title, selectable = false, onSelected = () => { }, selected = null }) {

    const playerSpells = usePlayerStore(state => state.playerSpells)
    const playerConstructions = usePlayerStore(state => state.playerConstructions)
    const fetchPlayerSpells = usePlayerStore(state => state.fetchPlayerSpells)
    const fetchPlayerConstructions = usePlayerStore(state => state.fetchPlayerConstructions)
    const [wizardSchoolStock, setWizardSchoolStock] = useState(null)
    const [sortSpell, setSortSpell] = useState(playerSpells);
    const [filteredSpell, setFilteredSpell] = useState(playerSpells);

    useEffect(() => {
        async function fetchData() {
            try {
                await fetchPlayerSpells()
                await fetchPlayerConstructions()
            } catch (error) {
                console.error(error);
            }
            const wizardSchoolConstruction = playerConstructions.find(
                playerConstruction => playerConstruction.construction.type === 'wizardSchool'
            );

            if (wizardSchoolConstruction) {
                setWizardSchoolStock(wizardSchoolConstruction);
            }
        }
        fetchData();
    }, [fetchPlayerConstructions, fetchPlayerSpells, playerConstructions]);

    const handleSelection = playerSpell => {
        if (playerSpell.isUnlock && !playerSpell.isCooldown) {
            if (selected === playerSpell) {
                onSelected(null); // Si déjà sélectionné, annuler la sélection
            } else {
                onSelected(playerSpell);
            }
        }
    }

    const handleSortSpellChange = (sortedPlayerSpells) => {
        setSortSpell(sortedPlayerSpells)
    };

    const handleFilterSpellChange = (filterPlayersSpells) => {
        setFilteredSpell(filterPlayersSpells)
    }

    const combinedSpells = sortSpell.filter(spell => filteredSpell.includes(spell));

    return (

        <ContentPane title={title}>

            {wizardSchoolStock &&
                <div className=" mx-auto text-center mt-5 mb-10">
                    <Divider>
                        <span>Ecoles de magie actuelles : <Chip>{wizardSchoolStock.quantity}</Chip></span>
                    </Divider>
                </div>
            }
            <div className="flex flex-row justify-space-evenly">

                <SpellSorted onSortSpellChange={handleSortSpellChange} playerSpells={playerSpells} />
                <SpellFilter handleFilterSpellChange={handleFilterSpellChange} playerSpells={playerSpells} />
            </div>

            <div className="spell-tiles-container">
                {combinedSpells.map((playerSpell, index) => (
                    <SpellTile
                        key={index}
                        playerSpell={playerSpell}
                        selectable={selectable}
                        onSelected={playerSpell => handleSelection(playerSpell)}
                        selected={selected}
                    />
                ))}
            </div>
        </ContentPane>
    )
}

export default SpellsList