import React, { useState, useEffect, useRef } from "react";

import usePlayerStore from "../../store/use-player-store";

import Chip from "../../layout/chip/chip";
import ConquestTextGenerator from "../../services/conquest-text-generator";
import ReactQuill from 'react-quill';
import { QuillEditorModules, QuillEditorFormats } from "../../quill-editor/quill-editor";
import {
  Skeleton,
  Button,
  ButtonGroup,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Alert, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from "@mui/material";
import 'react-quill/dist/quill.snow.css';

import "../../quill-editor/quill-editor.css"
import './conquest-card.scss';
import { htmlToText } from "../../services/utils";
import ConquestTypeSelector from "../conquest-type-selector/conquest-type-selector";

const cardOptions = [
  {
    title: "Pour terre",
    value: "land",
    description: "L'objectif de votre conquete est le gain de terre au détriment des pertes adverses"
  },
  {
    title: "Pour guerre",
    value: "war",
    description: "L'objectif de votre conquete est d'affaiblir l'armée adverse sans gain de terres possible"
  }
]

/**
 * @param player
 * @param soldierQuantity
 * @param politicalIssue
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function ConquestCard({
  player,
  soldierQuantity = 0,
  politicalIssue = "",
  type = null,
  onChange = () => { },
  onValidityChange = () => { },
  tooLowFreeLand = false
}) {
  const resourceStocks = usePlayerStore(state => state.resourceStocks)
  const fetchPlayerResources = usePlayerStore(state => state.fetchPlayerResources)

  const [soldierStock, setSoldierStock] = useState(null)
  const [exceedCost, setExceedCost] = useState(false)

  //the quantity mobilized by the attacker player in the number input
  const [quantity, setQuantity] = useState(soldierQuantity)
  //roleplay text of the attacker player.
  const [roleplay, setRoleplay] = useState(politicalIssue)
  const [target, setTarget] = useState({})
  const [conquestType, setConquestType] = useState(type)

  const rolePlayLength = useRef(0)


  useEffect(() => {
    setTarget(player ?? {})
  }, [player])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPlayerResources()
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [fetchPlayerResources]);

  // Hydrate soldier stock when resource stock is filled
  useEffect(() => {
    if (resourceStocks.length === 0) {
      return
    }

    const soldierResource = resourceStocks.find(
      resource => resource.resource.type === "soldier"
    );
    setSoldierStock(soldierResource.quantity)
  }, [resourceStocks])

  function computeCostExceeded(newQuantity) {
    if (newQuantity > soldierStock || newQuantity < 0 || newQuantity === '' || isNaN(newQuantity)) {
      setExceedCost(true);
    } else {
      setExceedCost(false);
    }
  }

  const handleChangeRoleplay = (value) => {
    setRoleplay(value);
    onChange(quantity, value, conquestType)
    const rawText = htmlToText(value)
    rolePlayLength.current = rawText.length
    handleValidityChange(quantity, value, conquestType)
  };

  const handleQuantityChange = (event) => {
    event.preventDefault();
    let newQuantity = parseInt(event.target.value);
    if (newQuantity > -1 || newQuantity === '' || isNaN(newQuantity)) {
      setQuantity(newQuantity);
      computeCostExceeded(newQuantity);
    }
    onChange(newQuantity, roleplay, conquestType)
    handleValidityChange(newQuantity, roleplay, conquestType)
  };

  const handleMax = () => {
    setQuantity(soldierStock)
    handleValidityChange(soldierStock, roleplay, conquestType)
    onChange(soldierStock, roleplay, conquestType)
  }

  const handleReset = () => {
    setQuantity(0)
    computeCostExceeded(0)
    handleValidityChange(0, roleplay, conquestType)
  }

  const validateRoleplay = (roleplay) => {
    const text = htmlToText(roleplay);
    return !(text.length < 70 || text.length > 10000)
  }

  const handleValidityChange = (quantity, roleplay, conquestType) => {
    const valid = !target || exceedCost || quantity === 0 || isNaN(quantity) || quantity === '' || !validateRoleplay(roleplay) || null === conquestType
    onValidityChange(!valid)
  }

  const handleRandomText = async () => {

    const textGenerator = new ConquestTextGenerator(target.nickname);
    const randomText = textGenerator.generateRandomText();
    setRoleplay(randomText);
  };

  return (
    <>
      {resourceStocks.length === 0 ?
        <Skeleton animation="wave" height={600} />
        :
        <div className="action-container">
          <div className="action-card action-card-single">
            <div className={`banner banner-single banner-conquest`}>
              <div className="action-title">
                <h2>CONQUETE</h2>
                <p>Reclamez les terres de vos adversaires</p>
              </div>
            </div>

            <form className='action-form'>
              {Object.entries(target).length !== 0 ? <h2>Votre cible: <Chip>{target.nickname}</Chip></h2> : <h2><Chip>Pas de cible</Chip></h2>}
              <h3>Type de conquete:</h3>
              <ConquestTypeSelector options={cardOptions} value={conquestType} onChange={(conquestType) => setConquestType(conquestType)} />
              <h3>Enjeux politiques:</h3>
              <ReactQuill
                className="action-roleplay"
                modules={QuillEditorModules.modules}
                formats={QuillEditorFormats.formats}
                value={roleplay}
                onChange={handleChangeRoleplay}
              />
              <Button onClick={handleRandomText} >Générer un texte aléatoire</Button>
              {roleplay !== "" && !validateRoleplay(roleplay) && <Alert severity={"error"}>
                Votre enjeux politique doit faire plus de 70 caractères
                <div>
                  <strong>Nombre de caractères actuel : </strong>{rolePlayLength.current}
                </div>
              </Alert>}
              <TableContainer sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Table size={"small"}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Troupes actuelles</TableCell>
                      <TableCell align="right">{soldierStock}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Troupes mobilisées</TableCell>
                      <TableCell align="right">
                        {isNaN(quantity) || quantity === '' ? 0 : <span className={quantity > soldierStock ? "exceed" : ""}>{quantity}</span>}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Troupes de reserves</TableCell>
                      <TableCell align="right">
                        <span>
                          {soldierStock - quantity < 0 || isNaN(quantity) ? 0 : soldierStock - quantity}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <label htmlFor={`quantityInput`}>
              </label>
              <div className='action-input-container'>
                <TextField
                  size={"small"}
                  className='action-input'
                  type="number"
                  label={"Quantité de soldat"}
                  variant={"outlined"}
                  value={isNaN(quantity) || quantity <= 0 ? '' : quantity}
                  inputProps={{
                    max: soldierStock,
                  }}
                  onChange={(event) => handleQuantityChange(event)}
                />

                <ButtonGroup variant={"outlined"}>
                  <Button onClick={handleMax}>
                    Max
                  </Button>
                  <Button onClick={handleReset}>
                    Reset
                  </Button>
                </ButtonGroup>
              </div>
            </form>
          </div >
        </div >
      }
    </>
  );
}

export default ConquestCard;
