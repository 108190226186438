import { FurrySpellCast } from "./furry-spell";
import { FireBallSpellCast } from "./fire-ball-spell";
import { VoraciousSwarmSpellCast } from "./voracious-swarm-spell";
import { FatalNecrosisSpellCast } from "./fatal-necrosis-spell"
import { PhoenixTailSpellCast } from "./phoenix-tail";
import { MagicShieldSpellCast } from "./magic-shield";
import { MagicHammerSpellCast } from "./magic-hammer";
import { BacchusFavorSpellCast } from "./bacchus-favor";
import { SwiftRepatriationSpellCast } from "./swift-repatriation";
import { MeteoriteFallsSpellCast } from "./meteorite-fall-spell";
import { RampartSpellCast } from "./rampart-spell";
import {DedaleSpellCast} from "./dedale-map";

function SpellCastEffect({ spellCast, detailed = true }) {
    return <>
        {spellCast.type === "furry" && <FurrySpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "fire" && <FireBallSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "voracious-swarm" && <VoraciousSwarmSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "fatal-necrosis" && <FatalNecrosisSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "phoenix-tail" && <PhoenixTailSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "magic-shield" && <MagicShieldSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "magic-hammer" && <MagicHammerSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "bacchus-favor" && <BacchusFavorSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "swift-repatriation" && <SwiftRepatriationSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "meteorite-fall" && <MeteoriteFallsSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "rampart" && <RampartSpellCast spellCast={spellCast} detailed={detailed} />}
        {spellCast.type === "dedale-map" && <DedaleSpellCast spellCast={spellCast} detailed={detailed} />}
    </>
}

export default SpellCastEffect