import ResourceIcon from "../../components/resource-icon/resource-icon"
import { thDelimiter } from "../../services/utils";
import './spying-recap.scss'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function SpyingResources({ resource }) {

    return (
        <div className="resource-spy-tile" key={resource.resource.id}>
            <div className="flex flex-row flex-item-center align-center">
                <ResourceIcon style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} className="big-icon" type={resource.resource.type} />
                <span className="ml-2">{resource.resource.name}</span>
            </div>
            <span className="bold-font">{resource.quantity === null ? <VisibilityOffIcon /> : thDelimiter(resource.quantity)}</span>
        </div>)
}

export default SpyingResources