import "./conquest.scss"
import ConquestVersus from "../../components/conquest-versus/conquest-versus";
import usePlayerStore from "../../store/use-player-store";
import ConquestForChip from "../../components/conquest-for-chip/conquest-for-chip";

function ConquestSummary({target, soldierQuantity, politicalIssue, conquestType}) {
    const player = usePlayerStore(state => state.player)
    return <div>
        <h2>Résumé de votre attaque</h2>
        <ConquestVersus defender={target} attacker={player} />
        <div>
            <span className={"strong"}>Conquete pour :</span>
            <ConquestForChip conquestType={conquestType} detailed />
        </div>
        <p>Vous mobilisez <strong>{soldierQuantity} soldats</strong> pour un puissance d'attaque de {soldierQuantity}</p>
        <div className={"political-issue"} >
            <h5>Enjeux politiques</h5>
            <div dangerouslySetInnerHTML={{__html: politicalIssue}} />
        </div>
        <p>
            Attaquer un joueur est un acte important qui peut provoquer/alimenter la guerre.
        </p>
    </div>
}

export default ConquestSummary