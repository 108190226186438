import './pagination.scss'
import arrowLeft from "../../assets/img/icon/arrow-left.png";
import arrowRight from "../../assets/img/icon/arrow-right.png";
import React, { useEffect, useState } from "react";

/**
 * @param {number} currentPage
 * @param {number} totalPages
 * @param {function} onPageSelection
 * @returns {JSX.Element}
 * @constructor
 */
function Pagination({ currentPage, totalPages, onPageSelection}) {
    let [pageButtons, setPageButton] = useState(Array.from({ length: totalPages }, (_, index) => index + 1))
    let [hasNextPage, setHasNextPage] = useState(currentPage < totalPages)

    useEffect(() => {
        setPageButton(Array.from({ length: totalPages }, (_, index) => index + 1))
        setHasNextPage(currentPage < totalPages)
    }, [totalPages, currentPage])

    const numberedButtonClassName = (pageNumber) => {
        return `pagination-button-number ${currentPage === pageNumber ? 'pagination-button-number-active' : ''}`
    }

    const onButtonClick = (pageNumber) => () => onPageSelection(pageNumber)
    const onPreviousClick = () => onPageSelection(Math.max(currentPage - 1, 0))
    const onNextClick = () => onPageSelection(Math.min(currentPage + 1, totalPages))

    return (
        <div className="pagination-button-container">
            {currentPage !== 1 ?
                <button className="switch-page" onClick={onPreviousClick}>
                    <img className="switch-page-img" src={arrowLeft} alt="arrow-left" />
                </button>
                :
                <div className="switch-page-inactive"></div>
            }

            <div className="button-number-container">
                {pageButtons.map((pageNumber) => (
                    <button
                        className={numberedButtonClassName(pageNumber)}
                        key={pageNumber}
                        onClick={onButtonClick(pageNumber)}>
                        {pageNumber}
                    </button>
                ))}
            </div>

            {hasNextPage ?
                <button className="switch-page" onClick={onNextClick}>
                    <img className="switch-page-img" src={arrowRight} alt="arrow-right"></img>
                </button>
                :
                <div className="switch-page-inactive"></div>
            }
        </div>
    )
}

export default Pagination