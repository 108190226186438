import ContentBox from "../../layout/content-box/content-box"

function Forum() {
    return (
        <>
            <ContentBox header='Forum'>
                Bientot disponible
            </ContentBox>
        </>
    )
}

export default Forum