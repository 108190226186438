export function RampartSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Rampart Solaire qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Rampart Solaire qui augmente la defense de vos fortifications de <strong>{(notification.body.spell.effect.fortification_defense_bonus * 100).toFixed(2)}%</strong>.
    </div>
}

export function RampartSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Les fortifications de {spellCast.defender.nickname} protègent le royaume à hauteur de {(spellCast.effect.fortification_defense_bonus * 100).toFixed(2)}%</span>
    }
    return <span>Fortifications ameliorées de {(spellCast.effect.fortification_defense_bonus * 100).toFixed(2)}%</span>
}