import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const PaginationLength = ({ onPageSizeChange, value = 20 }) => {
    const [pageSize, setPageSize] = useState(value);

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        onPageSizeChange(event.target.value);
    };

    // Todo fonctionnaliser les menu item par une prop
    return (
        <form  className={"w-full flex text-center mt-5 mb-5"}>
            <FormControl size='small' width="auto">
                <Select
                    labelId="page-size-label"
                    id="page-size-select"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                >
                    <MenuItem value={5}>Afficher 5</MenuItem>
                    <MenuItem value={10}>Afficher 10</MenuItem>
                    <MenuItem value={20}>Afficher 20</MenuItem>
                    <MenuItem value={50}>Afficher 50</MenuItem>
                </Select>
            </FormControl>
        </form>
    );
};

export default PaginationLength;
