import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';

function RuleLink({section = null, children = "En savoir plus +"}) {
    const computeRuleLink = () => {
        let path = "/rules"
        if (section !== null) {
            path = `${path}#${section}`
        }

        return path
    }
    return <Button variant={"outlined"} component={Link} to={computeRuleLink()}>
        <GavelIcon className={"mr-4"} />
        {children}
    </Button>
}

export default RuleLink