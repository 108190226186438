import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow,
    TextField
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import usePlayerStore from "../../store/use-player-store";
import { thDelimiter } from "../../services/utils";
import ResourceIcon from "../../components/resource-icon/resource-icon";
import backendRepository from "../../repository/backend-repository";
import game from "../../assets/game/game";
import { toast } from "react-toastify";

function MarketDialog({ sourceResource = null, targetResource = null, onClose = () => { }, open = false }) {
    const findResourceStockByResource = usePlayerStore(state => state.findResourceStockByResource)
    const findProductionByResource = usePlayerStore(state => state.findProductionByResource)
    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)

    const sourceStock = useRef(null)
    const targetStock = useRef(null)
    const error = useRef(null)
    const maxProduction = useRef(null)

    const [quantity, setQuantity] = useState(1)
    const [targetQuantity, setTargetQuantity] = useState(0)
    const [rawTargetQuantity, setRawTargetQuantity] = useState(0)
    const [disabledForm, setDisabledForm] = useState(false)
    const [detailed, setDetailed] = useState(false)

    useEffect(() => {
        if (null !== sourceResource) {
            sourceStock.current = findResourceStockByResource(sourceResource.id)
        }
        if (null !== targetResource) {
            targetStock.current = findResourceStockByResource(targetResource.id)
            const production = findProductionByResource(targetResource.id)
            maxProduction.current = production.maxQuantity * production.playerConstruction.quantity
        }
    }, [findResourceStockByResource, sourceResource, targetResource])

    useEffect(() => {
        if (null === targetResource) {
            return
        }

        let rawTargetQuantity = null
        if (!validInput(quantity)) {
            rawTargetQuantity = targetResource.exchangeWeight / sourceResource.exchangeWeight
        } else {
            rawTargetQuantity = (targetResource.exchangeWeight / sourceResource.exchangeWeight) * quantity
        }

        const targetQuantity = rawTargetQuantity * (1 - game.MARKET.TAX)

        setRawTargetQuantity(Math.round(rawTargetQuantity))
        setTargetQuantity(Math.round(targetQuantity))
    }, [quantity, targetResource, sourceResource])

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value)
    }

    const validInput = (quantity) => !(isNaN(quantity) || quantity === "" || quantity < 0)

    const validQuantity = (quantity) => {
        error.current = null
        if (!validInput(quantity)) {
            error.current = "Quantité invalide"
            return false
        }

        if (targetStock.current && targetStock.current.quantity + targetQuantity > maxProduction.current) {
            error.current = "Capacité de stockage dépassée"
            return false
        }

        if (targetQuantity === 0) {
            error.current = "Vous devez acheter au moins 1 " + targetResource.name
            return false
        }

        if (quantity > sourceStock.current.quantity) {
            error.current = "Pas assez de " + sourceResource.name
            return false
        }

        return true
    }

    const computeMax = () => {
        const max = Math.floor(((maxProduction.current - targetStock.current.quantity) * (sourceResource.exchangeWeight / targetResource.exchangeWeight)) / (1 - game.MARKET.TAX))
        setQuantity(Math.min(max, sourceStock.current.quantity))
    }

    const validForm = () => validQuantity(quantity) && targetQuantity > 0 && !disabledForm

    const handleDialogClose = () => {
        setQuantity(1)
        setDisabledForm(false)
        setDetailed(false)
        onClose()
    };

    const computeQuantity = (ratio) => () => {
        setQuantity(Math.round(ratio * sourceStock.current.quantity))
    }

    const handleExchange = () => {
        setDisabledForm(true)
        backendRepository.exchangeMarketResources(sourceResource, targetResource, parseInt(quantity)).then(() => {
            reloadPlayerData()
            handleDialogClose()
        }).catch(e => {
        }).finally(() => {
            displayConfirm()
            setDisabledForm(false)
        })
    }

    const displayConfirm = () => {
        toast.success(`Vous avez echangé ${quantity} ${sourceResource.name} contre ${targetQuantity} ${targetResource.name}`)
    }

    const handleSwitchDetail = (event) => {
        setDetailed(event.target.checked)
    }

    return <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
    >
        {targetResource && <>
            <DialogTitle id="alert-dialog-title">
                {<div className={"flex flex-row justify-space-between"}>
                    <div>Echange : {targetResource.name}</div>
                    <FormControlLabel value={detailed} onChange={handleSwitchDetail} control={<Switch />} label="Détail" />
                </div>}
            </DialogTitle>
            <DialogContent sx={{ padding: "20px 0" }}>
                <TableContainer className={"market-table-container"}>
                    <Table size={"small"}>
                        <TableBody>
                            {detailed && sourceStock.current && <>
                                <TableRow>
                                    <TableCell><ResourceIcon type={sourceResource.type} /> {sourceResource.name} en stock</TableCell>
                                    <TableCell>{thDelimiter(sourceStock.current.quantity)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ResourceIcon type={sourceResource.type} /> {sourceResource.name} en stock apres échange</TableCell>
                                    <TableCell>{thDelimiter(sourceStock.current.quantity - quantity)}</TableCell>
                                </TableRow>
                            </>}
                            <TableRow>
                                <TableCell><ResourceIcon type={sourceResource.type} /> {sourceResource.name} échangé</TableCell>
                                <TableCell>{!validInput(quantity) ? "1" : thDelimiter(quantity)}</TableCell>
                            </TableRow>
                            {detailed && <TableRow>
                                <TableCell><ResourceIcon type={sourceResource.type} /> % du stock échangé</TableCell>
                                <TableCell>{!validInput(quantity) ? "0" : ((quantity / sourceStock.current.quantity) * 100).toFixed(2)}%</TableCell>
                            </TableRow>}

                            <TableRow>
                                <TableCell>Taux de change brut</TableCell>
                                <TableCell>{(targetResource.exchangeWeight / sourceResource.exchangeWeight).toFixed(3)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>% Taxes</TableCell>
                                <TableCell>{(game.MARKET.TAX * 100).toFixed(0)}%</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell><ResourceIcon type={targetResource.type} /> {targetResource.name} récupéré hors taxe</TableCell>
                                <TableCell>{thDelimiter(rawTargetQuantity)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><ResourceIcon type={targetResource.type} /> {targetResource.name} récupéré
                                    apres taxe</TableCell>
                                <TableCell>{thDelimiter(targetQuantity)}</TableCell>
                            </TableRow>
                            {detailed && targetStock.current && <>
                                <TableRow>
                                    <TableCell><ResourceIcon type={targetResource.type} /> {targetResource.name} en stock apres échange</TableCell>
                                    <TableCell>{thDelimiter(targetQuantity + targetStock.current.quantity)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ResourceIcon type={targetResource.type} /> % de stock apres échange</TableCell>
                                    <TableCell>{(100 * (targetQuantity + targetStock.current.quantity) / maxProduction.current).toFixed(2)}%</TableCell>
                                </TableRow>
                            </>}


                            <TableRow>
                                <TableCell colSpan={2}>
                                    Echangez <strong>{!validInput(quantity) ? "1" : thDelimiter(quantity)}</strong>
                                    <ResourceIcon
                                        type={sourceResource.type} /> pour <strong>{thDelimiter(targetQuantity)}</strong>
                                    <ResourceIcon
                                        type={targetResource.type} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={"mt-8 flex flex-row"}>
                    <TextField
                        label="Quantité à échanger"
                        error={!validQuantity(quantity)}
                        variant="outlined"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                        helperText={error.current}
                    />
                    <Button
                        variant={"contained"}
                        onClick={handleExchange}
                        disabled={!validForm()}
                        sx={{ maxHeight: "56px" }}
                    >Echanger</Button>
                </div>
                <div className={"flex justify-space-between mt-2"}>
                    <ButtonGroup variant={"outlined"} color={"info"}>
                        <Button onClick={computeQuantity(0.1)}>10%</Button>
                        <Button onClick={computeQuantity(0.25)}>25%</Button>
                        <Button onClick={computeQuantity(0.5)}>50%</Button>
                        <Button onClick={computeQuantity(1)}>100%</Button>
                        <Button onClick={computeMax}>MAX</Button>
                    </ButtonGroup>
                </div>
            </DialogContent>
        </>}
    </Dialog>

}

export default MarketDialog