import {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';

import '../sign-up/sign-up.scss'
import {Button, FormControlLabel, Grid, Skeleton, Switch, TextField} from "@mui/material";
import backendRepository from "../../repository/backend-repository";
import usePlayerStore from '../../store/use-player-store';
import DangerZone from '../../components/danger-zone/danger-zone';
import useUiStore from "../../store/use-ui-store";
import ContentPane from "../../layout/content-pane/content-pane";
import GameOptionSwitch from "./game-option-switch";

function UpdateAccount() {

    const navigate = useNavigate();
    const player = usePlayerStore(state => state.player)
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)

    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)
    const helper = useUiStore(state => state.helper)
    const toggleHelper = useUiStore(state => state.toggleHelper)
    const scrollToBottom = useUiStore(state => state.scrollToBottom)
    const toggleScrollToBottom = useUiStore(state => state.toggleScrollToBottom)


    //all the state of the necessary form for the account modification

    useEffect(() => {
        if (null !== player) {
            setBackground(player.background)
            setAge(player.age)
            setAlliance(player.alliance)
            return
        }
        fetchPlayer().then(player => {
            setBackground(player.background)
            setAge(player.age)
            setAlliance(player.alliance)
        })
    }, [fetchPlayer, player])

    const [background, setBackground] = useState("");
    const [age, setAge] = useState("");
    const [alliance, setAlliance] = useState("");
    const [error, setError] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (background === null && age === null) {
                toast.error("Vous devez modifier au moins une valeur");
                return
            }
            await backendRepository.updatePlayer(background, parseInt(age), alliance);
            toast.success('Votre compte a été modifié')
            setTimeout(() => {
                navigate(`/players/${player.id}`);
            }, 2000);
            reloadPlayerData()
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue lors de la modification de votre compte.')
        }
    };

    const handleError = (value) => {
        setError(null)
        if (value === null || value === "") {
            return
        }

        if (value.length > 4) {
            setError("L'alliance ne doit pas comporter plus de 4 caractères")
        }
    }

    if (! player) {
        return <Skeleton animation={"wave"} variant={"rectangular"} height={300} />
    }

    return (
        <>
            <Button variant={"outlined"} component={Link} to={`/players/${player.id}`}>Retour</Button>
            <ContentPane
                title={"Modifier le compte"}
            >
                <p>Veuillez rentrer les informations à modifier</p>
                <form onSubmit={handleSubmit} className={""}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className={"w-full"}
                                type={"number"}
                                label="Modifier l'age du personnage"
                                value={age}
                                onChange={(event) => setAge(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                error={error !== null}
                                helperText={error}
                                className={"w-full"}
                                type={"text"}
                                label="Modifier l'alliance (4 char. max)"
                                value={alliance ?? ""}
                                onChange={(event) => {
                                    handleError(event.target.value)
                                    setAlliance(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                className={"w-full"}
                                type={"text"}
                                label="Modifier le background"
                                value={background ?? ""}
                                rows={8}
                                onChange={(event) => setBackground(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <div className="form-element text-center flex justify-flex-end">
                        <Button type={"submit"} variant={"contained"}>Modifier le compte</Button>
                    </div>
                </form>

                <h2>Préférences</h2>
                <div className={"flex flex-col ml-4"}>
                    <GameOptionSwitch
                        label={"Activation des aides en jeu"}
                        checked={helper}
                        onChange={toggleHelper}
                        help={"Ajoute des blocs d'aides contextuelles dans les différentes sections du jeu"}
                    />
                    <GameOptionSwitch
                        label={"Bouton pour remonter en haut de page"}
                        checked={scrollToBottom}
                        onChange={toggleScrollToBottom}
                        help={"Ajoute un bouton en bas à droite de l'écran qui permet de remonter en haut de la page"}
                    />
                </div>
            </ContentPane>
            <DangerZone />
        </>
    )
}

export default UpdateAccount