import PageTitle from "../../layout/page-title/page-title";
import ContentPane from "../../layout/content-pane/content-pane";
import KeyIcon from "../../layout/key-icon/key-icon";
import "./help.scss"
import { ImShift } from "react-icons/im"
import { MobileView } from 'react-device-detect';
import { Alert } from "@mui/material";

function Help() {
    const shift = <><ImShift style={{ marginTop: "auto", marginBottom: "auto" }} /> shift</>

    return <>
        <PageTitle>Aide</PageTitle>
        <ContentPane title={"Raccourcis claviers"}>
            <p>
                Les raccourcis clavier suivants permettent une navigation simplifiée dans l'interface du jeu :
            </p>
            <MobileView>
                <Alert severity={"warning"}>
                    Ces raccourcis ne sont pas disponibles sur téléphone
                </Alert>
            </MobileView>

            <ul className={"help-list"}>
                <li><KeyIcon keyCodes={["m"]} /> : Ouvre/ferme le menu latéral</li>
                <li><KeyIcon keyCodes={[shift, "d"]} /> : Aller sur la page dashboard</li>
                <li><KeyIcon keyCodes={[shift, "e"]} /> : Aller sur la page d'exploration</li>
                <li><KeyIcon keyCodes={[shift, "b"]} /> : Aller sur la page construction</li>
                <li><KeyIcon keyCodes={[shift, "a"]} /> : Aller sur la page armée</li>
                <li><KeyIcon keyCodes={[shift, "c"]} /> : Aller sur la page conquete</li>
                <li><KeyIcon keyCodes={[shift, "h"]} /> : Aller sur la page d'historique des conquetes</li>
                <li><KeyIcon keyCodes={[shift, "o"]} /> : Aller sur la page des actions en cours</li>
                <li><KeyIcon keyCodes={[shift, "s"]} /> : Aller sur la page des sorts</li>
                <li><KeyIcon keyCodes={[shift, "p"]} /> : Aller sur la page de lancer de sort</li>
                <li><KeyIcon keyCodes={[shift, "f"]} /> : Aller sur la page des effets actifs</li>
            </ul>
        </ContentPane>
    </>
}

export default Help