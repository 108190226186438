import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import BonusList from "../../layout/bonus/bonus-list";
import ResourceIcon from "../../components/resource-icon/resource-icon";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {thDelimiter} from "../../services/utils";

function AttackerDetails({meta, attacker}) {
    return <>
        <h3 className={"conquest-recap-title"}><GpsFixedIcon className={"mr-2"} />Détail offensif :</h3>
        <p>
            Attaquant : {attacker.nickname}
        </p>
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Unité</strong></TableCell>
                        <TableCell><strong>Valeur offensive</strong></TableCell>
                        <TableCell><strong>Engagés</strong></TableCell>
                        <TableCell><strong>Perdus</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <div className={"flex flex-row flex-items-center"}>
                                <ResourceIcon type={"soldier"} />
                                <span className={"strong ml-2"}>Soldats</span>
                            </div>
                        </TableCell>
                        <TableCell>{thDelimiter(meta.troops.soldier.value)}</TableCell>
                        <TableCell>{thDelimiter(meta.troops.soldier.quantity)}</TableCell>
                        <TableCell>{thDelimiter(meta.lostTroops.soldier)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <BonusList bonus={meta.bonus} emptyMessage={"Pas de bonus magique appliqué sur l'attaquant"} />
    </>
}

export default AttackerDetails