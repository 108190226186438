import ContentBox from "../../layout/content-box/content-box"

function Messaging() {
    return <>
        <ContentBox header='Messagerie'>
      Bientot disponible
    </ContentBox>
  </>
}

export default Messaging