import React, { useState } from "react";
import FaceCompact from "./face-compact";
import FaceDetailed from "./face-detailed";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

function SpellTile({ index, playerSpell, selectable = false, onSelected = () => { }, selected = null }) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        if (selectable) {
            onSelected(playerSpell)
        } else {
            setDialogOpen(true);
        }
    };

    const handleDialogClose = (event, reason) => {
        setDialogOpen(false);
    };

    return (
        <div key={index}>
            <FaceCompact onClick={handleDialogOpen} selectable={selectable} playerSpell={playerSpell} selected={selected} />
            <Dialog
                sx={{ width: '100%', margin: '0px', padding: '0px' }}
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>Details du sort</DialogTitle>
                <DialogContent sx={{ width: '100%', margin: '0px', padding: '0px' }}>
                    <FaceDetailed playerSpell={playerSpell} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SpellTile;
