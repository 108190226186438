import { Outlet, Navigate } from "react-router";
import usePlayerStore from "../store/use-player-store";

const PrivateRoutes = () => {
    const token = usePlayerStore(state => state.token);

    return (
        token ? <Outlet /> : <Navigate to="/login" />
    )


}

export default PrivateRoutes