import {Grid, InputAdornment, LinearProgress, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useState, useEffect, useRef} from "react";

/**
 * An input that emit onChange after a timeout.
 *
 * @param {function} props.onChange Event emitted when input has no longer been changed after last change
 * @param {number} props.timeout
 * @return {JSX.Element}
 * @constructor
 */
function DelayedInput(props) {
    // Props
    const timeout = props.timeout ?? 2000
    const steps = props.steps ?? 5
    const stepsCounts = Math.ceil(100/steps)

    // States
    // Timeout id is stored in order to clear it on ne input change
    const [sendTimeout, setSendTimeout] = useState(null)
    // Progress for loader
    const [progress, setProgress] = useState(100)
    // Value on the input
    const [value, setValue] = useState("")

    // Handler for input change
    const handleInputChange = (event) => {
        setValue(event.target.value)

        // If send timeout is not null, clear timeout to avoid onChange emit
        if (sendTimeout) {
            clearTimeout(sendTimeout)
            setSendTimeout(null)
        }

        const id = setTimeout(() => {
            clearTimeout(sendTimeout)
            setSendTimeout(null)

            props.onChange(event.target.value)
        }, timeout)
        setSendTimeout(id)
        setProgress(0)
    };

    let intervalHandler = useRef(null)
    useEffect(() => {
        const updateProgress = () => {
            intervalHandler.current = setInterval(() => {
                setProgress(prevProgress => prevProgress + steps)
            }, (timeout - 400) / stepsCounts)

            if (progress >= 100) {
                clearInterval(intervalHandler.current)
                setProgress(100)
            }
        }

        updateProgress()

        return () => clearInterval(intervalHandler.current)
    }, [progress, intervalHandler, steps, stepsCounts, timeout])

    // Input breakpoint for responsive
    const breakPoints = {
        OFFSET: {
            xs: 0,
            md: 3,
        },
        CONTENT: {
            xs: 12,
            md: 6,
        }
    }

    return <Grid container>
        <Grid xs={breakPoints.OFFSET.xs} md={breakPoints.OFFSET.md} item />
        <Grid xs={breakPoints.CONTENT.xs}  md={breakPoints.CONTENT.md} item>
            <TextField
                fullWidth
                value={value}
                onChange={handleInputChange}
                InputProps={{
                    startAdornment: <InputAdornment position={"start"}>
                        <SearchIcon />
                    </InputAdornment>
                }}
            />
        </Grid>
        <Grid xs={breakPoints.OFFSET.xs} md={breakPoints.OFFSET.md} item />
        <Grid xs={breakPoints.OFFSET.xs} md={breakPoints.OFFSET.md} item />
        <Grid xs={breakPoints.CONTENT.xs} md={breakPoints.CONTENT.md} item>
            {progress > 0 && <LinearProgress variant={"determinate"} value={progress} />}
        </Grid>
        <Grid xs={breakPoints.OFFSET.xs} md={breakPoints.OFFSET.md} item />
    </Grid>
}

export default DelayedInput