import usePlayerStore from "../../store/use-player-store";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import useUiStore from "../../store/use-ui-store";

function SocketManager() {
    const reloadPlayerData = usePlayerStore(state => state.reloadPlayerData)
    const logout = usePlayerStore(state => state.logout)
    const token = usePlayerStore(state => state.token)
    const fetchNotifications = useUiStore(state => state.fetchNotifications)
    const setHasBeenDisconnected = useUiStore(state => state.setHasBeenDisconnected)

    const [socket, setSocket] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        if (!token) {

            if (null === socket) {
                return
            } else {
                socket.close()
                setSocket(null)
                return
            }
        }


        const socketConnection = new io(process.env.REACT_APP_API_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`
            },
            transports: ['polling']
        })

        socketConnection.on("connect", () => {
            if (useUiStore.getState().hasBeenDisconnected) {
                reloadPlayerData()
            }
        })


        socketConnection.on("refresh", (payload) => {
            reloadPlayerData()
        })

        socketConnection.on("logout", () => {
            logout()
            navigate("/login?expired")
        })

        socketConnection.on("notification", () => {
            fetchNotifications()
        })

        socketConnection.on("disconnect", () => {
            setHasBeenDisconnected(true)
        })

        setSocket(socketConnection)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPlayerData, token])

    return null
}

export default SocketManager