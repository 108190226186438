import { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

function SpellSorted({ onSortSpellChange, playerSpells }) {
    const [sortSpell, setSortSpell] = useState('wizard-school');

    useEffect(() => {
        if (Array.isArray(playerSpells)) {
            const newSortedPlayerSpells = [...playerSpells];
            newSortedPlayerSpells.sort((a, b) => {
                const spellA = a.spell;
                const spellB = b.spell;

                switch (sortSpell) {
                    case 'wizard-school':
                        return spellA.requiredSchool - spellB.requiredSchool;

                    case 'name':
                        return spellA.name.localeCompare(spellB.name);

                    case 'cost':
                        return spellA.cost - spellB.cost;

                    case 'dangerosity':
                        const dangerosityOrder = ['low', 'medium', 'high'];
                        return dangerosityOrder.indexOf(spellA.dangerosity) - dangerosityOrder.indexOf(spellB.dangerosity);

                    default:
                        return 0;
                }
            });
            onSortSpellChange(newSortedPlayerSpells);
        }
    }, [onSortSpellChange, playerSpells, sortSpell]);

    const handleSortChange = (event) => {
        if (event && event.target) {
            const newSortSpell = event.target.value;
            setSortSpell(newSortSpell);
        }
    }
    return (
        <>
            <form className={"flex text-center mt-5 mb-5"}>
                <FormControl size='small' width="auto">
                    <span>Trier par :</span>
                    <Select
                        labelId="page-size-label"
                        id="page-size-select"
                        defaultValue='Trier par :'
                        value={sortSpell}
                        onChange={handleSortChange}
                    >
                        <MenuItem value={'wizard-school'}>Ecole de mages</MenuItem>
                        <MenuItem value={'cost'}>Coût en Mana</MenuItem>
                        <MenuItem value={'dangerosity'}>Dangerosité</MenuItem>
                        <MenuItem value={'name'}>Nom</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </>
    )
}

export default SpellSorted;