import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Game from "../../assets/game/game";
import { thDelimiter } from "../../services/utils";


function CitizenCost({ citizen }) {

    const gameData = Game.CITIZEN_UPGRADE;
    const citizenData = gameData[citizen.resource.type.toUpperCase()];

    return (
        <>
            <div className="flex flex-center">
                <h3>Cout d'une construction</h3>
            </div>
            <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }} >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ressources nécessaires</TableCell>
                            <TableCell>Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={require(`../../assets/img/icon/${"villager"}.webp`)}
                                    className="ressource-icon mr-5"
                                    alt="resoure-icon"
                                    rel="preload"
                                    as="image"
                                />
                                {gameData.ALL.RESOURCE}</TableCell>
                            <TableCell>{thDelimiter(gameData.ALL.QUANTITY)}</TableCell>
                        </TableRow>
                        {citizenData.map((e, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={require(`../../assets/img/icon/${e.TYPE}.webp`)}
                                        className="ressource-icon mr-5"
                                        alt="resource-icon"
                                        rel="preload"
                                        as="image"
                                    />
                                    {e.RESOURCE}
                                </TableCell>
                                <TableCell>{thDelimiter(e.QUANTITY)}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CitizenCost