import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import * as React from 'react';

import '../hamburger/hamburger.scss'
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import GavelIcon from '@mui/icons-material/Gavel';
import GradeIcon from '@mui/icons-material/Grade';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExploreIcon from '@mui/icons-material/Explore';
import BuildIcon from '@mui/icons-material/Build';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ForumIcon from '@mui/icons-material/Forum';
import EmailIcon from '@mui/icons-material/Email';
import CastleIcon from '@mui/icons-material/Castle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ShieldIcon from '@mui/icons-material/Shield';
import FortIcon from '@mui/icons-material/Fort';
import PublicIcon from '@mui/icons-material/Public';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {FaBullhorn} from "react-icons/fa"
import { FaHatWizard } from 'react-icons/fa'
import { GiBoltSpellCast } from 'react-icons/gi'
import {GiPotionBall} from 'react-icons/gi'
import {GiLockSpy} from 'react-icons/gi'

import { useEffect, useState } from "react";
import usePlayerStore from "../../store/use-player-store";
import { useCookies } from "react-cookie";
import keypress from "../../services/keypress";
//import Link from "@mui/material/Link";
import HamburgerDropdown from "./hamburger-dropdown";

function Hamburger() {

    const logout = usePlayerStore(state => state.logout)
    const token = usePlayerStore(state => state.token)
    const setDisplayHelp = usePlayerStore(state => state.setDisplayHelp)
    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);

    const [, , removeCookie] = useCookies(['Refresh-Token']);

    let preventDrawerClose = false

    const toggleDrawer =
        (open = null) =>
            (event) => {
                if (preventDrawerClose) {
                    preventDrawerClose = false
                    return
                }
                if (
                    event.type === 'keydown' &&
                    (event.key === 'Tab' || event.key === 'Shift')
                ) {
                    return;
                }

                setOpenMenu(open ?? !openMenu);
            };


    useEffect(() => {
        if (token === null) {
            return
        }

        // Register keyboard shortcut
        keypress.addEventListener(['m'], () => setOpenMenu(!openMenu))
        keypress.addEventListener(['shift', 'd'], () => navigate('/dashboard') && setOpenMenu(false))
        keypress.addEventListener(['shift', 'e'], () => navigate('/exploration'))
        keypress.addEventListener(['shift', 'b'], () => navigate('/construction'))
        keypress.addEventListener(['shift', 'a'], () => navigate('/army'))
        keypress.addEventListener(['shift', 'h'], () => navigate('/conquests/me'))
        keypress.addEventListener(['shift', 'c'], () => navigate('/conquest'))
        keypress.addEventListener(['shift', 'o'], () => navigate('/orders'))
        keypress.addEventListener(['shift', 's'], () => navigate('/spells'))
        keypress.addEventListener(['shift', 'p'], () => navigate('/spell-cast'))
        keypress.addEventListener(['shift', 'f'], () => navigate('/active-effect'))
        keypress.addEventListener(['h'], () => setDisplayHelp(true), () => setDisplayHelp(false))

        keypress.registerWindowListener()

        return () => keypress.unregisterWindowListener()
    }, [openMenu, token, navigate, setDisplayHelp, setOpenMenu])

    function disconnected() {
        toast.info(
            <div>
                <p>Voulez-vous vous déconnecter ?</p>
                <div className='toastify-button-container'>
                    <Button className='button-hamburger' onClick={handleLogout}>Oui</Button>
                    <Button className='button-hamburger' onClick={handleCancel}>Non</Button>
                </div>
            </div>,
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                closeOnClick: true,
                closeButton: false
            }
        );
    }

    function handleLogout() {
        // Todo
        logout()
        removeCookie("Refresh-Token")
        navigate(`/login`);

    }

    function handleCancel() {
        return
    }

    const commonLinks = <>
        <ListItem key={"Accueil"} disablePadding>
            <ListItemButton component={Link} to={"/"}>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Accueil"} />
            </ListItemButton>
        </ListItem>
        <ListItem key={"Regles du jeu"} disablePadding>
            <ListItemButton component={Link} to={"/rules"}>
                <ListItemIcon>
                    <GavelIcon />
                </ListItemIcon>
                <ListItemText primary={"Regles du jeu"} />
            </ListItemButton>
        </ListItem>
    </>

    const anonymousLinks = token !== null ? "" : <>
        <ListItem key={"S'inscrire"} disablePadding>
            <ListItemButton component={Link} to={"/signup"}>
                <ListItemIcon>
                    <GradeIcon />
                </ListItemIcon>
                <ListItemText primary={"S'inscrire"} />
            </ListItemButton>
        </ListItem>
        <ListItem key={"Login"} disablePadding>
            <ListItemButton component={Link} to={"/login"}>
                <ListItemIcon>
                    <LoginIcon />
                </ListItemIcon>
                <ListItemText primary={"Login"} />
            </ListItemButton>
        </ListItem>
    </>;

    const loggedLinks = token === null ? "" : <>
        <ListItem key={"Logout"} disablePadding>
            <ListItemButton onClick={disconnected}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
            </ListItemButton>
        </ListItem>
    </>;

    const gameLinks = token === null ? "" : <>
        <Divider />
        <List>
            <ListItem key={"Dashboard"} disablePadding>
                <ListItemButton component={Link} to={"/dashboard"}>
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Place publique"} disablePadding>
                <ListItemButton component={Link} to={"/feeds"}>
                    <ListItemIcon><FaBullhorn className={"ml-2"} size={18} /></ListItemIcon>
                    <ListItemText primary={"Place publique"} />
                </ListItemButton>
            </ListItem>
            <HamburgerDropdown title={"Royaume"} icon={<PublicIcon />} onOpen={() => preventDrawerClose = true}>
                <ListItem key={"Exploration"} disablePadding>
                    <ListItemButton component={Link} to={"/exploration"}>
                        <ListItemIcon><ExploreIcon /></ListItemIcon>
                        <ListItemText primary={"Exploration"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Construction"} disablePadding>
                    <ListItemButton component={Link} to={"/construction"}>
                        <ListItemIcon><BuildIcon /></ListItemIcon>
                        <ListItemText primary={"Construction"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Armée"} disablePadding>
                    <ListItemButton component={Link} to={"/army"}>
                        <ListItemIcon><ShieldIcon /></ListItemIcon>
                        <ListItemText primary={"Armée"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Actions"} disablePadding>
                    <ListItemButton component={Link} to={"/orders"}>
                        <ListItemIcon><FormatListNumberedIcon /></ListItemIcon>
                        <ListItemText primary={"Actions"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Le marché"} disablePadding>
                    <ListItemButton component={Link} to={"/market"}>
                        <ListItemIcon><StorefrontIcon /></ListItemIcon>
                        <ListItemText primary={"Le marché"} />
                    </ListItemButton>
                </ListItem>
            </HamburgerDropdown>
            <HamburgerDropdown title={"Conquete"} icon={<CastleIcon />} onOpen={() => preventDrawerClose = true}>
                <ListItem key={"Conquérir"} disablePadding>
                    <ListItemButton component={Link} to={"/conquest"}>
                        <ListItemIcon><FortIcon /></ListItemIcon>
                        <ListItemText primary={"Conquérir"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Historique"} disablePadding>
                    <ListItemButton component={Link} to={"/conquests/me"}>
                        <ListItemIcon><HistoryToggleOffIcon /></ListItemIcon>
                        <ListItemText primary={"Historique des conquetes"} />
                    </ListItemButton>
                </ListItem>
            </HamburgerDropdown>
            <HamburgerDropdown title={"Espionnage"} icon={<VisibilityIcon />} onOpen={() => preventDrawerClose = true}>
                <ListItem key={"Espionner"} disablePadding>
                    <ListItemButton component={Link} to={"/spying"}>
                        <ListItemIcon><VisibilityIcon /></ListItemIcon>
                        <ListItemText primary={"Espionner"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Historique"} disablePadding>
                    <ListItemButton component={Link} to={"/spyings/me"}>
                        <ListItemIcon><HistoryToggleOffIcon /></ListItemIcon>
                        <ListItemText primary={"Historique des espionnages"} />
                    </ListItemButton>
                </ListItem>
            </HamburgerDropdown>
            <HamburgerDropdown title={"Magie"} icon={<AutoFixHighIcon />} onOpen={() => preventDrawerClose = true}>
                <ListItem key={"Mes sorts"} disablePadding>
                    <ListItemButton component={Link} to={"/spells"}>
                        <ListItemIcon><FaHatWizard /></ListItemIcon>
                        <ListItemText primary={"Mes sorts"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Lancer un sort"} disablePadding>
                    <ListItemButton component={Link} to={"/spell-cast"}>
                        <ListItemIcon><GiBoltSpellCast /></ListItemIcon>
                        <ListItemText primary={"Lancer un sort"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Effets actifs"} disablePadding>
                    <ListItemButton component={Link} to={"/active-effect"}>
                        <ListItemIcon><GiPotionBall /></ListItemIcon>
                        <ListItemText primary={"Effets actifs"} />
                    </ListItemButton>
                </ListItem>
            </HamburgerDropdown>
        </List>
        <Divider />
        <List>
            <ListItem key={"Echange de ressources"} disablePadding>
                <ListItemButton component={Link} to={"/swap"}>
                    <ListItemIcon><SwapHorizIcon /></ListItemIcon>
                    <ListItemText primary={"Echange de ressources"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Classement"} disablePadding>
                <ListItemButton component={Link} to={"/ranking"}>
                    <ListItemIcon><MilitaryTechIcon /></ListItemIcon>
                    <ListItemText primary={"Classement"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Alliances"} disablePadding>
                <ListItemButton component={Link} to={"/alliance"}>
                    <ListItemIcon><HandshakeIcon /></ListItemIcon>
                    <ListItemText primary={"Alliances"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Forum"} disablePadding>
                <ListItemButton component={Link} to={"/forum"}>
                    <ListItemIcon><ForumIcon /></ListItemIcon>
                    <ListItemText primary={"Forum"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Messagerie privée"} disablePadding>
                <ListItemButton component={Link} to={"/messaging"}>
                    <ListItemIcon><EmailIcon /></ListItemIcon>
                    <ListItemText primary={"Messagerie privée"} />
                </ListItemButton>
            </ListItem>
        </List>
    </>;
    const list = <Box
        sx={{ width: 250, backgroundColor: "#31313B" }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
    >
        <List>
            {commonLinks}
            {anonymousLinks}
            {loggedLinks}
        </List>
        {gameLinks}
    </Box>;
    return (
        <nav className="hamburger-container">
            <React.Fragment key={"left"}>
                <div className="hamburger" onClick={toggleDrawer(true)}>
                    <div className='hamburger-icon hamburger-top'></div>
                    <div className='hamburger-icon hamburger-middle'></div>
                    <div className='hamburger-icon hamburger-bottom'></div>
                </div>
                <Drawer
                    anchor={"left"}
                    open={openMenu}
                    onClose={toggleDrawer(false)}
                >
                    {list}
                </Drawer>
            </React.Fragment>
        </nav>
    )
}

export default Hamburger