import LoginForm from "../../components/login-form/login-form"
import { useSearchParams } from "react-router-dom"
import { Link } from "@mui/material";
import "./login.css"
import ContentBox from "../../layout/content-box/content-box";
import { Alert } from "@mui/material";

function Login() {
    const [searchParams] = useSearchParams();
    const expiredSession = searchParams.get("expired") !== null

    return (
        <ContentBox
            header={"Se connecter"}
        >
            {expiredSession && <Alert severity={"warning"}>Votre session a expirée veuillez vous reconnecter</Alert>}
            <div>
                <p>Veuillez rentrer vos informations afin de vous connecter:</p>
            </div>

            <LoginForm />

            <div>
                <p>Vous n'avez pas créé de compte ? Rendez-vous sur la page <Link href="/signup">Inscription</Link></p>
            </div>
        </ContentBox>
    )
}

export default Login