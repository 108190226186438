import "./market.scss"
import ResourceIcon from "../../components/resource-icon/resource-icon";
import {Button} from "@mui/material";

function MarketResource({resource, comparisonResource = null, onExchange = () => {}}) {
    let changeRate = "-"
    if (null !== comparisonResource) {
        changeRate = (resource.exchangeWeight / comparisonResource.exchangeWeight).toFixed(5)
    }

    const onClick = () => {
        onExchange(resource)
    }
    return <div className={"market-resource-container"}>
        <div className={"ml-2 flex flex-row flex-items-center"}>
            <ResourceIcon type={resource.type} />
            <div className="flex flex-col ml-4">
                <div className={"strong"}>Taux</div>
                <div>{changeRate}</div>
            </div>
        </div>
        <Button variant={"outlined"} disabled={null === comparisonResource || comparisonResource.type === resource.type} onClick={onClick}>Echanger</Button>
    </div>
}

export default MarketResource