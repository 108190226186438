import "./content-pane.scss"
import "../../index.scss"

function ContentPane({ title = "", children, id }) {
    return (<div className="content-pane" id={id}>
        <h2>{title}</h2>
        <div className={"content-pane-content text-left"}>
            {children}
        </div>
    </div>)
}

export default ContentPane