import React from "react";
import SpellIcon from "../spell-icon/spell-icon";
import { Divider } from "@mui/material";
import RequiredSchool from './required-school.jsx'
import Chip from "../../layout/chip/chip";
import game from "../../assets/game/game";

function FaceCompact({ onClick, selectable = false, playerSpell, selected, readonly }) {
    return (

        <div onClick={onClick}>
            <div
                key={playerSpell.spell.type}
                className={`spell-tile compact`}>
                <SpellIcon type={playerSpell.spell.type} selectable={selectable} playerSpell={playerSpell} selected={selected === playerSpell} />
                <div className="spell-tile-text">
                    <Divider>
                        <h3>{playerSpell.spell.name}</h3>
                    </Divider>
                    <span className={"mb-6"}>
                        <span className={"strong"}>Dangerosité :</span> <Chip type={game.MAGIC.COLOR[playerSpell.spell.dangerosity]}>{game.MAGIC.LEVEL[playerSpell.spell.dangerosity]}</Chip>
                    </span>
                    {!readonly && <RequiredSchool playerSpell={playerSpell} />}
                </div>
            </div>

        </div>
    )
}

export default FaceCompact