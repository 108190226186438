import React from 'react'
import {thDelimiter} from "../../services/utils";

export function MeteoriteFallsSpellNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Chute de Méteores qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Chute de Méteores qui a detruit <strong>{thDelimiter(notification.body.spell.effect.sumOfDestruction)} constructions</strong>.
    </div>
}
export function MeteoriteFallsSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Vous avez detruit {thDelimiter(spellCast.effect.sumOfDestruction)} constructions dans le stock de {spellCast.defender.nickname}</span>
    }
    return <span>Vous avez detruit {thDelimiter(spellCast.effect.sumOfDestruction)} constructions</span>
}