import LandscapeIcon from '@mui/icons-material/Landscape';
import { GiBroadsword } from "react-icons/gi"

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    SPY_TYPES: {
        watchResource: {
            NAME: "ressources",
            COLOR: "secondary",
            ICON: (detailed) => <LandscapeIcon />
        },
    },
    CONQUEST_TYPES: {
        land: {
            NAME: "Terre",
            COLOR: "secondary",
            ICON: (detailed) => <LandscapeIcon />
        },
        war: {
            NAME: "Guerre",
            COLOR: "primary",
            ICON: (detailed) => <GiBroadsword size={detailed ? undefined : 24} />
        }
    },
    MINIMUM_LANDS_SAFETY: 1000,
    CITIZEN_UPGRADE: {
        ALL: {
            TYPE: "villager",
            RESOURCE: "Villageois",
            QUANTITY: 1,
        },
        SOLDIER: [{
            TYPE: "iron",
            RESOURCE: "Fer",
            QUANTITY: 10,
        },
        {
            TYPE: "copper",
            RESOURCE: "Cuivre",
            QUANTITY: 5,
        }
        ],
        SPY: [{
            TYPE: "silver",
            RESOURCE: "Argent",
            QUANTITY: 10,
        },
        {
            TYPE: "gold",
            RESOURCE: "Or",
            QUANTITY: 2,
        },
        ],
        WIZARD: [{
            TYPE: "gold",
            RESOURCE: "Or",
            QUANTITY: 10,
        },
        {
            TYPE: "gemstone",
            RESOURCE: "Gemme",
            QUANTITY: 1,
        },
        ]
    },
    EXPLORATION_COST: [
        { type: "villager", quantity: 50, name: 'villageois' },
        { type: 'food', quantity: 200, name: 'nourriture' }
    ],
    HOUSE_CONSTRUCTIONS: ["house", "wizardSchool", "garrison", "spySchool"],
    COMPUTE_HOUSE_CAPACITY: (type, quantity) => {
        if (type === "house") {
            return 200 * quantity
        }

        if (type === "garrison") {
            return 50 * quantity
        }
        return 10 * quantity
    },
    COMPUTE_EXPLORATION_COST: (initialQuantity, quantity, resourceType) => {
        const rawFormulae = (qty, baseQuantity) => Math.round(baseQuantity * Math.pow(qty / 20, 2))
        if (resourceType === "food") {
            const baseQty = 50
            return rawFormulae(initialQuantity + quantity, baseQty) - rawFormulae(initialQuantity, baseQty)
        }
        if (resourceType === "villager") {
            const baseQty = 10
            return rawFormulae(initialQuantity + quantity, baseQty) - rawFormulae(initialQuantity, baseQty)
        }

        return 0
    },

    COMPUTE_REVERSE_EXPLORATION_COST: (resourceType, quantity, totalLands) => {
        const rawFormulae = (qty, baseQuantity) => baseQuantity * Math.pow(qty / 20, 2)
        const f = (cost, baseQuantity) => 20 * Math.sqrt((cost + rawFormulae(totalLands, baseQuantity)) / baseQuantity) - totalLands
        if (resourceType === "food") {
            const baseQty = 50
            return f(quantity, baseQty)
        }
        if (resourceType === "villager") {
            const baseQty = 10
            return f(quantity, baseQty)
        }

        return 0
    },

    MAGIC: {
        LEVEL: {
            low: "Faible",
            medium: "Moyenne",
            high: "Elevée",
        },

        COLOR: {
            low: "info",
            medium: "warning",
            high: "danger"
        }
    },
    MARKET: {
        TAX: 0.15,
        TAX_PERCENT() { return `${(this.TAX * 100).toFixed(2)}%` }
    }
};
