function PlayerName({ player, bold = false }) {
    const computeClasses = () => {
        const classes = ["mr-1"]
        if (bold) {
            classes.push("strong")
        }

        return classes.join(" ")
    }
    if (player === "Inconnu" || player === "") {
        return <span className="unknown-player">Inconnu</span>;
    }

    return <>
        {player.alliance !== null ? <span className={computeClasses()}>[{player.alliance}]</span> : ""}
        {player.nickname}
    </>
}

export default PlayerName