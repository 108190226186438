import { useState } from "react";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { toast } from "react-toastify";

function SacrifyCitizenInput({ citizen, onSacrify = () => { } }) {
    const [quantityToSacrify, setQuantityToSacrify] = useState(0);
    const handleSacrify = () => {
        toast.info(
            <div>
                <p>
                    Voulez-vous sacrifier {quantityToSacrify} {citizen.resource.name} ?
                </p>
                <div className="toastify-button-container">
                    <Button
                        className="button-hamburger"
                        onClick={() => {
                            onSacrify(quantityToSacrify, citizen.id);
                            setQuantityToSacrify(0);
                        }}
                    >
                        Oui
                    </Button>
                    <Button className="button-hamburger" onClick={() => { }}>
                        Non
                    </Button>
                </div>
            </div>,
            {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: false,
                closeOnClick: true,
                closeButton: false,
            }
        );
    };

    const handleSacrifyQuantityChange = (event) => {
        event.preventDefault();
        let newQuantity = parseInt(event.target.value);
        setQuantityToSacrify(newQuantity);
    };

    const handleMaxSacrify = () => {
        setQuantityToSacrify(citizen.quantity);
    };

    const handleResetSacrify = () => {
        setQuantityToSacrify(0);
    };


    return (
        <>
            <label className="strong mt-10" htmlFor={`quantityInput${citizen.id}`}>
                Quantité à sacrifier:
            </label>

            <div className="action-input-container">
                <TextField
                    size="small"
                    className="action-input"
                    id={`quantityInput${citizen.id}`}
                    type="number"
                    value={isNaN(quantityToSacrify) ? "" : quantityToSacrify}
                    onChange={(event) => handleSacrifyQuantityChange(event)}
                />

                <ButtonGroup variant="outlined">
                    <Button onClick={handleMaxSacrify}>Max</Button>
                    <Button onClick={handleResetSacrify}>Reset</Button>
                    <Button
                        variant="contained"
                        disabled={
                            quantityToSacrify <= 0 ||
                            quantityToSacrify > citizen.quantity ||
                            isNaN(quantityToSacrify) ||
                            quantityToSacrify === ""
                        }
                        color="error"
                        onClick={handleSacrify}
                    >
                        Sacrifier
                    </Button>
                </ButtonGroup>
            </div>
        </>
    );
}

export default SacrifyCitizenInput