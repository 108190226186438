import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTheme } from "@mui/material";

import { useEffect, useState } from "react";

function Summary() {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));


    const [open, setOpen] = useState(false);

    useEffect(() => {
        let { hash } = window.location
        hash = hash.replace("#", "")

        if ("" !== hash) {
            scrollToElement(hash)
        }
    }, [])

    const handleClick = () => {
        setOpen(!open);
    };

    function scrollToElement(id) {
        const element = document.getElementById(id);
        if (! element) {
            return
        }
        const offsetTop = element.offsetTop - 95;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }

    return (

        <div className="rules-summary">
            <List component="nav" sx={{ bgcolor: isMobile ? 'rgba(22, 22, 34, 0.8)' : 'transparent', borderRadius: '5px' }}>
                <ListItemButton onClick={handleClick}>
                    <ListItemText primary="Sommaire" />
                    <ListItemIcon>
                        {open ?
                            <ArrowDropUpIcon />
                            :
                            <ArrowDropDownIcon />
                        }
                    </ListItemIcon>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton onClick={() => scrollToElement('cycles')}>
                            <ListItemText primary="Cycles" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('exploration')}>
                            <ListItemText primary="Exploration" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('resources')}>
                            <ListItemText primary="Ressources" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('constructions')}>
                            <ListItemText primary="Constructions" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('citizen')}>
                            <ListItemText primary="Citoyens" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('conquest')}>
                            <ListItemText primary="Conquête" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('magic')}>
                            <ListItemText primary="Magie" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('spy')}>
                            <ListItemText primary="Espionnage" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('gift')}>
                            <ListItemText primary="Échange" />
                        </ListItemButton>
                        <ListItemButton onClick={() => scrollToElement('market')}>
                            <ListItemText primary="Marché" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </div >
    )
}

export default Summary