import ContentPane from "../../layout/content-pane/content-pane";
import usePlayerStore from "../../store/use-player-store";
import {useEffect} from "react";
import ManaProgress from "../mana-progress/mana-progress";

function ManaPane() {
    const player = usePlayerStore(state => state.player)
    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)

    useEffect(() => {
        async function fetchData() {

        try {
            await fetchPlayer()
        } catch (error) {
            console.error(error);
        }
    }
    fetchData()
    }, [fetchPlayer])

    return (
        player && (
            <ContentPane title={"Mana"}>
                <ManaProgress />
                <p>
                    Taux de récupération : <strong>2pts/tour</strong>
                    {player.mana < player.maxMana && (
                        <>
                            <br/>
                            Votre mana sera complètement régénérée dans{" "}
                            <strong>{Math.ceil((player.maxMana - player.mana) / 2)} cycles</strong>
                        </>
                    )}
                </p>
            </ContentPane>
        )
    );
}

export default ManaPane