import { create } from "zustand";
import backendRepository from "../repository/backend-repository";

const initialState = {
    helper: localStorage.getItem("helper") !== null ? (localStorage.getItem("helper") === "true" || false) : true,
    scrollToBottom: localStorage.getItem("scrollToBottom") !== null ? (localStorage.getItem("scrollToBottom") === "true" || false) : false,
    notifications: [],
    hasNewNotification: false,
    hasBeenDisconnected: false,
};

const useUiStore = create((set, get) => ({
    ...initialState,

    toggleHelper: () => {
        set((state) => {
            const newValue = !state.helper;
            localStorage.setItem("helper", String(newValue));
            return {
                helper: newValue,
            };
        });
    },

    toggleScrollToBottom: () => {
        set((state) => {
            const newValue = !state.scrollToBottom;
            localStorage.setItem("scrollToBottom", String(newValue));
            return {
                scrollToBottom: newValue,
            };
        });
    },
    fetchNotifications: async () => {
        const notifications = await backendRepository.getNotifications()
        set({notifications})
        return notifications
    },
    addMoreNotifications: async (lastId) => {
        const newNotifications = await backendRepository.getNotifications(lastId)

        set({notifications: [...get().notifications, ...newNotifications]})
        return get().notifications
    },
    markNotificationAsRead: async (notification) => {
        await backendRepository.markNotificationAsRead(notification)
        return get().fetchNotifications()
    },
    markAllNotificationsAsRead: async () => {
        await backendRepository.markAllNotificationsAsRead()
        return get().fetchNotifications()
    },
    deleteAllNotifications: async () => {
        await backendRepository.deleteNotifications()
        return get().fetchNotifications()
    },
    setHasBeenDisconnected: (value) => {
        set({hasBeenDisconnected: value})
    }
}));

export default useUiStore;