import ResourceIcon from "../resource-icon/resource-icon";
import "./element-chip.scss"
import {useEffect, useState} from "react";
import usePlayerStore from "../../store/use-player-store";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import {thDelimiter} from "../../services/utils";
import {Box, LinearProgress} from "@mui/material";

function ElementChip({item, className = ""}) {
    const [showName, setShowName] = useState(false)
    const [production, setProduction] = useState(0)

    const findProductionByResource = usePlayerStore(state => state.findProductionByResource)
    const productions = usePlayerStore(state => state.productions)

    useEffect(() => {
        if (productions.length === 0) {
            return
        }

        setProduction(findProductionByResource(item.resource.id).productionOnNextCycle)
    }, [findProductionByResource, productions, item.resource.id])

    const computeNameStyle = () => {
        const styles = ["element-item-name"]

        if (! showName) {
            styles.push("hidden")
        } else {
            styles.push("visible")
        }
        return styles.join(" ")
    }

    const handleDisplayHelp = () => {
        setShowName(!showName)
    }

    let normalizedValue = item.quantity ? (item.quantity / item.maxResourcesAllowed) * 100 : 0
    if (normalizedValue > 100) {
        normalizedValue = 100
    }

    return <div className={className}>
            <span
                className="element-item"
            >
            <ResourceIcon type={item.resource.type} />
            <div className={"element-item-content"}>
                <div className={"element-item-value"}>
                    <div className={item.quantity >= item.maxResourcesAllowed ? "resource-value-full" : ""}>
                        {thDelimiter(item.quantity)} <strong>/ {thDelimiter(item.maxResourcesAllowed)}</strong>
                    </div>
                </div>
                <div className={computeNameStyle()}>
                    {thDelimiter(item.quantity)} / {thDelimiter(item.maxResourcesAllowed)}
                </div>
                {!showName && <div className={"element-item-top-name"}>
                    {item.resource.name}
                </div>}
            </div>
        </span>
        <Box sx={{ display: 'flex', alignItems: 'center', position: "relative" }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress color={"secondary"} sx={{marginRight: "5px", marginLeft: "5px", height: "10px"}} value={normalizedValue} variant={"determinate"} />
            </Box>
            <Box sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: production > 0 ? "#65BD6E" : "#C46D5E",
                fontWeight: "bold",
                fontSize: "1em"
            }}>
                {!["soldier", "spy", "wizard"].includes(item.resource.type) ? <>
                    {production < 0 && <SouthEastIcon />}
                    {production > 0 && <CallMadeIcon />}
                    {production !== 0 && <span>{thDelimiter(production)}</span>}
                </> : <div />}
            </Box>
        </Box>
    </div>
}

export default ElementChip