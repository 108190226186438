import React, { useState } from "react";
import FaceCompact from "./face-compact";
import FaceDetailed from "./face-detailed";

function ConstructionTile({ playerConstruction, playerProduction, resourceStocks, sumOfCitizen, constructionOrder }) {
    const [detailed, setDetailed] = useState(false);


    const handleClick = () => {
        setDetailed(!detailed);
    };

    const computeClasses = () => {
        const classes = ["construction-tile-container"]

        if (detailed) {
            classes.push("construction-tile-container-detailed")
        }

        return classes.join(" ")
    }

    return <div className={computeClasses()}>
        {detailed ? <FaceDetailed
            onClick={handleClick}
            playerConstruction={playerConstruction}
            playerProduction={playerProduction}
            resourceStocks={resourceStocks}
            sumOfCitizen={sumOfCitizen}
            constructionOrder={constructionOrder}
        /> : <FaceCompact
            onClick={handleClick}
            playerConstruction={playerConstruction}
        />}
    </div>
}
export default ConstructionTile;