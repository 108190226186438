import React from "react";
import { Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import "./construction-tile.scss"
import {thDelimiter} from "../../services/utils";

function ConstructionProduction({ playerProduction, playerConstruction, sumOfCitizen }) {
    const computeBonusClasses = (production) => {
        const classes = []

        if (production.productionIncrease !== 0) {
            classes.push("strong")
            classes.push(production.productionIncrease > 0 ? "text-success" : "text-warning")
        }

        return classes.join(" ")
    }

    return (
        <>
            {playerProduction.map((production, j) => (
                <React.Fragment key={j}>
                    <div className="flex flex-center">
                        <h3>Produit : {production.resource.name}</h3>
                        <img
                            src={require(`../../assets/img/icon/${production.resource.type}.webp`)}
                            className="ressource-icon radius-100 ml-5"
                            alt="resoure-icon"
                            rel="preload"
                            as="image"
                        />
                    </div>
                    <TableContainer sx={{ width: "100%", backgroundColor: "rgb(22, 22, 34)" }}>
                        <Table size="small">
                            <TableHead>
                                {!production.construction.isSpecial ? (
                                    <>
                                        <TableRow>
                                            <TableCell>Stock actuel</TableCell>
                                            <TableCell>{thDelimiter(production.resourceStock.quantity)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stock max par batiment</TableCell>
                                            <TableCell>{thDelimiter(production.maxQuantity)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stock max de tout les batiments</TableCell>
                                            <TableCell>
                                                {thDelimiter(production.maxQuantity * playerConstruction.quantity)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Production par batiment</TableCell>
                                            <TableCell>
                                                {(production.productionPerCycle / playerConstruction.quantity).toFixed(1)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Production brute par cycle</TableCell>
                                            <TableCell>{thDelimiter(production.rawProductionPerCycle)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Production par cycle avec bonus</TableCell>
                                            <TableCell>{thDelimiter(production.productionPerCycle)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Production réelle au prochain cycle</TableCell>
                                            <TableCell>{thDelimiter(production.productionOnNextCycle)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Bonus de production</TableCell>
                                            <TableCell>
                                                <span className={computeBonusClasses(production)}>
                                                    {(production.productionIncrease * 100).toFixed(2)}%
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                        {production.construction.type === "farm" && (
                                            <>
                                                <TableRow>
                                                    <TableCell>Production après consommation</TableCell>
                                                    <TableCell>
                                                        {thDelimiter(production.productionPerCycle - sumOfCitizen)}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                        <TableRow>
                                            <TableCell>Stock au prochain tour</TableCell>
                                            <TableCell>{thDelimiter(production.stockQuantityOnNextCycle)}</TableCell>
                                        </TableRow>
                                    </>
                                ) : (
                                    <>
                                        <TableRow>
                                            <TableCell>Stock actuel</TableCell>
                                            <TableCell>
                                                {thDelimiter(production.resourceStock.quantity)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stock max par batiment</TableCell>
                                            <TableCell>{thDelimiter(production.maxQuantity)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stock max de tout les batiments</TableCell>
                                            <TableCell>
                                                {thDelimiter(production.maxQuantity * playerConstruction.quantity)}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableHead>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            ))}
        </>
    )
}
export default ConstructionProduction