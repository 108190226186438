export function MagicHammerNotification({ notification }) {
    if (null === notification.body.spell.effect) {
        return <div>
            <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Marteau Magique qui a <strong>échoué</strong>
        </div>
    }
    return <div>
        <strong>{notification.body.caster.nickname}</strong> vous a envoyé le sort Marteau Magique qui augmente la production de vos mines de <strong>{(notification.body.spell.effect.productionIncrease * 100).toFixed(2)}%</strong>.
    </div>
}

export function MagicHammerSpellCast({ spellCast, detailed = true }) {
    if (detailed) {
        return <span>Les mines de {spellCast.defender.nickname} augmentent leurs productions de {(spellCast.effect.productionIncrease * 100).toFixed(2)}%</span>
    }
    return <span>Augmentation de la production des mines de {(spellCast.effect.productionIncrease * 100).toFixed(2)}%</span>
}