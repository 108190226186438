import CountdownCycle from "../countdown-cycle/countdown-cycle";
import Hamburger from "../hamburger/hamburger";
import UserAvatar from "../user-avatar/user-avatar";
import "./header.scss";
import logo from "../../assets/img/logo.webp"
import { Link, useNavigate } from "react-router-dom";
import usePlayerStore from "../../store/use-player-store";
import ResourceHamburger from "../resource-hamburger/resource-hamburger";
import NotificationButton from "../notification-button/notification-button";

import { AiOutlineUserAdd } from 'react-icons/ai'
import { BiLogInCircle } from 'react-icons/bi'
import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

function Header() {
    const token = usePlayerStore(state => state.token)
    const navigate = useNavigate()

    usePlayerStore.subscribe((state, prevState) => {
        if (!state.token && prevState.token) {
            navigate("/login?expired")
        }
    })

    return (
        <>
            <div className={"header-container"}>
                <div className={"header-menu"}>
                    <div className={"header-item left"}>
                        <Hamburger />
                    </div>
                    <div className={"header-item center"}>
                        <Link to='/'><img src={logo} alt="logo conquest" className={"logo-conquest"} /></Link>
                    </div>
                    <div className={"header-item right"}>
                        <CountdownCycle />
                        {!token && <div className="header-component">
                            <Tooltip title="Creer un compte" arrow>
                                <Button component={Link} to={"/signup"}><AiOutlineUserAdd size={32} /></Button>
                            </Tooltip>
                            <Tooltip title="Se connecter" arrow>
                                <Button component={Link} to={"/login"}><BiLogInCircle size={32} /></Button>
                            </Tooltip>
                        </div>}
                        {token && <>
                            <UserAvatar />
                            <NotificationButton />
                            <ResourceHamburger />
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
