import ContentPane from "../../layout/content-pane/content-pane";
import CollapseBox from "../../layout/collapse-box/collapse-box";
import {Button, Link} from "@mui/material";
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';

function CvAccess() {
    return <ContentPane title={"Présentation détaillée du projet"}>
        <div className={"text-left"}>
            <p>
                Bienvenus sur Conquest.
            </p>
            <p>
                Conquest est un jeu en ligne au tour par tour qui se joue dans un navigateur. <br/>
            </p>
            <div className={"text-center"}>
                <Button variant={"outlined"} component={Link} to={"/rules"}>Voir les regles</Button>
            </div>

            <h2>Histoire du projet</h2>
            <p>
                Ce site est développé par Théo Wenger et Corentin Montel, deux développeurs du sud de la France et amis
                depuis l'adolescence.
            </p>

            <h2>Présentation de la stack technique</h2>
            <CollapseBox header={"Frontend"} icon={<PhonelinkIcon />}>
                <p>
                    Le frontend a été développé avec le framework <Link to={"#"}>ReactJs</Link>.
                </p>
                <p>
                    Graphiquement le site a été designé en full CSS vanilla, cependant nous avons intégrés une librairie de composant
                    graphique afin d'économiser du temps sur la partie de construction des composants de base.
                </p>
                <p>
                    Les librairies suviantes ont été utilisées
                </p>
                <ul>
                    <li><Link to={"https://www.npmjs.com/package/@mui/material"}>@mui/material</Link> pour les composants graphiques</li>
                    <li><Link to={"https://www.npmjs.com/package/zustand"}>zustand</Link> pour le state-management</li>
                    <li><Link to={"https://www.npmjs.com/package/axios"}>axios</Link> en client HTTP</li>
                    <li><Link to={"https://www.npmjs.com/package/socket.io-client"}>socket.io-client</Link> en client Socket</li>
                    <li><Link to={"https://www.npmjs.com/package/react-toastify"}>react-toastify</Link> pour l'affichage des notifications</li>
                    <li><Link to={"https://www.npmjs.com/package/react-quill"}>react-quill</Link> en éditeur WYSIWYG</li>
                    <li><Link to={"https://www.npmjs.com/package/react-moment"}>react-moment</Link> pour la gestion des dates</li>
                    <li><Link to={"https://www.npmjs.com/package/react-cookie"}>react-cookie</Link> pour la gestion des cookies</li>
                </ul>
            </CollapseBox>
            <CollapseBox header={"Backend"} icon={<StorageIcon />}>
                <p>
                    Le backend a été développé avec le framework <Link to={"#"}>ExpressJS</Link>.
                </p>
                <p>
                    Nous avons par la suite organisé notre code selon une architecture <strong>MVC</strong> couplé avec
                    un <strong>service layer</strong>.
                </p>
                <p>
                    La persistence de la donnée est assurée par MariaDB et son connecteur NodeJS officiel. Au niveau code
                    nous avons implémentés des couples <strong>Model/Repository</strong> pour chaques tables et la mise
                    à jour/déploiement des modèles de données se fait par un <strong>système de migration</strong> maison (deploy/rollback).
                </p>
                <p>
                    Certains évènements de jeux necessitent une gestion asynchrone (retour des troupes par exemple), nous
                    avons optés pour un système de messaging maison persistés en base directement. Cela etait le plus simple
                    en temps un mais basculer le transport vers un message broker reconnue est une option envisagés en fonction
                    du volume que représenterons ce type d'évènement dans le futur.
                </p>
                <p>
                    La sécurité repose sur JWT, l'application fournie un jeton d'accès à durée de vie courte pour
                    l'accès aux données et un jeton de refresh à durée de vie longue pour la génération des jeton d'accès.
                </p>
                <ul>
                    <li><Link to={"https://www.npmjs.com/package/express-jwt"}>express-jwt</Link> pour le middleware en charge de la vérification du jeton JWT</li>
                    <li><Link to={"https://www.npmjs.com/package/express-validator"}>express-validator</Link> pour la validation des inputs utilisateurs (body, query, path)</li>
                    <li><Link to={"https://www.npmjs.com/package/jsonwebtoken"}>jsonwebtoken</Link> pour la génération des jetons JWT</li>
                    <li><Link to={"https://www.npmjs.com/package/mariadb"}>mariadb</Link> en connecteur client à la base de donnée</li>
                    <li><Link to={"https://www.npmjs.com/package/moment"}>moment</Link> pour la gestion des dates</li>
                    <li><Link to={"https://www.npmjs.com/package/winston"}>winston</Link> comme logger</li>
                    <li><Link to={"https://www.npmjs.com/package/cors"}>cors</Link> pour la gestion du CORS</li>
                    <li><Link to={"https://www.npmjs.com/package/axios"}>axios</Link> en client HTTP</li>
                </ul>
            </CollapseBox>
            <CollapseBox header={"Infrastructure"} icon={<LanguageIcon />}>
                <p>
                    Au niveau infrastructure, le montage actuel est simple et peu couteux. Le frontend, le backend
                    et la base donnée sont déployé sur un serveur type VPS, les webservices sont exposés via le serveur
                    web Nginx. Cependant nous pouvons simplement améliorer la performance
                    de la stack en déployant les différents éléments sur des serveurs qui leur sont dédiés (et non pas des serveurs dédié).
                </p>
                <p>
                    En effet notre backend étant <strong>stateless</strong> il apparait comme simple de déployer plusieurs
                    réplicas du backend ou du frontend, de mettre un load balancer frontal (type Nginx, Traefik) pour mitiger et encaisser
                    l'augmentation du trafic. Nous sommes donc capables de scaler les webservices <strong>horizontalement et verticalement</strong>.
                </p>
                <p>
                    Par contre base de donnée ne peut etre simplement répliquée, c'est une problématique connue des SGBD.
                    La solution envisagée est de faire plusieurs serveurs de jeu quand notre base est "at capacity".
                </p>
            </CollapseBox>
        </div>
    </ContentPane>
}

export default CvAccess