import { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";


function SpellFilter({ handleFilterSpellChange, playerSpells }) {
    const [filteredSpell, setFilteredSpell] = useState('default');

    useEffect(() => {
        if (Array.isArray(playerSpells)) {
            let filteredPlayerSpells = [...playerSpells];

            switch (filteredSpell) {
                case 'instant':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => spell.spell.duration === 0);
                    break;
                case 'duration':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => spell.spell.duration > 0);
                    break;
                case 'offensive':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => spell.spell.friendly === false);
                    break;
                case 'defensive':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => spell.spell.friendly === true);
                    break;
                case 'available':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => !spell.isCooldown && spell.isUnlock);
                    break;
                case 'unavailable':
                    filteredPlayerSpells = filteredPlayerSpells.filter(spell => spell.isCooldown || !spell.isUnlock);
                    break;
                default:
                    break;
            }
            handleFilterSpellChange(filteredPlayerSpells)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerSpells, filteredSpell]);

    return (
        <>
            <form className={"flex text-center mt-5 mb-5"}>
                <FormControl size='small' width="auto">
                    <span>Filtrer par :</span>
                    <Select
                        labelId="page-size-label"
                        id="page-size-select"
                        value={filteredSpell}
                        onChange={(e) => setFilteredSpell(e.target.value)}
                    >
                        <MenuItem value={'default'}>Tous</MenuItem>
                        <MenuItem value={'available'}>Disponible</MenuItem>
                        <MenuItem value={'unavailable'}>indisponible</MenuItem>
                        <MenuItem value={'instant'}>Instantané</MenuItem>
                        <MenuItem value={'duration'}>A durée</MenuItem>
                        <MenuItem value={'offensive'}>Offensif</MenuItem>
                        <MenuItem value={'defensive'}>Defensif</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </>
    )
}

export default SpellFilter