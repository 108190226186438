import React, {useEffect, useState} from "react";
import SpellIcon from "../spell-icon/spell-icon";
import {Divider, Link as MuiLink} from "@mui/material";
import {Link} from "react-router-dom";
import RequiredSchool from './required-school.jsx'
import SpellRecap from "./spell-recap";
import {Chip as MuiChip} from "@mui/material"
import SpellEfficiencyChip from "../spell-efficiency-chip/spell-efficiency-chip";
import usePlayerStore from "../../store/use-player-store";

function FaceDetailed({ playerSpell }) {
    const findResourceStockByResourceType = usePlayerStore((state => state.findResourceStockByResourceType))

    const [wizardStock, setWizardStock] = useState(null)

    useEffect(() => {
        const wizardStock = findResourceStockByResourceType("wizard")

        setWizardStock(wizardStock)
    }, [])

    if (wizardStock === null) {
        return null
    }

    return (
        <div key={playerSpell.spell.type} className={`spell-tile`}>
            <SpellIcon dialog={true} type={playerSpell.spell.type} playerSpell={playerSpell} withMana={false} />
            <div className="spell-tile-text">
                <Divider><h3>{playerSpell.spell.name}</h3></Divider>
                <div className="px-5 mb-10 text-center">{playerSpell.spell.description}</div>
                <div className="px-5 text-center strong">Efficacité maximale</div>
                <div className={"mb-2"}>
                    <MuiLink component={Link} to={"/rules#magic-efficiency"}>
                        <MuiChip size={"small"} label={"?"} /> Comprendre cette valeur
                    </MuiLink>
                </div>
                <div className="px-5 mb-5">
                    <SpellEfficiencyChip wizardStock={wizardStock} spell={playerSpell.spell} />
                </div>
                <RequiredSchool dialog={true} playerSpell={playerSpell} />
            </div>
            <SpellRecap playerSpell={playerSpell} />
        </div>
    );
}

export default FaceDetailed;