import "./whats-new.scss"
import { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Chip from "../../layout/chip/chip";
import RuleLink from "../../layout/rule-link/rule-link";


function WhatsNew() {
    const [open, setOpen] = useState(false)
    const newsName = "alpha_spying0"
    const limitDate = new Date("2023/10/15").getTime()
    //const [changelog, setChangelog] = useState(null)

    useEffect(() => {
        const latestNews = localStorage.getItem("whats-new")

        if (latestNews !== newsName && Date.now() < limitDate) {
            setOpen(true)
            /*backendRepository.getChangelog().then(c => {
                setChangelog(c[0])
                setOpen(true)
            })*/

        }
    }, [limitDate])

    const handleClose = () => {
        localStorage.setItem("whats-new", newsName)
        setOpen(false)
    }

    return <Dialog className={"whats-new-container"} open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Conquest - alpha</DialogTitle>
        <DialogContent>
            <p>
                <Chip>03/10/2023</Chip> L'espionnage est disponible !
            </p>
            <img src={require("../../../src/assets/img/spying-news.jpg")} width={"100%"} alt={"spying"} />
            <h2>
                Ajout de la fonctionnalité d'espionnage
            </h2>
            <p>
                Affutez vos lames, enfilez votre capuche et preparez vous à decouvrir les secrets de vos adversaires.
            </p>
            <p>
                Vous pouvez maintenant utiliser les espions afin de connaitre le stock de vos adversaires en utilisant la fonctionnalité d'espionnage dans le menu dedié.
                La page de règles a été mise à jour, veuillez vous y referer pour comprendre le fonctionnement de cette nouvelle mecanique.
            </p>
            <div className={"flex flex-row flex-center"}>
                <RuleLink section={"spy"}>Règles de l'espionnage</RuleLink>
            </div>
            <p>
                Bon jeu à tous !
            </p>
            <p>
                <Button variant={"contained"} color={"primary"} onClick={handleClose}>C'est compris !</Button>
            </p>
        </DialogContent>
    </Dialog>
}

export default WhatsNew