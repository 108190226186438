import "./changelog.scss"
import ContentPane from "../../layout/content-pane/content-pane";
import {useEffect, useState} from "react";
import backendRepository from "../../repository/backend-repository";
import ChangelogItem from "../../components/changelog-item/changelog-item";

function Changelog() {
    const [changelog, setChangelog] = useState([])

    useEffect( () => {
        backendRepository.getChangelog().then(c => {
            setChangelog(c)
        })
    }, [])

    return <>
        <ContentPane title={"Changelog"}>
            <p>
                Vous trouverez ici les descriptifs des mises-à-jour du jeu
            </p>
            <div className="changelog-container">
                {changelog.map(c => (
                    <ChangelogItem key={c.release} item={c} />
                ))}
            </div>
        </ContentPane>
    </>
}

export default Changelog