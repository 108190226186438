import ContentBox from "../../layout/content-box/content-box";
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function OrderList({
    title = "",
    elements = [],
    children = "",
    rowRenderer = () => {},
    onEmpty = "Pas d'élément à afficher"
}) {
    return <ContentBox title={title}>
        <p>{children}</p>
        <List component={Paper} sx={{width: "100%"}}>
            {elements.map(element => (
                <ListItem key={element.id}>
                    <ListItemIcon>
                        <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={rowRenderer(element)}
                    />
                </ListItem>
            ))}
            {elements.length === 0 && <>
                <ListItem>
                    <ListItemText
                        primary={onEmpty}
                    />
                </ListItem>
            </>}
        </List>
    </ContentBox>
}

export default OrderList