import { useEffect, useState } from "react";
import usePlayerStore from "../../store/use-player-store";
import { Button, List, ListItem, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import backendRepository from "../../repository/backend-repository";
import Pagination from "../../layout/pagination/pagination";
import PaginationLength from "../../layout/pagination/pagination-length";
import PaginationFilter from "../../layout/pagination/pagination-filter";
import { Link } from "react-router-dom";
import ShieldIcon from '@mui/icons-material/Shield';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import moment from "moment";

function SpellCastHistory() {

    const fetchPlayer = usePlayerStore(state => state.fetchPlayer)
    const player = usePlayerStore(state => state.player)

    const [spellCastHistory, setSpellCastHistory] = useState(null);

    const [pages, setPages] = useState(1)
    const [pageSize, setPageSize] = useState(20);
    const [role, setRole] = useState('')

    useEffect(() => {
        async function fetchData() {
            try {
                const history = await backendRepository.getSpellCastsHistory(pages, pageSize, role);
                setSpellCastHistory(history);
                await fetchPlayer()
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [setSpellCastHistory, fetchPlayer, pages, pageSize, role]);


    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPages(1)
    };

    const handleRoleChange = (role) => {
        setRole(role);
        setPages(1);
    }

    if (spellCastHistory === null || player === null) {
        return <Skeleton animation="wave" height={600} />
    }

    return (
        <>
            <PaginationFilter onRoleChange={handleRoleChange} filters={{ 'as-attacker': "Attaque", 'as-defender': "Defense", '': 'Tout' }} />
            <Pagination
                currentPage={spellCastHistory.currentPage}
                totalPages={spellCastHistory.totalPages}
                onPageSelection={setPages}
            />
            <PaginationLength onPageSizeChange={handlePageSizeChange} />

            <List dense sx={{ bgcolor: 'background.paper' }}>
                {spellCastHistory.spellCasts.map((spellCast) => {

                    const isWin = spellCast.isWin


                    return (
                        <ListItem alignItems={"center"} key={spellCast.id} sx={{ color: "white" }}>
                            <ListItemIcon>
                                {player.id === spellCast.defender.id ?
                                    <ShieldIcon style={{ fontSize: "32px" }} className={isWin ? "victory-text" : "defeat-text"} fontSize={"large"} />
                                    :
                                    <GpsFixedIcon style={{ fontSize: "32px" }} className={isWin ? "victory-text" : "defeat-text"} fontSize={"large"} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={<>
                                    <strong>{spellCast.attacker.nickname}</strong> lance un sort sur <strong>{spellCast.defender.nickname}</strong><br />
                                    {isWin ? `Sort de ${spellCast.spell.name} réussi` : `Sort de ${spellCast.spell.name} échoué`}
                                </>}
                                secondary={
                                    <>{moment(spellCast.createdAt).format("YYYY/MM/DD HH:mm:SS")}</>
                                }
                            />
                            {spellCast.attacker.id === player.id ?
                                <Button variant={"outlined"} component={Link} to={`/spell-casts/${spellCast.id}`}>Détail</Button>
                                :
                                null
                            }
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}

export default SpellCastHistory