import React, { useState } from 'react';
import '../collapse-box/collapse-box.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//Collapse is a component used to display a header. If you toggle on, the children will display and disapear
//header represent the text, icon if you want to add some image, and children is the content into the component

const CollapseBox = ({ header, icon, children, id = null, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapse-box w-full" id={id}>
      <div className="collapse-box-header " onClick={toggleCollapse}>
        <div className='w-full flex flex-row align-center '>
          {icon}
          <div className='ml-5'>{header}</div>
        </div>
        {/* is open represent the toggle on button, and the icon keyArrow change on the state is open */}
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      {isOpen && <div className="collapse-box-content">{children}</div>}
    </div>
  );
};

export default CollapseBox; 