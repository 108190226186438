import SpyingHistoryList from "../../components/spying-history-list/spying-history-list"
import PageTitle from "../../layout/page-title/page-title"
import "./spying-history.scss"
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import HelperPane from "../../layout/helper-pane/helper-pane";

function SpyingHistory() {

  return (
    <>
      <Button component={Link} to={"/spying"}>Espionner</Button>
      <PageTitle>Historique des espionnages</PageTitle>
      <HelperPane linkTo={"spy"}>
        <p>
          Vous trouverez ici l'historique des espionnages dont vous êtes le commanditaire ou la cible.
          Vous pouvez utiliser le filtre pour afficher uniquement les espionnages en tant qu'attaquant ou en tant que défenseur.
        </p>

      </HelperPane>

      <SpyingHistoryList />
    </>
  )
}

export default SpyingHistory