import React, { useState } from "react";
import usePlayerStore from "../../store/use-player-store";
import { AiFillStar } from "react-icons/ai"
import { Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress } from "@mui/material";
import PlayerName from "../player-name";
import Chip from "../chip/chip";

/**
 * @return {JSX.Element}
 * @constructor
 */
function PlayerSelector({ players = [], onChange = () => { }, pending = false }) {
  const currentPlayer = usePlayerStore(state => state.player)

  const [target, setTarget] = useState({ id: null })

  const handleSelection = (player) => {
    setTarget(player)
    onChange(player)
  };

  if (null === currentPlayer) {
    return null
  }
  /*if (pending) {
      return <div className="users-result-container">
          <CircularProgress />
      </div>
  }*/
  return <div className="users-result-container">
    <TableContainer sx={{ marginTop: '20px', marginBottom: '20px' }}>
      <Table size="small">
        <TableBody>
          {players &&
            players.map((player, index) => {
              const isEvenIndex = index % 2 === 0;
              const nextPlayer = isEvenIndex ? players[index + 1] : null;

              return isEvenIndex ? (
                <TableRow sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end' }} key={player.id}>
                  <TableCell
                    sx={{ width: '100%', textAlign: 'center' }}
                    selected={target.id === player.id}
                    onClick={() => handleSelection(player)}
                  >
                    {currentPlayer.id === player.id && <AiFillStar />}
                    {target.id === player.id ?
                      <Chip><PlayerName player={player} bold /></Chip>
                      :
                      <PlayerName player={player} bold />
                    }
                  </TableCell>
                  {nextPlayer && (
                    <TableCell
                      sx={{ width: '100%', textAlign: 'center' }}
                      selected={target.id === nextPlayer.id}
                      onClick={() => handleSelection(nextPlayer)}
                    >
                      {currentPlayer.id === nextPlayer.id && <AiFillStar />}
                      {target.id === nextPlayer.id ?
                        <Chip><PlayerName player={nextPlayer} bold /></Chip>
                        :
                        <PlayerName player={nextPlayer} bold />
                      }
                    </TableCell>
                  )}
                </TableRow>
              ) : null;
            })}
        </TableBody>
      </Table>
    </TableContainer>


    {pending && <div className={"users-results-cover"}>
      <CircularProgress />
    </div>}
  </div>
}

export default PlayerSelector