import ExplorationCard from "../../components/exploration-card/exploration-card"
import PlayerResourceInfo from "../../components/player-resource-info/player-resource-info"
import "./exploration.scss"
import PageTitle from "../../layout/page-title/page-title";
import HelperPane from "../../layout/helper-pane/helper-pane";
import {BrowserView} from 'react-device-detect';

function Exploration() {
    return (
        <>
            <PageTitle>Exploration</PageTitle>
            <HelperPane linkTo={"exploration"}>
                <p>
                    En explorant les terres sauvages, vous bénéficierez de terres vides prêtes à accueillir un bâtiment. Explorer coûte de la
                    nourriture et des villageois. À la fin du cycle, vous récupérerez une partie de vos villageois partis explorer en même temps
                    que vos terres vides. La nourriture sera définitivement perdue.
                </p>
            </HelperPane>
            <BrowserView>
                <PlayerResourceInfo
                    title="Ressources"
                />
            </BrowserView>
            <ExplorationCard />

        </>
    )
}

export default Exploration